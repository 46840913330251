var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['custom-label-loyat', _vm.formLayout]},[(!!_vm.itemLabel)?_c('div',{class:['custom-label-loyat__left', _vm.size],style:({ minWidth: _vm.getLabelWidth, textAlign: _vm.textAlign })},[(_vm.requiredIcon)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_c('span',{staticStyle:{"line-height":"1.5"},domProps:{"innerHTML":_vm._s(_vm.itemLabel)}}),(_vm.item.tooltipsInlabel)?_c('a-popover',[_c('template',{slot:"content"},[_c('p',[_vm._v(_vm._s(_vm.item.tooltipsInlabel.content||""))])]),_c('a-icon',{style:({color:_vm.item.tooltipsInlabel.color||'#0c85ee',marginLeft:'5px',verticalAlign:'middle'}),attrs:{"type":_vm.item.tooltipsInlabel.iconType ||'question-circle'}})],2):_vm._e()],1):_vm._e(),_c('div',{staticClass:"custom-label-loyat__right"},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.isHidden),expression:"!item.isHidden"}],style:(_vm.formItemStyle(_vm.item)),attrs:{"label":"","label-col":_vm.getLabelCol,"wrapper-col":_vm.getWrapperCol,"validate-status":_vm.item.validate && _vm.item.validate.validateStatus,"help":_vm.item.validate && _vm.item.validate.errorMsg,"required":_vm.item.validate || _vm.item.rules
                        ? true
                        : _vm.item.hasSubValidate
                        ? false
                        : null}},[_c('div',{staticStyle:{"display":"flex"}},[(
                        !_vm.itemLabel &&
                            ((_vm.item.rules && !!_vm.item.rules.length) ||
                                _vm.item.validate)
                    )?_c('span',{staticStyle:{"color":"red","vertical-align":"middle","padding-right":"8px"}},[_vm._v("*")]):_vm._e(),_c('div',{staticStyle:{"flex":"1"}},[(_vm.item.popoverContent)?_c('a-popover',{attrs:{"defaultVisible":false,"placement":_vm.item.placement || 'bottomLeft',"mouseEnterDelay":_vm.item.mouseEnterDelay || 0,"mouseLeaveDelay":_vm.item.mouseLeaveDelay || 0,"overlayStyle":{
                        width: _vm.item.overlayWidth
                            ? typeof _vm.item.overlayWidth === 'number'
                                ? _vm.item.overlayWidth + 'px'
                                : _vm.item.overlayWidth
                            : '300px',
                        'word-wrap': 'break-word'
                    },"autoAdjustOverflow":true}},[(_vm.item.popoverTitle)?_c('div',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.item.popoverTitle)},slot:"title"}):_vm._e(),(
                            _vm.item.popoverContent && _vm.item.popoverContent !== true
                        )?_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.item.popoverContent)},slot:"content"}):_vm._e(),_c('div',{on:{"mouseover":function($event){return _vm.mouseoverHandle(_vm.item, _vm.form)},"mouseleave":function($event){return _vm.mouseleaveHandle(_vm.item, _vm.form)}}},[_vm._t("default")],2)]):_vm._t("default")],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }