import Layout from "@/layout/index.vue";
export default {
  path: "/dailywork",
  name: "dailywork",
  component: Layout,
  children: [
    {
      path: "meeting",
      name: "meeting",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/dailywork/meeting/index.vue"
        ),
    },
    {
      path: "meeting/add",
      name: "addMeeting",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/dailywork/meeting/add.vue"
        ),
    },
    {
      path: "reception",
      name: "reception",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/dailywork/reception/index.vue"
        ),
    },
    {
      path: "sealManage",
      name: "sealManage",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/dailywork/sealManage/index.vue"
        ),
    },
    {
      path: "businessLearning",
      name: "businessLearning",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/dailywork/businessLearning/index.vue"
        ),
    },
  ],
};
