var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-checkbox',{attrs:{"disabled":_vm.item.isDisabled || false,"size":_vm.size,"indeterminate":_vm.item.indeterminate,"checked":_vm.item.defaultValue},on:{"click":function($event){return _vm.clickTask(
                    null,
                    _vm.item,
                    _vm.item,
                    ("checkbox选择值-" + (_vm.item.label)),
                    false
                )},"change":function (e) {
                    _vm.changeCheckedHandle(e, _vm.item);
                }},model:{value:(_vm.checkboxVal),callback:function ($$v) {_vm.checkboxVal=$$v},expression:"checkboxVal"}},[_vm._v(_vm._s(_vm.item.label || _vm.item.title))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }