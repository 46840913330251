import moment from 'moment'
import { isArray } from '../utils/index'

const ZTimeComponent = {
    name: 'ZIconComponent',
    props: {
        _value: {
            type: String
        },
        column: {
            type: Object
        }
    },
    inject: ['generalEvents'],
    methods: {

    },
    computed: {

    },
    render() {
        const { column, generalEvents } = this
        let cloneCloums = JSON.parse(JSON.stringify(column))
        cloneCloums.type = cloneCloums.zType
        const props = {
            props: { ...cloneCloums },
            on: {
                click: () => generalEvents({ fnName: 'click', props: column.props }),
            },
        }
        return (
            <div>
                <a-icon {...props} />
            </div>
        )
    }
}

export default ZTimeComponent