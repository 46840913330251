import moment from 'moment'
import { isArray } from '../utils/index'

const ZTimeComponent = {
    name: 'ZTimeComponent',
    props: {
        _value: { // 支持解析数组类型，表示时间间隔
            type: [String, Array]
        },
        splitFlagChar: { // 当_value为数组时，可以自定义分隔符样式
            type: String,
            default: '~'
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        column: {
            type: Object
        }
    },
    inject: ['noneValue'],
    methods: {

    },
    computed: {
        getTime() {
            const { _value = undefined, format, splitFlagChar } = this.$props
            const { noneValue } = this
            if (!_value && _value !== 0) {
                return noneValue
            }
            if (isArray(_value)) {
                return `${moment(_value[0]).format(format)} ${splitFlagChar} ${moment(_value[1]).format(format)}`
            }
            return moment(_value).format(format)
        }
    },
    render() {
        const { getTime } = this
        return (
            <div>
                {getTime}
            </div>
        )
    }
}

export default ZTimeComponent