<template>
	<div>
		<a-checkbox-group
			v-if="item.subCol"
			class="zen-form--checkboxgroup"
			:disabled="item.isDisabled"
			@change="
                e => {
                    changeCheckedGroupHandle(e, item);
                }
            "
			:size="size"
			v-model="checkboxGroupVal"
		>
			<a-row>
				<template v-for="(checkboxItem, index) in item.options">
					<a-col
						:span="item.subCol"
						:key="index"
						:style="{
                            marginBottom: item.marginBottom + 'px' || '0px'
                        }"
					>
						<a-checkbox
							:disabled="checkboxItem.isDisabled"
							:value="checkboxItem.value"
							@click="
                                clickTask(
                                    index,
                                    checkboxItem,
                                    item,
                                    `checkbox选择值-${item.label}`,
                                    'div/div/'
                                )
                            "
						>{{ checkboxItem.label }}</a-checkbox>
					</a-col>
				</template>
			</a-row>
		</a-checkbox-group>
		<a-checkbox-group
			v-else
			:disabled="item.isDisabled"
			@change="
                e => {
                    changeCheckedGroupHandle(e, item);
                }
            "
			:size="size"
			v-model="checkboxGroupVal"
		>
			<a-checkbox
				v-for="(checkboxItem, index) in item.options"
				:key="index"
				:disabled="checkboxItem.isDisabled"
				:value="checkboxItem.value"
				@click="
                    clickTask(
                        index,
                        checkboxItem,
                        item,
                        `checkbox选择值-${item.label}`
                    )
                "
			>{{ checkboxItem.label }}</a-checkbox>
		</a-checkbox-group>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			checkboxGroupVal: this.value || []
		}
	},
	methods: {
		clickTask(index, item, column, step, path) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				const task = {
					by_select: 'xpath',
					by_val:
						path === false
							? `//*[@id="${column.props}"]`
							: `//*[@id="${column.props}"]/${path ||
									''}label[${index + 1}]`,
					param: item.label || item.title,
					type: 'click',
					time: '0',
					step: step
				}
				this.$utt.addTask(task)
			}
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		changeCheckedGroupHandle(e, column) {
			let value = (column.list = e)
			this.$emit('change', value)
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}

			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		}
	},
	watch: {
		value: function(val) {
			this.checkboxGroupVal = val || []
		}
	},
	props: {
		value: {
			type: Array
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
