<template>
	<div class="searchForm">
		<a-form layout="inline" :form="form">
			<a-form-item
				:label="item.label"
				v-for="(item, index) in columns"
				:key="index"
			>
				<a-input
					v-decorator="[item.props]"
					v-if="item.widgetType === 'input'"
					v-bind="item"
					:style="{ width: '200px', ...item.style }"
				/>
				<a-select
					v-decorator="[item.props]"
					v-if="item.widgetType === 'select'"
					v-bind="item"
					:style="{ width: '200px', ...item.style }"
				></a-select>
			</a-form-item>
			<a-form-item>
				<a-button type="primary" @click="searchHandle"> 查询 </a-button>
				<a-button @click="resetHandle"> 重置 </a-button>
				<slot name="btnContent"></slot>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	name: 'searchForm',
	methods: {
		searchHandle() {
			let values = this.form.getFieldsValue()
			this.$emit('search', values)
		},
		resetHandle() {
			this.form.resetFields()
		},
	},
	props: {
		columns: Array,
	},
	created() {
		this.form = this.$form.createForm(this)
	},
	components: {},
}
</script>

<style lang="scss" scoped>
.searchForm {
	background: #fff;
	margin-bottom: 24px;
	.ant-btn {
		height: 36px;
		margin-right: 8px;
	}
	.ant-form-item-label {
		line-height: 36px;
	}
	.ant-input {
		height: 36px;
	}
}
</style>
