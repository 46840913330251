const toStirng = Object.prototype.toString
const Type = {}, type = ['String', 'Object', 'Number', 'Array', 'Undefined', 'Function', 'Null', 'Date', 'AsyncFunction'];

for (let i = 0, len = type.length; i < len; i++) {
    Type['is' + type[i]] = function (obj) {
        return toStirng.call(obj) === '[object' + ' ' + type[i] + ']';
    }
}

export const isPromise = promise => typeof promise === 'object' && Type.isFunction(promise.then)

export default Type