import './style/index.less';
const zdetail2RefPrefixCls = '_zdetail2'
const ztableComponent = {
    name: "ztable2",
    props: {
        columns: {
            type: Array,
            default() {
                return []
            }
        },
        detailColumns: {
            type: Array,
            default() {
                return []
            }
        },
        dataSource: {
            type: Array,
            default() {
                return []
            }
        },
        express: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            zdetail2Refs: [],
            detailColumnsMap: {},
            cloneDataSource: JSON.parse(JSON.stringify(this.dataSource))
        }
    },
    watch: {
        dataSource: {
            deep: true,
            handler(val) {
                this.cloneDataSource = JSON.parse(JSON.stringify(val))
            }
        }
    },
    components: {},
    methods: {
        /**
        * @description: 预处理 scopedSlots
        */
        setScopedSlots(columns) {
            let { renderColumn, $scopedSlots } = this

            const scopedSlots = { ...$scopedSlots }
            if (scopedSlots.ellipsisOperations) {
                scopedSlots['operations'] = (text, record, rowIndex, column) => {
                    return <a-popover placement="bottom">
                        <template slot="content">
                            <div class="editable-row-operations">
                                {scopedSlots.ellipsisOperations(record, text, rowIndex, column)}
                            </div>
                        </template>
                        <a-icon type="ellipsis" style={{ fontSize: "30px" }} />
                    </a-popover>
                }
            }
            columns.forEach(item => {
                // 如果父组件自定义了插槽，不做处理
                // 否则，需要利用<zdetail>组件渲染
                if ($scopedSlots[item.dataIndex]) {
                    scopedSlots[item.dataIndex] = $scopedSlots[item.dataIndex]
                } else {
                    scopedSlots[item.dataIndex] = (text, record, rowIndex, column) => {
                        return renderColumn(text, record, rowIndex, column, item)
                    }
                }

                // if (item.dataIndex === "operation") {
                //     console.log('this.$slots', this, this.$slots);
                //     scopedSlots[item.dataIndex + '_1'] = (text, record, rowIndex, column) => {
                //         return <div id="123">
                //             {scopedSlots[item.dataIndex](record, text, rowIndex, column)}
                //         </div>
                //     }
                // }

            })
            return scopedSlots
        },
        validateFields() {
            return new Promise(async (resolve, reject) => {
                let err = []
                for (const key in this.$refs) {
                    const element = this.$refs[key]
                    if (key.indexOf(zdetail2RefPrefixCls) !== 0) {
                        continue
                    }
                    try {
                        await element.validateFields()
                    } catch (error) {
                        err.push(error)
                    }
                }
                if (err.length > 0) {
                    return reject(err)
                }
                return resolve(this.cloneDataSource)
            })
        },
        /**
       * @description: 渲染 column
       */
        renderColumn(text, record, rowIndex, column, item) {
            // 如果在detailColumnsMap找到了配置，将使用<zdetail>组件渲染
            // 否则，直接返回文本
            let { detailColumnsMap, express, actionHandel } = this
            let detailColumn = detailColumnsMap[item.dataIndex]
            if (!detailColumn) {
                return <span>{text !== undefined ? text : "-"}</span>
            }
            const { editOptions } = detailColumn
            let ref = null
            if (editOptions) {
                ref = `${zdetail2RefPrefixCls}-${detailColumn.props || detailColumn.dataIndex}-${rowIndex}`
            }
            const detailProps = {
                props: {
                    dataSource: record,
                    column: detailColumn,
                    express
                },
                on: {
                    action: (attr) => actionHandel(attr, rowIndex, detailColumn.props)
                },
                ref: ref
            }

            return <zdetail2 {...detailProps}></zdetail2>
        },
        initColumns() {
            let { columns } = this
            let newColumns = []
            // 将columns中动态的添加scopedSlots，由<zdtail>组件渲染
            // 过滤显示掩藏column
            columns.map(item => {
                if (!item.scopedSlots) {
                    item.scopedSlots = {
                        customRender: item.dataIndex
                    }
                }
                if (item.showDefaultColumn !== false) {
                    newColumns.push(item)
                }
            })
            return newColumns
        },
        actionHandel(attr, rowIndex, detailColumnProps) {
            const { fnName, props, newVal } = attr
            if (fnName === 'editValueChange') {
                let fields = detailColumnProps ? `${detailColumnProps}.${props}` : props
                this.setFieldsValue({ [fields]: newVal }, rowIndex)
            }
            this.$emit('action', attr)
        },
        setFieldsValue(fieldValue, rowIndex) {
            for (const key in fieldValue) {
                const element = fieldValue[key];
                let splited = key.split('.')
                if (splited.length === 1) {
                    this.$set(this.cloneDataSource[rowIndex], key, element)
                    continue
                }
                let cloneDataSourceRow = this.cloneDataSource[rowIndex]
                for (let index = 0; index < splited.length; index++) {
                    const splitedElement = splited[index];
                    if (index === splited.length - 1) {
                        cloneDataSourceRow[splitedElement] = element
                        continue
                    }
                    cloneDataSourceRow = cloneDataSourceRow[splitedElement]
                }
            }
        },
        resetFieldsValue() {
            this.cloneDataSource = this.dataSource
        },
        getFieldsValue(fields, rowIndex) {
            const { cloneDataSource } = this
            if (!fields || fields.length === 0) {
                return cloneDataSource
            }
            let res = {}
            fields.map(item => {
                res[item] = cloneDataSource[rowIndex][item]
            })
            return res
        },
    },
    beforeMount() {
        let { detailColumns } = this
        detailColumns.map(item => {
            this.detailColumnsMap[item.props || item.dataIndex] = item
        })
    },

    render() {
        let { setScopedSlots, initColumns, cloneDataSource, $slots } = this
        let newColumns = initColumns()
        const scopedSlots = setScopedSlots(newColumns)
        let tableProps = {
            props: { ...this.$props, columns: newColumns, dataSource: cloneDataSource, tableLayout: 'fixed' },
            attrs: { ...this.$attrs },
            on: {
                ...this.$listeners
            },
            scopedSlots
        }
        return (
            <div class="ztable">
                <a-table {...tableProps}>
                    {$slots ?
                        (() => {
                            let slotsNodes = []
                            for (const key in $slots) {
                                const element = $slots[key];
                                slotsNodes.push(<template slot={key}>{element}</template>)
                            }
                            return slotsNodes
                        })()
                        : ''}
                </a-table>
            </div>
        )
    }
}

export default ztableComponent