import request from '@/api/request'
// 用户注册
export function login(data) {
    return request({
        url: '/api/auth/login',
        method: 'post',
        data
    })
}

// 切换业委会登录
export function switchUocLogin(data) {
    return request({
        url: '/api/auth/switch/uoc/login',
        method: 'post',
        data
    })
}


// 更改密码
export function updatePhone(data) {
    return request({
        url: '/api/auth/update/phone',
        method: 'post',
        data
    })
}


// 更改密码
export function updatePwd(data) {
    return request({
        url: '/api/auth/update/pwd',
        method: 'post',
        data
    })
}



export function getUOCWithPhone(phone, params) {
    return request({
        url: `/api/auth/uocs/${phone}`,
        method: "get",
        params: params,
    });
}


// 模拟登录
export function getUserInfoWithToken(params) {
    return request({
        url: `/api/auth/mockLogin/getUserInfo`,
        method: "put",
        data: params,
    });
}



