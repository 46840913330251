/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 17:39:40
 */
import Layout from "@/layout/index.vue";
export default {
  path: "/conferenceWorkbench",
  name: "conferenceWorkbench",
  component: Layout,
  children: [
    {
      path: "list",
      name: "list",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/list.vue"
        ),
    },
    {
      path: "add",
      name: "add",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/add.vue"
        ),
    },
    {
      path: "detail/:id",
      name: "detail",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/detail.vue"
        ),
    },
    {
      path: "bigScreenVoting/:id",
      name: "bigScreenVoting",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/bigScreenVoting.vue"
        ),
    },
    {
      path: "votingDetails/:id",
      name: "votingDetails",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/votingDetails.vue"
        ),
    },
    {
      path: "votingStatistics/:id",
      name: "votingStatistics",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/conferenceWorkbench/votingStatistics.vue"
        ),
    },
  ],
};
