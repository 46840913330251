<template>
	<div class="todolist-box">
		<a-form :form="form">
			<div
				v-for="(todo, rowindex) in todoListVal"
				:key="rowindex"
				:required="false"
			>
				<div class="todolist-item">
					<div class="todolist-content">
						<a-row :gutter="item.space || 5">
							<template
								v-for="(column, colindex) in item.subColumns"
							>
								<a-col
									:key="colindex"
									:span="column.col || 12"
									v-if="!column.isHidden"
								>
									<a-form-item :style="formItemStyle(column)">
										<span
											v-if="
												colindex > 0 &&
												item.symlinks &&
												item.symlinks[colindex - 1]
											"
											style="
												position: absolute;
												top: 0px;
												left: -10px;
											"
											>{{
												item.symlinks[colindex - 1]
											}}</span
										>
										<zinput
											v-if="column.type === 'input'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zinput>
										<zinput-number
											v-else-if="
												column.type === 'input-number'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zinput-number>
										<ztextArea
											v-else-if="
												column.type === 'textarea'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
											@blurHandle="blurHandle"
										></ztextArea>
										<zselect
											v-if="column.type === 'select'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:columns="item.subColumns"
											:updateForm="updateForm"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zselect>
										<!-- zdivider -->
										<zdivider
											v-else-if="
												column.type === 'divider'
											"
											:item="column"
											parentType="todo-list"
										></zdivider>
										<!-- zempty -->
										<zempty
											v-else-if="column.type === 'empty'"
											parentType="todo-list"
										></zempty>
										<zbutton
											v-else-if="column.type === 'button'"
											:item="column"
											:size="size"
											:form="form"
											:id="column.props"
										></zbutton>
										<zbuttons
											v-else-if="
												column.type === 'buttons'
											"
											:item="column"
											:size="size"
											:form="form"
											:id="column.props"
										></zbuttons>
										<ztag
											v-else-if="column.type === 'tag'"
											:item="column"
											:size="size"
											:form="form"
										></ztag>
										<zcheckbox
											v-else-if="
												column.type === 'checkbox'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zcheckbox>
										<zcheckboxGroup
											v-else-if="
												column.type === 'checkboxGroup'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zcheckboxGroup>
										<zswitch
											v-else-if="column.type === 'switch'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zswitch>
										<zdatepicker
											v-else-if="
												column.type === 'datepicker'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
											@blurHandle="blurHandle"
										></zdatepicker>
										<ztimepicker
											v-else-if="
												column.type === 'timepicker'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></ztimepicker>
										<zinput-search
											v-else-if="
												column.type === 'input-search'
											"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
											@blurHandle="blurHandle"
										></zinput-search>
										<zcascader
											v-if="column.type === 'cascader'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:size="size"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
											@blurHandle="blurHandle"
										></zcascader>
										<zradio-group
											v-if="column.type === 'radioGroup'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: getItemRules(
														column.rules,
														column
													),
													validateFirst: true,
												},
											]"
											:item="column"
											:form="form"
											parentType="todo-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zradio-group>

										<zoss-upload
											v-if="column.type === 'oss-upload'"
											v-decorator="[
												setDecorator(column, rowindex),
												{
													initialValue:
														todoListVal[rowindex][
															column.props
														],
													rules: [
														...(getItemRules(
															column.rules,
															column
														) || []),
														{
															message: getUploadErrorMsg(),
															validator: (
																rule,
																value,
																callback
															) =>
																handleValidatorOssUpload(
																	rule,
																	value,
																	callback,
																	column
																),
														},
													],
													getValueFromEvent: uploadNormFile,
												},
											]"
											:item="column"
											:form="form"
											parentType="group-list"
											:rowIndex="rowindex"
											@changeHandle="changeHandle"
										></zoss-upload>
									</a-form-item>
								</a-col>
							</template>
							<a-col :span="item.actionColSpan || 2">
								<a-form-item>
									<a-icon
										style="
											font-size: 16px;
											cursor: pointer;
											margin-right: 10px;
										"
										v-for="(btn, index1) in item.btns"
										:key="index1"
										:type="btn.icon"
										@click="
											btn.clickHandle &&
												btn.clickHandle(
													rowindex,
													todoListVal
												)
										"
									/>
									<a-icon
										type="minus-circle-o"
										style="font-size: 16px; cursor: pointer"
										class="dynamic-delete-button"
										v-if="
											item.allowDeleteFirst
												? true
												: rowindex > 0
										"
										@click="deleteHandle(rowindex)"
									/>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
					<!-- <div class="todolist-action">
						<a-icon
							type="delete"
							style="font-size:16px;cursor: pointer;"
							v-if="rowindex>0"
							@click="deleteHandle(rowindex)"
						/>
					</div>-->
				</div>
			</div>
			<a-button
				:disabled="item.isDisabled"
				type="dashed"
				style="width: 60%"
				@click="addHandle"
			>
				<a-icon type="plus" />
				<span v-html="item.addText || 'Add field'"></span>
			</a-button>
		</a-form>
	</div>
</template>

<script>
import formItem from '../formItem'
import zinput from './zinput'
import zselect from './zselect'
import zinputNumber from './zinputNumber'
import ztextArea from './ztextArea'
import zcheckbox from './zcheckbox'
import zcheckboxGroup from './zcheckboxGroup'
import zswitch from './zswitch'
import zdatepicker from './zdatepicker'
import ztimepicker from './ztimepicker'
import zinputSearch from './zinputSearch'
import zcascader from './zcascader'
import zradioGroup from './zradioGroup'
import zossUpload from './zossUpload'
import zdivider from './zdivider'
import zempty from './zempty'
import zbutton from './zbutton'
import zbuttons from './zbuttons'
import ztag from './ztag'
var cloneDeep = require('lodash.clonedeep')

export default {
	name: 'toDoList',
	data() {
		return {
			todoListVal: this.value
				? JSON.parse(JSON.stringify(this.value))
				: [],
		}
	},
	components: {
		formItem,
		zinput,
		zselect,
		zinputNumber,
		ztextArea,
		zcheckbox,
		zcheckboxGroup,
		zradioGroup,
		zswitch,
		zdatepicker,
		ztimepicker,
		zinputSearch,
		zcascader,
		zossUpload,
		zdivider,
		zempty,
		zbutton,
		zbuttons,
		ztag,
	},
	created() {
		this.$nextTick(() => {
			this.$createHookFunctions()
		})
		if (this.item.isDisabled !== undefined) {
			this.item.subColumns.forEach((subItem) => {
				subItem.isDisabled = this.item.isDisabled
			})
		}
	},
	methods: {
		formItemStyle(item) {
			let conditionalJudgment = {}
			Object.assign(
				conditionalJudgment,
				item.type == 'textarea' ? { marginTop: '7px' } : {}
			)

			return Object.assign(
				{
					maxWidth: item.maxWidth,
				},
				conditionalJudgment
			)
		},
		generateUUID() {
			if (process.env.NODE_ENV === 'test') {
				return 'test-uuid'
			}
			let d = new Date().getTime()
			const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
				/[xy]/g,
				(c) => {
					const r = (d + Math.random() * 16) % 16 | 0
					d = Math.floor(d / 16)
					return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
				}
			)
			return uuid
		},
		getItemRules(rules, item) {
			if (!rules || rules.length === 0 || item.isHidden) {
				return []
			}
			if (item && item.isDisabled === true) {
				return []
			}
			let findedRequired = rules.find((item) => item.required === true)
			if (findedRequired) {
				findedRequired.message = '该字段不能为空'
			}
			return rules
		},
		getUploadErrorMsg() {
			return 'Please wait for the file to finish uploading'
			// return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
			// 	? '请等待文件上传完毕'
			// 	: 'Please wait for the file to finish uploading'
		},
		handleValidatorOssUpload(rule, value, callback, item) {
			if (!value || value.length === 0) {
				callback()
			}
			if (item.uploading) {
				callback(new Error('illegal'))
			}
			callback()
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		blurHandle(e, column) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = `${column.props}`
				task.param = `${e.target.value}`
				task.type = 'sendkey'
				task.time = '2'
				task.step = `修改input值-${column.label}`
				this.$utt.addTask(task)
			}
		},
		emitFormChange(todoListVal) {
			let { item } = this
			let findComponents = function (component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value: todoListVal, column: item })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		changeHandle(value, column, rowIndex) {
			if (!this.todoListVal[rowIndex]) {
				return
			}
			const params = { column, current: value, action: 'change' }
			this.$emitHookFunction(params, (error, payload = {}) => {
				if (!error) {
					this.todoListVal[rowIndex][column.props] =
						payload.props || value
					this.$emit('change', this.todoListVal)
					this.emitFormChange(this.todoListVal)
				}
			})
		},
		deleteHandle(rowIndex) {
			const params = {
				current: this.todoListVal[rowIndex],
				action: 'delete',
			}
			console.log('submit test')
			this.$emitHookFunction(params, (error, payload = {}) => {
				let todoListVal = cloneDeep(this.todoListVal)
				todoListVal.splice(
					this.type.isNumber(payload.props) || rowIndex,
					1
				)
				if (!error) {
					this.todoListVal = todoListVal
					this.$emit('change', this.todoListVal)
					this.emitFormChange(todoListVal)
				}
			})
		},
		addHandle() {
			let defaultNewItem = this.item.defaultNewItem
				? JSON.parse(JSON.stringify(this.item.defaultNewItem))
				: null
			let { todoListVal } = this
			let newItem = todoListVal[todoListVal.length - 1]
				? JSON.parse(
						JSON.stringify(todoListVal[todoListVal.length - 1])
				  )
				: {}
			let result = defaultNewItem || newItem
			const params = {
				current: result, // 当前的item
				action: 'add',
			}
			//  回调函数可以return 给到after 钩子函数
			this.$emitHookFunction(params, (error, payload = {}) => {
				// console.log(payload.props)
				if (!error) {
					this.todoListVal.push(payload.props || result)
					this.$emit('change', this.todoListVal)
					return { props: 'Custom Props' }
				}
			})
		},
		updateForm(formData, rowIndex) {
			let todoListVal = cloneDeep(this.todoListVal)
			this.todoListVal = []
			this.$nextTick(() => {
				if (rowIndex === 0 || rowIndex) {
					todoListVal[rowIndex] = Object.assign(
						todoListVal[rowIndex],
						JSON.parse(JSON.stringify(formData))
					)
				} else {
					todoListVal = JSON.parse(JSON.stringify(formData))
				}
				this.todoListVal = todoListVal
			})
			//this.form.setFieldsValue(formData)
		},
		setDecorator(column, rowindex) {
			var uuid = null
			if (column.uuid) {
				uuid = column.uuid
			} else {
				uuid = this.generateUUID()
				column.uuid = uuid
			}
			return column.props + uuid + rowindex
		},
	},
	watch: {
		item: {
			handler(newValue, oldValue) {
				if (this.item.isDisabled !== undefined) {
					this.item.subColumns.forEach((subItem) => {
						subItem.isDisabled = this.item.isDisabled
					})
				}
			},
			deep: true,
		},
	},
	props: {
		value: {
			type: Array,
		},
		item: {
			type: Object,
		},
		size: {
			type: String,
		},
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
	},
}
</script>
<style lang="less">
.todolist-item {
	position: relative;
	padding-right: 20px;
	.todolist-action {
		position: absolute;
		right: 0px;
		top: 0px;
	}
}
</style>
