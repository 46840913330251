<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-08-17 15:19:38
-->
<template>
	<div>
		<template v-if="item.text">
			<a-divider
				:size="item.size"
				:type="item.lintype"
				:dashed="item.dashed"
				:orientation="item.orientation"
			>
				<template v-if="item.lintype !== 'vertical'">{{item.text}}</template>
			</a-divider>
			<span v-if="item.lintype === 'vertical'">{{item.text}}</span>
		</template>
		<a-divider
			v-else
			:size="item.size"
			:type="item.lintype"
			:dashed="item.dashed"
			:orientation="item.orientation"
		></a-divider>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	methods: {},
	props: {
		item: {
			type: Object
		},
		parentType: { type: String }
	}
}
</script>
<style lang="less"></style>
