<template>
  <div :class="['zen-select', { 'zen-select-mask': showMask }, { 'zen-select-mask_number': showMaskNumber }]">
    <a-select
      v-if="item.searchFilter"
      showSearch
      :mode="item.mode"
      :labelInValue="item.labelInValue"
      :placeholder="item.placeholder"
      :filterOption="selectFilterOption"
      :getPopupContainer="(target) => target.parentNode"
      :size="size"
      :disabled="item.isDisabled"
      :dropdownClassName="item.flagNumber ? 'zen-select-option_auto' : null"
      @blur="
        () => {
          selectedBlur(item)
        }
      "
      @focus="
        () => {
          selectedFocus(item)
        }
      "
      @dropdownVisibleChange="() => { handleClick(item) }"
      @change="(value) => handleCange({value, item, fun: 'selectHandleChange'})"
      v-model="getSelectVal"
    >
      <!--配置options-->
      <template
        v-for="(option, optionIndex) in item.fieldNames
          ? getfieldNames(item.fieldNames, item.options)
          : item.options"
      >
        <a-select-option
          v-if="item.options"
          :disabled="option.disabled"
          :key="option.value"
          :id="item.props + '-option' + optionIndex"
          :class="setType2UUID(option.value, item.type, columnIndex)"
          >{{ option.text }}</a-select-option
        >
      </template>
    </a-select>
    <a-select
      v-else-if="item.searchFetch"
      showSearch
      :mode="item.mode"
      :getPopupContainer="(target) => target.parentNode"
      :labelInValue="item.labelInValue"
      :placeholder="item.placeholder"
      :defaultActiveFirstOption="false"
      :showArrow="item.showArrow"
      :filterOption="false"
      :size="size"
      :disabled="item.isDisabled"
      :allowClear="item.allowClear || false"
      :dropdownClassName="item.flagNumber ? 'zen-select-option_auto' : null"
      @popupScroll="(e) => selectPopupScroll(e, item)"
      @focus="() => selectedFetchFocus(item)"
      @blur="
        () => {
          selectedBlur(item)
        }
      "
      @dropdownVisibleChange="() => { handleClick(item) }"
      @deselect="
        (value) => {
          selectedHandle(value, item, columnIndex)
        }
      "
      @select="
        (value) => {
          selectedHandle(value, item, columnIndex)
        }
      "
      @search="(value) => selectFetchHandleSearch(value, item)"
      @change="(value) => handleCange({value, item, fun: 'selectFetchHandleChange'})"
      :notFoundContent="item.options || null"
      v-model="getSelectVal"
    >
      <a-spin
        v-if="item.searchLoading && item.fetching"
        slot="notFoundContent"
        size="small"
      />
      <!--配置options-->
      <template v-for="(option, optionIndex) in item.options">
        <a-select-option
          v-if="item.options"
          :disabled="option.disabled"
          :key="option.value"
          :id="item.props + '-option' + optionIndex"
          :class="setType2UUID(option.value, item.type, columnIndex)"
          >{{ option.text }}</a-select-option
        >
      </template>
      <a-select-option :disabled="true" value="spin" v-if="item.searchLoading">
        <a-spin />
      </a-select-option>
    </a-select>
    <a-select
      v-else
      :placeholder="item.placeholder"
      :getPopupContainer="(target) => target.parentNode"
      :disabled="item.isDisabled || false"
      :mode="item.mode"
      :labelInValue="item.labelInValue"
      :maxTagCount="item.maxTagCount"
      :loading="item.loading"
      :tokenSeparators="item.tokenSeparators"
      :showSearch="item.showSearch"
      :allowClear="item.allowClear || false"
      :showArrow="item.showArrow"
      :notFoundContent="item.notFoundContent"
      :optionFilterProp="item.optionFilterProp || 'children'"
      :dropdownClassName="item.flagNumber ? 'zen-select-option_auto' : null"
      @deselect="
        (value) => {
          selectedHandle(value, item, columnIndex)
        }
      "
      @select="
        (value) => {
          selectedHandle(value, item, columnIndex)
        }
      "
      
      @dropdownVisibleChange="() => { handleClick(item) }"
      @change="(value) => handleCange({value, item, fun: 'changeHandleByValue'})"
      @blur="
        () => {
          selectedBlur(item)
        }
      "
      @focus="
        () => {
          selectedFocus(item)
        }
      "
      v-model="getSelectVal"
      :size="size"
    >
      <a-icon
        v-if="item.suffixIcon"
        slot="suffixIcon"
        :type="item.suffixIcon"
      />
      <!--配置options-->
      <template
        v-for="(option, optionIndex) in item.fieldNames
          ? getfieldNames(item.fieldNames, item.options)
          : item.options"
      >
        <a-select-option
          v-if="item.options"
          :disabled="option.disabled"
          :key="option.value"
          :id="item.props + '-option' + optionIndex"
          :class="setType2UUID(option.value, item.type, columnIndex)"
          >{{ option.text }}</a-select-option
        >
      </template>
    </a-select>
    <div class="zen-form-mask_title" v-if="showMask">
      <span v-text="maskText"></span>
    </div>
    <a-popover
      style="cursor: pointer;"
      placement="right"
      trigger="click"
      v-if="showMaskNumber"
    >
      <template slot="content" v-if="getSelectVal">
        <p
          style="margin: 0;padding-bottom: 4px;font-size: 12px;"
          v-for="(valItem, valIndex) in getSelectVal"
          :key="valIndex"
        >
          {{ getValItem(valItem) }}
        </p>
      </template>
      <span class="zen-select-mask_number">
        <a-badge
          :count="getSelectVal.length"
          :number-style="{
            backgroundColor: '#fff',
            color: '#999',
            boxShadow: '0 0 0 1px #d9d9d9 inset',
          }"
        />
      </span>
    </a-popover>
  </div>
</template>

<script>
import axios from 'axios'
const cloneDeep = require('lodash.clonedeep')
const lodashDebounce = require('lodash/debounce')

function generateUUID() {
  if (process.env.NODE_ENV === 'test') {
    return 'test-uuid'
  }
  let d = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
  })
  return uuid
}

const debounceFn = lodashDebounce((val) => {
  val()
}, 500)

export default {
  name: '',
  data() {
    return {
      selectVal: this.value || '',
      type2UUID: {},
      counter: 1,
      focusSelect: false,
    }
  },
  computed: {
    maskText() {
      const { item, getSelectVal } = this
      return item.mode && item.mode === 'multiple'
        ? item.maskTitle
        : getSelectVal === undefined
        ? item.maskTitle
        : `${item.maskTitle}: ${this.getValItem(getSelectVal)}`
    },
    showMask() {
      const {item, focusSelect} = this
      return item.mask && !focusSelect
    },
    showMaskNumber() {
      const { item, getSelectVal, focusSelect } = this
      return (
        item.mask &&
        item.mode &&
        item.mode === 'multiple' &&
        !focusSelect &&
        getSelectVal !== undefined
      )
    },
    getSelectVal: {
      // getter
      get: function() {
        if (this.item && this.item.options && this.item.options.length > 0) {
          let find = this.item.options.find(
            (item) => item.value === this.selectVal
          )

          if (find) {
            return this.paperVal(find.value)
          } else {
            return this.paperVal(this.selectVal)
          }
        } else {
          return this.paperVal(this.selectVal)
        }
      },
      // setter
      set: function(newValue) {
        if (this.item && this.item.options && this.item.options.length > 0) {
          let find = this.item.options.find((item) => item.value == newValue)
          if (find) {
            this.selectVal = find.value
          } else {
            this.selectVal = newValue
          }
        } else {
          this.selectVal = newValue
        }
      },
    },
  },
  created() {
    this.type2UUID = {}
  },
  mounted() {
    if (
      this.item &&
      this.item.searchFetch &&
      this.item.fieldNames &&
      this.item.fieldNames.value &&
      this.selectVal
    ) {
      this.item.fetchParams = this.item.fetchParams || {}
      let exactValue = `${this.item.fieldNames.value}:${this.selectVal}`
      if (
        this.item.labelInValue === true &&
        this.selectVal &&
        this.selectVal.key !== undefined
      ) {
        exactValue = `${this.item.fieldNames.value}:${this.selectVal.key}`
      }

      this.selectedFetchData(this.item, false, exactValue)
      return
    }
  },
  methods: {
    getValItem(value) {
      return typeof value === 'object' ? value.label : this.item.options.find(ele => ele.value === (Array.isArray(value) ? value[0] : value)).text
    },
    paperVal(val) {
      if (val === 0) {
        return 0
      }
      if (!val) {
        return void 0
      }
      if (typeof val === 'object' && Object.keys(val).length === 0) {
        return void 0
      }
      try {
        val = val.replace(/(^\s*)|(\s*$)/g, '')

        if (!val) {
          return void 0
        }
      } catch (error) {}
      return val
    },
    selectPopupScroll(e, item) {
      let distanse =
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      if (distanse < 10) {
        this.selectFetchDebounce(300, item, true)
      }
    },
    handleCange({value, item, fun}) {
      if (!item.mode || (item.mode && item.mode !== 'multiple')) {
        this.focusSelect = false
      }
      this[fun](value, item)
    },
    selectFetchHandleSearch(value, item) {
      // this.selectHandleSearch(value, item)
      item.fetchParams = item.fetchParams || {}
      item.fetchParams['queryValue'] = value
      item.alreadyAll = false
      this.selectFetchDebounce(300, item)
    },
    selectedFetchFocus(item) {
      this.selectedFocus(item)
      item.fetchParams = item.fetchParams || {}
      // item.fetchParams['queryValue'] = item.fetchParams['queryValue'] || ''
      item.fetchParams['queryValue'] = ''
      this.selectedFetchData(item)
    },
    selectFetchHandleChange(value, item) {
      this.selectHandleChange(value, item)

      // item.fetchParams = item.fetchParams || {}
      // item.fetchParams['queryValue'] = value

      // this.selectFetchDebounce(300, item)
    },
    selectFetchDebounce(wait, item, concat) {
      debounceFn(() => this.selectedFetchData(item, concat))
      // if (item.iTime) {
      // 	return
      // }
      // if (item.alreadyAll) {
      // 	return
      // }
      // item.iTime = setTimeout(() => {
      // 	this.selectedFetchData(item, concat)
      // }, wait)
    },
    selectedFetchData(item, concat, exactValue, exactCallback) {
      // if (item.isLastPage) {
      // 	return
      // }
      item.searchLoading = true

      // 可以使用传入的axios对象
      const selectAxiox = item.axios || axios

      // header中添加jwt
      // useToken : [String] localstrorage中获取，或者直接使用
      let headers = item.headers || {}
      if (item.useToken) {
        let jwt = localStorage.getItem(item.useToken)
        if (jwt) {
          headers.Authorization = 'Bearer ' + jwt
        } else {
          headers.Authorization = item.useToken
        }
      }

      let params = item.fetchParams
      if (!params || Object.keys(params).length === 0) {
        return
      }

      params.pageNum = params.pageNum || 1
      params.pageSize = params.pageSize || 10
      if (exactValue) {
        params['exactValue'] = exactValue
        params.pageSize = exactValue.split(',').length || 1
      } else {
        delete params['exactValue']
        params.pageSize = 10
      }

      params.pageNum = concat ? params.pageNum + 1 : 1

      selectAxiox
        .get(item.searchApi, { params, headers })
        .then((res) => {
          item.searchLoading = false
          var value = null
          let total = 0
          if (item.axios) {
            value = Array.isArray(res) ? res : res.data || res.list
            total = res.total
            item.isLastPage = res.isLastPage
          } else {
            value = res.data.data.list || res.data.data
            total = res.data.data.total
            item.isLastPage = res.data.data.isLastPage
          }
          if (concat) {
            let fieldValues = this.getfieldNames(item.fieldNames, value)

            fieldValues = this.arrayObjectDeduplication(
              fieldValues,
              item.deduplicationField
            )

            if (JSON.stringify(fieldValues) === JSON.stringify(item.options)) {
              item.alreadyAll = false
              item.options = fieldValues
            } else {
              if (item.options.length < total) {
                item.alreadyAll = false
                item.options = item.options.concat(fieldValues)
              } else {
                item.alreadyAll = true
              }
            }
          } else {
            item.options = this.arrayObjectDeduplication(
              this.getfieldNames(item.fieldNames, value),
              item.deduplicationField
            )
            if (item.unspecified) {
              item.options.unshift({
                value: item.unspecified,
                text: '-',
              })
            }
          }
        })
        .catch((err) => {
          item.searchLoading = false
          console.log(err)
        })
        .finally(() => {
          item.searchLoading = false
          clearTimeout(item.iTime)
          item.iTime = null
          if (exactCallback) {
            exactCallback()
          }
        })
    },
    arrayObjectDeduplication(arr, field) {
      if (!field || !arr || arr.length === 0) {
        return arr
      }
      let hash = {}
      arr = arr.reduce(function(item, next) {
        hash[next[field]] ? '' : (hash[next[field]] = true && item.push(next))
        return item
      }, [])
      return arr
    },
    selectFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    subColumnCallback(value, column, subColumn, fn, option) {
      let { form } = this
      subColumn[fn]({
        value,
        form,
        column,
        subColumn,
        option,
      })
    },
    columnCallback(value, column, fn, option) {
      let { form } = this
      column[fn]({
        value,
        form,
        column,
        option,
      })
    },
    changeHandleByValue(val, column, subColumn) {
      let value = val

      this.$emit('change', value)

      var option = null
      if (column.mode === 'default' || !column.mode) {
        column.options.map((item) => {
          let targetValue = column.labelInValue
            ? value
              ? value.key
              : null
            : val
          let findKey = column.fieldNames ? column.fieldNames.value : 'value'
          if (targetValue === item[findKey]) {
            option = item
          }
        })
      }

      if (subColumn && subColumn.changeHandle) {
        this.subColumnCallback(value, column, subColumn, 'changeHandle', option)
        return
      }
      if (column.changeHandle) {
        this.columnCallback(value, column, 'changeHandle', option)
      }

      // 触发父级changeHandle
      if (this.parentType === 'todo-list' || this.parentType === 'group-list') {
        this.$emit('changeHandle', value, column, this.rowIndex)
      }

      // 触发zform组件上的change方法
      let findComponents = function(component) {
        if (component.$options.name === 'zform') {
          if (component._change) {
            component._change({ value, column })
          }
          return
        }
        if (component.$parent) {
          findComponents(component.$parent)
        }
      }
      findComponents(this)

      // 查看是否有关联关系
      if (
        !this.item.childProps ||
        Object.keys(this.item.childProps).length === 0
      ) {
        return
      }
      this.checkChildProps(this.item.childProps, value)
    },
    checkChildProps(childProps, value) {
      let updateProps = {}
      for (const key in childProps) {
        let finded = this.columns.find((item) => item.props === key)
        if (!finded) {
          continue
        }
        if (finded.childProps) {
          this.checkChildProps(finded.childProps)
        }
        finded.options = []
        if (value) {
          //finded.isDisabled = false
          finded.fetchParams[childProps[key]] = value.key
        } else {
          //finded.isDisabled = true
          finded.fetchParams[childProps[key]] = 0
        }
        updateProps[key] = ''
      }
      this.updateForm(updateProps, this.rowIndex)
    },
    setType2UUID(value, type, columnIndex) {
      if (this.type2UUID[type + value + columnIndex]) {
        return this.type2UUID[type + value + columnIndex]
      }
      const UUID = generateUUID() + value
      this.type2UUID[type + value + columnIndex] = UUID
      return UUID
    },
    getfieldNames(fieldNames, options) {
      let splitFlagChar = fieldNames.splitFlagChar || '|' // 字段分割标志
      let formatChar = fieldNames.format || '-' // 展示字符拼接标志
      return options.map((item) => {
        let textNames = fieldNames.text.split(splitFlagChar)
        let text = ''
        textNames.map((key, index) => {
          if (index === 0) {
            text = item[key]
          } else {
            if (item[key]) {
              text += formatChar + (item[key] || ' ')
            }
          }
        })
        return {
          value: item[fieldNames.value],
          text,
        }
      })
    },
    selectedBlur(item) {
      this.focusSelect = false
      item.onBlur && item.onBlur(item)
    },
    selectedFocus(item) {
      item.onFocus && item.onFocus(item)
    },
    handleClick(item) {
      this.focusSelect = true
    },
    selectFetch(value, item, callback) {
      item.fetching = true
      axios.get(item.searchApi).then((response) => {
        item.fetching = false
        callback(response.data)
      })
    },
    selectHandleSearch(value, item) {
      item.options = []
      this.selectFetch(value, item, (data) => {
        item.options = item.fieldNames
          ? this.getfieldNames(item.fieldNames, data)
          : data
      })
    },
    selectHandleChange(value, item) {
      this.changeHandleByValue(value, item)
    },
    selectedHandle(value, item, columnIndex) {
      value = item.labelInValue ? value.key : value

      const className = this.type2UUID[item.type + value + columnIndex]
      const selectDom = document.getElementsByClassName(className)[0]

      var allSelectDom = selectDom.parentNode.getElementsByTagName('li')
      var index = [].indexOf.call(allSelectDom, selectDom)
      if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
        if (this.$utt.activeType !== item.props) {
          this.setSelectTask(item.props, null, `点击select框-${item.label}`)
          if (item.mode === 'multiple') {
            this.$utt.activeType = item.props
          }
        }
        //let id = item.props+index
        this.setSelectTask(
          item.props + '-option' + index,
          value,
          `点击select options-${item.label}`
        )
      }

      // index就是点击的索引
    },
    setSelectTask(id, data, step) {
      if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
        //添加uitest日志
        let task = {}
        task.by_select = `id`
        task.by_val = id
        task.param = data || ''
        task.type = 'click'
        task.time = '2'
        task.step = step
        this.$utt.addTask(task)
      }
    },
  },
  watch: {
    value: function(val) {
      // 通过计数器来触发异步select第一次回显
      // 框架设计时回先触发一次重置操作，故第一次可能为空请求
      // 表单重置时需要为select框添加reset属性
      if (this.item.reset) {
        this.selectVal = val
        this.counter = 1
        this.item.reset = false
      }
      var exactValue = ''
      if (this.item.labelInValue) {
        exactValue = val ? val.key : ''
      } else if (this.item.mode === 'multiple') {
        exactValue = val.join(',')
      } else {
        exactValue = val
      }
      if (this.counter > 2) {
        this.selectVal = val
        return
      }
      this.counter++

      // 如果是disabled,且为labelInValue状态时，不用发起默认请求
      if (this.item.isDisabled && this.item.labelInValue && val.label) {
        this.item.options = [
          {
            text: val.label,
            value: val.key,
          },
        ]
        this.selectVal = val
        return
      }

      if (
        !this.item.option &&
        this.item &&
        this.item.searchFetch &&
        this.item.fieldNames &&
        this.item.fieldNames.value &&
        exactValue
      ) {
        this.item.fetchParams = this.item.fetchParams || {}
        exactValue = `${this.item.fieldNames.value}:${exactValue}`
        this.selectedFetchData(this.item, false, exactValue, () => {
          this.selectVal = val
        })
      } else {
        this.selectVal = val
      }
    },
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object, Date],
    },
    columns: {
      type: Array,
    },
    updateForm: {
      type: Function,
      default: null,
    },
    item: {
      type: Object,
    },
    size: {
      type: String,
    },
    form: {
      type: Object,
    },
    columnIndex: {
      type: Number,
    },
    parentType: {
      type: String,
    },
    rowIndex: {
      type: Number,
    },
  },
}
</script>

<style lang="less">
.zen-select {
  position: relative;
}
.zen-select-mask .ant-select-selection__rendered ul,
.zen-select-mask .ant-select-selection__rendered > div {
  height: 0;
  overflow: hidden;
}
.zen-select-mask_number .ant-select-selection--multiple .ant-select-selection__clear,
.zen-select-mask_number .ant-select-selection--multiple .ant-select-arrow {
  right: @space*7px
}
</style>
