<template>
	<div class="user-page">
		<u-header></u-header>
		<div class="container">
			<h3>业主大会执行秘书系统</h3>
			<div class="user-main">
				<transition name="fade-transform" mode="out-in">
					<router-view />
				</transition>
			</div>
		</div>
		<u-fotter></u-fotter>
	</div>
</template>

<script>
import uHeader from "./header.vue"
import uFotter from "./fotter.vue"
export default {
	name: "",
	data() {
		return {}
	},
	methods: {},
	components: {
		uHeader,
		uFotter
	}
}
</script>
<style lang="scss" scoped>
.user-page {
	height: 100vh;
	position: relative;
	.container {
		min-height: calc(100% - 150px - 80px);
		padding: 12px 0px 20px;
		background: url("../../assets/images/user_ys.png") no-repeat 0 0;
		background-size: 100% 100%;
		background-color: #f1f4fa;
		h3 {
			font-size: 27px;
			height: 30px;
			line-height: 30px;
			padding: 48px 0;
			font-weight: bold;
			color: #000000;
			text-align: center;
		}
		.user-main {
			min-height: 400px;
			display: flex;
			justify-content: center;
		}
	}
}
</style>