/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-12-07 16:08:10
 */
import axios from "axios";
import { notification, confirm, message } from "ant-design-vue";
//import store from '@/store'
import { getToken } from "./auth";
import errorCode from "./errorCode";
import router from "../router";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.NODE_ENV === 'production' ? null : process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 0,
});
// request拦截器
service.interceptors.request.use(
    (config) => {
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken === false;
        if (getToken() && !isToken) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    (error) => {
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode["default"];
        if (code === 201) {
            return res.data.data;
        } else if (code === 401) {
            confirm(
                "登录状态已过期，您可以继续留在该页面，或者重新登录",
                "系统提示", {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                // store.dispatch('LogOut').then(() => {
                //     location.href = '/index';
                // })
            });
        } else if (code === 500) {
            message({
                message: msg,
                type: "error",
            });
            return Promise.reject(new Error(msg));
        } else if (code !== 200) {
            notification.error({
                message: msg,
            });
            return Promise.reject("error");
        } else {
            return res.data.data;
        }
    },
    (error) => {
        let { code } = error.response.data || {};
        if (code === 401) {
            router.push({ path: "/login" });
            return;
        }
        return Promise.reject(error.response);
    }
);

export default service;