<template>
	<div class="zform-tablelist-box" style="overflow-y: auto">
		<a-form :form="form">
			<table border="1" :width="item.scroll || '100%'">
				<tr>
					<template v-for="(column, colindex) in item.subColumns">
						<th v-show="!column.isHidden" :key="colindex">
							<span
								v-if="
									isRequired(
										getItemRules(column.rules, column)
									)
								"
								style="color: red"
								>*</span
							>
							<span>{{ itemLabel(column) }}</span>
							<a-popover v-if="column.tooltipsInlabel">
								<template slot="content">
									<p
										v-html="
											column.tooltipsInlabel.content || ''
										"
									></p>
								</template>
								<a-icon
									:type="
										column.tooltipsInlabel.iconType ||
										'question-circle'
									"
									:style="{
										color:
											column.tooltipsInlabel.color ||
											'#0c85ee',
										marginLeft: '5px',
										verticalAlign: 'middle',
									}"
								/>
							</a-popover>
						</th>
					</template>
					<th v-if="showOperations(item)">
						{{ getOperationsText() }}
					</th>
				</tr>
				<tr
					v-for="(todo, rowindex) in tableListVal"
					:key="rowindex"
					:required="false"
					:class="item.isDisabled ? 'disabled' : ''"
				>
					<template v-for="(column, colindex) in item.subColumns">
						<td
							v-show="!column.isHidden"
							:key="colindex"
							:width="column.width || 'auto'"
						>
							<div v-if="!item.usePopup">
								{{ getColText(tableListVal[rowindex], column) }}
							</div>
							<a-form-item v-if="item.usePopup">
								<zinput
									v-if="column.type === 'input'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zinput>
								<zinput-number
									v-else-if="column.type === 'input-number'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zinput-number>
								<ztextArea
									v-else-if="column.type === 'textarea'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
									@blurHandle="blurHandle"
								></ztextArea>
								<zselect
									v-else-if="column.type === 'select'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:columns="item.subColumns"
									:updateForm="updateForm"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zselect>
								<!-- zdivider -->
								<zdivider
									v-else-if="column.type === 'divider'"
									:item="column"
									parentType="todo-list"
								></zdivider>
								<!-- zempty -->
								<zempty
									v-else-if="column.type === 'empty'"
									parentType="todo-list"
								></zempty>

								<!-- zbutton -->
								<zbutton
									v-else-if="column.type === 'button'"
									:item="column"
									:size="size"
									:form="form"
									:id="column.props"
								></zbutton>

								<!-- zbuttons -->
								<zbuttons
									v-else-if="column.type === 'buttons'"
									:item="column"
									:size="size"
									:form="form"
									:id="column.props"
								></zbuttons>

								<!-- ztag -->
								<ztag
									v-else-if="column.type === 'tag'"
									:item="column"
									:size="size"
									:form="form"
								></ztag>

								<zcheckbox
									v-else-if="column.type === 'checkbox'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zcheckbox>
								<zcheckboxGroup
									v-else-if="column.type === 'checkboxGroup'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zcheckboxGroup>
								<zswitch
									v-else-if="column.type === 'switch'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zswitch>
								<zdatepicker
									v-else-if="column.type === 'datepicker'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
									@blurHandle="blurHandle"
								></zdatepicker>
								<ztimepicker
									v-else-if="column.type === 'timepicker'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></ztimepicker>
								<zinput-search
									v-else-if="column.type === 'input-search'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
									@blurHandle="blurHandle"
								></zinput-search>
								<zcascader
									v-if="column.type === 'cascader'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:size="size"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
									@blurHandle="blurHandle"
								></zcascader>
								<zradio-group
									v-if="column.type === 'radioGroup'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: getItemRules(
												column.rules,
												column
											),
											validateFirst: true,
										},
									]"
									:item="column"
									:form="form"
									parentType="todo-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zradio-group>

								<zoss-upload
									v-if="column.type === 'oss-upload'"
									v-decorator="[
										setDecorator(column, rowindex),
										{
											initialValue:
												tableListVal[rowindex][
													column.props
												],
											rules: [
												...(getItemRules(
													column.rules,
													column
												) || []),
												{
													message: getUploadErrorMsg(),
													validator: (
														rule,
														value,
														callback
													) =>
														handleValidatorOssUpload(
															rule,
															value,
															callback,
															column
														),
												},
											],
											getValueFromEvent: uploadNormFile,
										},
									]"
									:item="column"
									:form="form"
									parentType="group-list"
									:rowIndex="rowindex"
									@changeHandle="changeHandle"
								></zoss-upload>
							</a-form-item>
						</td>
					</template>
					<td
						:width="item.actionWidth || '60px'"
						v-if="showOperations(item)"
					>
						<div class="tablelist-action" style="color: #0c85ee">
							<a-icon
								style="font-size: 16px; cursor: pointer"
								v-for="(btn, index1) in item.btns"
								:key="index1"
								:type="btn.icon || 'edit'"
								@click="
									btn.clickHandle &&
										btn.clickHandle(rowindex, tableListVal)
								"
							/>
							<a-icon
								:type="!item.usePopup ? 'edit' : 'plus'"
								style="font-size: 16px; cursor: pointer"
								@click="addHandle(rowindex)"
							/>
							<a-icon
								type="delete"
								v-if="!item.hiddenDeleteOperation"
								style="font-size: 16px; cursor: pointer"
								@click="deleteHandle(rowindex)"
							/>
						</div>
					</td>
				</tr>

				<tfoot v-if="showOperations(item)">
					<tr v-if="!item.hiddenAddOperation">
						<td
							:colspan="item.subColumns.length + 1"
							@click="addHandle(null)"
						>
							<a href="javascript:void(0)">
								<a-icon type="plus" />
								<span
									v-html="item.addText || 'Add field'"
								></span>
							</a>
						</td>
					</tr>
				</tfoot>
			</table>
			<!-- <a-button style="width: 100%" @click="addHandle" v-if="tableListVal&&tableListVal.length===0">
				<a-icon type="plus" />
				<span v-html="item.addText || 'Add field'"></span>
			</a-button>-->
		</a-form>

		<zmodal
			ref="tableListForm"
			title="Configuration Fields"
			:visible="visible"
			:destroyOnClose="true"
			@handleOk="zmodalHandleOk"
			@handleCancel="zmodalHandleCancel"
			:bodyStyle="{ margin: '24px 98px 0px 40px', padding: 0 }"
			:width="'80%'"
			:formConfig="tableListFormConfig"
			:formData="{}"
		></zmodal>
	</div>
</template>

<script>
import formItem from '../formItem'
import zinput from './zinput'
import zselect from './zselect'
import zinputNumber from './zinputNumber'
import ztextArea from './ztextArea'
import zcheckbox from './zcheckbox'
import zcheckboxGroup from './zcheckboxGroup'
import zswitch from './zswitch'
import zdatepicker from './zdatepicker'
import ztimepicker from './ztimepicker'
import zinputSearch from './zinputSearch'
import zcascader from './zcascader'
import zradioGroup from './zradioGroup'
import zossUpload from './zossUpload'
import zdivider from './zdivider'
import zempty from './zempty'
import zbutton from './zbutton'
import zbuttons from './zbuttons'
import ztag from './ztag'
var cloneDeep = require('lodash.clonedeep')

export default {
	name: 'tableList',
	data() {
		return {
			tableListVal: this.value
				? JSON.parse(JSON.stringify(this.value))
				: [],
			visible: false,
			tableListFormConfig: {
				formLayout: 'horizontal',
				col: '12',
				fixedLabelWidth: '180px',
				columns: [],
			},
			editIndex: null,
		}
	},
	components: {
		formItem,
		zinput,
		zselect,
		zinputNumber,
		ztextArea,
		zcheckbox,
		zcheckboxGroup,
		zradioGroup,
		zswitch,
		zdatepicker,
		ztimepicker,
		zinputSearch,
		zcascader,
		zossUpload,
		zdivider,
		zempty,
		zbutton,
		zbuttons,
		ztag,
	},
	methods: {
		generateUUID() {
			if (process.env.NODE_ENV === 'test') {
				return 'test-uuid'
			}
			let d = new Date().getTime()
			const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
				/[xy]/g,
				(c) => {
					const r = (d + Math.random() * 16) % 16 | 0
					d = Math.floor(d / 16)
					return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
				}
			)
			return uuid
		},
		getItemRules(rules, item) {
			if (!rules || rules.length === 0 || item.isHidden) {
				return []
			}
			if (item && item.isDisabled === true) {
				return []
			}
			let findedRequired = rules.find((item) => item.required === true)
			if (findedRequired) {
				findedRequired.message = '该字段不能为空'
			}
			return rules
		},
		isRequired(rules) {
			if (!rules) {
				return false
			}
			var result = false
			if (Array.isArray(rules)) {
				rules.map((item) => {
					if (item.required) {
						result = true
					}
				})
			}
			return result
		},
		getOperationsText() {
			return 'Operations'
			// return localStorage.getItem('lang') || 'zh_CN' == 'zh_CN'
			// 	? '操作'
			// 	: 'Operations'
		},
		getUploadErrorMsg() {
			return 'Please wait for the file to finish uploading'
			// return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
			// 	? '请等待文件上传完毕'
			// 	: 'Please wait for the file to finish uploading'
		},
		handleValidatorOssUpload(rule, value, callback, item) {
			if (!value || value.length === 0) {
				callback()
			}
			if (item.uploading) {
				callback(new Error('illegal'))
			}
			callback()
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		blurHandle(e, column) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = `${column.props}`
				task.param = `${e.target.value}`
				task.type = 'sendkey'
				task.time = '2'
				task.step = `修改input值-${column.label}`
				this.$utt.addTask(task)
			}
		},
		changeHandle(value, column, rowIndex) {
			if (!this.tableListVal[rowIndex]) {
				return
			}
			const params = {
				action: 'change',
				current: value,
				column: column,
				columns: this.tableListVal,
			}
			this.$emitHookFunction(params, (error, props = {}) => {
				if (!error) {
					this.tableListVal[rowIndex][column.props] = value
					this.$emit('change', this.tableListVal)
					this.emitFormChange(this.tableListVal)
				}
			})
		},
		deleteHandle(rowIndex) {
			const params = {
				current: this.tableListVal[rowIndex],
				action: 'delete',
				columns: this.tableListVal,
			}
			this.$emitHookFunction(params, (error, payload = {}) => {
				let tableListVal = cloneDeep(this.tableListVal)
				tableListVal.splice(
					this.type.isNumber(payload.props) || rowIndex,
					1
				)
				if (!error) {
					this.tableListVal.splice(rowIndex, 1)
					this.$emit('change', this.tableListVal)
				}
			})
		},
		addHandle(rowIndex) {
			let defaultNewItem = this.item.defaultNewItem
				? JSON.parse(JSON.stringify(this.item.defaultNewItem))
				: null

			let { tableListVal } = this

			let newItem = tableListVal[tableListVal.length - 1]
				? JSON.parse(
						JSON.stringify(tableListVal[tableListVal.length - 1])
				  )
				: {}
			let result = defaultNewItem || newItem

			const params = {
				action: 'add',
				current: result,
				formConfig: this.tableListFormConfig,
				columns: this.tableListVal,
			}
			if (!this.item.usePopup) {
				this.$emitHookFunction(params, (error, props = {}) => {
					this.visible = true
					this.tableListFormConfig.columns = this.item.subColumns
					this.editIndex = null
					if (rowIndex === 0 || rowIndex) {
						this.editIndex = rowIndex
						let formData = this.tableListVal[rowIndex]
						let updateGroup = []
						this.item.subColumns.map((item) => {
							if (
								item.type === 'todo-list' ||
								item.type === 'table-list' ||
								item.type === 'group'
							) {
								updateGroup.push(item.props)
							}
						})
						this.$refs.tableListForm.updateForm(
							formData,
							updateGroup
						)
					} else {
						this.$refs.tableListForm.resetFields()
					}
				})

				return
			}

			this.$emitHookFunction(params, (error, payload = {}) => {
				console.log(error, payload)
				if (!error) {
					rowIndex && rowIndex !== 0
						? this.tableListVal.splice(
								this.type.isNumber(payload.props) || rowIndex,
								0,
								newItem
						  )
						: this.tableListVal.push(payload.props || newItem)
					this.$emit('change', this.tableListVal)
					// return 12313
					return { props: 'custom return value' }
				}
			})
		},
		updateForm(formData, rowIndex) {
			let tableListVal = cloneDeep(this.tableListVal)
			this.tableListVal = []
			this.$nextTick(() => {
				if (rowIndex === 0 || rowIndex) {
					tableListVal[rowIndex] = Object.assign(
						tableListVal[rowIndex],
						JSON.parse(JSON.stringify(formData))
					)
				} else {
					tableListVal = JSON.parse(JSON.stringify(formData))
				}
				this.tableListVal = tableListVal
			})
			//this.form.setFieldsValue(formData)
		},
		emitFormChange(tableListVal) {
			let { item } = this
			let findComponents = function (component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value: tableListVal, column: item })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		zmodalHandleOk(e, values) {
			if (this.editIndex || this.editIndex === 0) {
				this.tableListVal[this.editIndex] = values
			} else {
				this.tableListVal.push(values)
			}
			this.$emit('change', this.tableListVal)
			this.visible = false
		},
		zmodalHandleCancel() {
			this.visible = false
		},
		getColText(record, column) {
			if (column.type == 'select' && column.labelInValue) {
				let res = record[column.props] || {}
				return res.label || '-'
			}
			return record[column.props] || '-'
		},
		itemLabel(column) {
			if (column.zh && column.en) {
				return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
					? column.zh
					: column.en
			}
			return column.label
		},
		setDecorator(column, rowindex) {
			var uuid = null
			if (column.uuid) {
				uuid = column.uuid
			} else {
				uuid = this.generateUUID()
				column.uuid = uuid
			}
			return column.props + uuid + rowindex
		},
		showOperations(column) {
			if (column.isDisabled || column.hiddenOperations) {
				return false
			}
			return true
		},
	},
	watch: {
		// value: function(val) {
		// 	this.tableListVal = [];
		// 	this.$nextTick(() => {
		// 		this.tableListVal = JSON.parse(JSON.stringify(val));
		// 	})
		// }
	},
	props: {
		value: {
			type: Array,
		},
		item: {
			type: Object,
		},
		size: {
			type: String,
		},
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
	},
	mounted() {
		this.$nextTick(() => {
			this.$createHookFunctions()
		})
		//this.tableListFormConfig.columns = this.item.subColumns
	},
}
</script>
<style lang="less">
.zform-tablelist-box {
	table {
		border: 1px solid #e8e8e8;
		border-radius: 4px 4px 0 0;
		th {
			background: #f1f3f7;
		}
		td:hover {
			background: #e6f7ff;
		}
		tr.disabled {
			background: #f1f3f7;
			opacity: 0.6;
		}
		tr.disabled td:hover {
			background: #f1f3f7;
			opacity: 0.6;
		}
	}
	td,
	th {
		border: 1px solid #e8e8e8;
		padding: 12px 8px;
	}
	tfoot td {
		padding: 6px 8px;
	}
	.ant-form-item {
		margin-bottom: 0;
	}
}
</style>
