/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 10:31:26
 */
import Type from './type'
export const findParentProps = (vm) => {
    let parent = vm.$parent, attrs, res = {}
    while (parent) {
        attrs = parent.$attrs
        if ('before' in attrs || 'after' in attrs) {
            res.before = attrs.before
            res.after = attrs.after
            return res
        }
        parent = parent.$parent
    }
    return res
}
export const createHookFunction = (fn) => (Type.isFunction(fn) || Type.isAsyncFunction(fn)) && fn || ((p, c) => c && c())
export const METHODS = {
    addBefore: null,
    addAfter: null,
    changeBefore: null,
    changeAfter: null,
    deleteBefore: null,
    deleteAfter: null
}
export const mergeParams = function (target) {
    if (!Type.isObject(target)) target = { target }
    const assignableValue = value => {
        for (let key in value) {
            target[key] = value[key] || Type.isNumber(value[key]) && value[key] || target[key]
        }
    }
    for (let i = 0; i < arguments.length; i++) {
        assignableValue(arguments[i])
    }
    return target
}


export const isArray = (value) => {
    return Object.prototype.toString.call(value) === '[object Array]'
}

export const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]'
}

export const getRawType = (value) => {
    return Object.prototype.toString.call(value).slice(8, -1)
}

function getStoreTableVariables(namespace) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    return `_${userInfo.uid}-${namespace}-table-config`
}

export const saveTableVariables = (namespace, columns) => {
    if (!namespace) {
        return
    }
    const tableVariablesKey = getStoreTableVariables(namespace)
    localStorage.setItem(tableVariablesKey, JSON.stringify(columns || []))
    return true
}

export const getTableVariables = (namespace) => {
    if (!namespace) {
        return
    }
    const tableVariablesKey = getStoreTableVariables(namespace)
    return JSON.parse(localStorage.getItem(tableVariablesKey) || '[]')
}

export const getFields = (arrayOfObject, key) => {
    let output = [];
    for (let i = 0; i < arrayOfObject.length; ++i)
        output.push(arrayOfObject[i][key]);
    return output;
}
