var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.subCol)?_c('a-checkbox-group',{staticClass:"zen-form--checkboxgroup",attrs:{"disabled":_vm.item.isDisabled,"size":_vm.size},on:{"change":function (e) {
                    _vm.changeCheckedGroupHandle(e, _vm.item);
                }},model:{value:(_vm.checkboxGroupVal),callback:function ($$v) {_vm.checkboxGroupVal=$$v},expression:"checkboxGroupVal"}},[_c('a-row',[_vm._l((_vm.item.options),function(checkboxItem,index){return [_c('a-col',{key:index,style:({
                            marginBottom: _vm.item.marginBottom + 'px' || '0px'
                        }),attrs:{"span":_vm.item.subCol}},[_c('a-checkbox',{attrs:{"disabled":checkboxItem.isDisabled,"value":checkboxItem.value},on:{"click":function($event){return _vm.clickTask(
                                    index,
                                    checkboxItem,
                                    _vm.item,
                                    ("checkbox选择值-" + (_vm.item.label)),
                                    'div/div/'
                                )}}},[_vm._v(_vm._s(checkboxItem.label))])],1)]})],2)],1):_c('a-checkbox-group',{attrs:{"disabled":_vm.item.isDisabled,"size":_vm.size},on:{"change":function (e) {
                    _vm.changeCheckedGroupHandle(e, _vm.item);
                }},model:{value:(_vm.checkboxGroupVal),callback:function ($$v) {_vm.checkboxGroupVal=$$v},expression:"checkboxGroupVal"}},_vm._l((_vm.item.options),function(checkboxItem,index){return _c('a-checkbox',{key:index,attrs:{"disabled":checkboxItem.isDisabled,"value":checkboxItem.value},on:{"click":function($event){return _vm.clickTask(
                        index,
                        checkboxItem,
                        _vm.item,
                        ("checkbox选择值-" + (_vm.item.label))
                    )}}},[_vm._v(_vm._s(checkboxItem.label))])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }