var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-upload',{class:['_zen-oss-upload', _vm.item.uploadedListLayout],attrs:{"listType":_vm.item.listType || 'picture-card',"name":_vm.item.name || 'files',"fileList":_vm.fileList,"defaultFileList":_vm.value,"before-upload":function (file, fileList) { return _vm.ossUploadBeforeUpload(file, fileList, _vm.item); },"remove":function (file) {
				return _vm.uploadRemoveHandle(file, _vm.item)
			},"disabled":_vm.item.isDisabled,"showUploadList":_vm.item.showUploadList,"customRequest":function (obj) {
				_vm.ossUploadCustomRequest(obj, _vm.item)
			}},on:{"change":function (obj) { return _vm.ossUploadChangeHandle(obj, _vm.item); },"preview":function (file) {
				_vm.ossUploadPreviewHandle(file, _vm.item)
			}}},[(!_vm.item.maxUpload || _vm.fileList.length < _vm.item.maxUpload)?[(
					_vm.item.listType === 'picture' || _vm.item.listType === 'text'
				)?_c('a-button',{ref:"uploadFile",attrs:{"disabled":_vm.item.isDisabled,"loading":_vm.uploading}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" "+_vm._s(_vm.item.uploadText)+" ")],1):_c('div',{ref:"uploadFile"},[_c('a-icon',{staticStyle:{"width":"19px","height":"18px"},attrs:{"type":_vm.uploading ? 'loading' : 'upload'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.item.uploadText))])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }