/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-08-18 11:25:29
 */
export function zCustom(Vue) {
  return Vue.directive('custom', {});
}

export default {
  install: Vue => {
    zCustom(Vue);
  },
};
