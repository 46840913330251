/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-01-23 20:11:34
 */
import Vue from "vue";
import Router from "vue-router";
import userLayout from "@/layout/user/index.vue";
import Layout from "@/layout/index.vue";
import communityRoute from "./community";
import thinkTankRoute from "./thinkTank";
import fileManagementRoute from "./fileManagement";
import dailyworkRoute from "./dailywork";
import financeRoute from "./finance";
import conferenceWorkbenchRoute from "./conferenceWorkbench";
import { getToken, removeToken } from "@/utils/auth";
Vue.use(Router);
export const selfRouter = [
  {
    path: "/",
    name: "index",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { keepAlive: true },
        component: () =>
          import(/* webpackChunkName: 'dashboard'*/ "@/views/dashboard.vue"),
      },
      {
        path: "floor",
        name: "floor",
        meta: { keepAlive: true },
        component: () =>
          import(
            /* webpackChunkName: 'dashboard'*/ "@/views/community/floor/index.vue"
          ),
      },
      {
        path: "/tableTest",
        name: "tableTest",
        meta: { keepAlive: true },
        component: () =>
          import(/* webpackChunkName: 'dashboard'*/ "@/views/tableTest.vue"),
      },
      {
        path: "/newsDetail",
        name: "newsDetail",
        meta: { keepAlive: true },
        component: () =>
          import(/* webpackChunkName: 'dashboard'*/ "@/views/newsDetail.vue"),
      },
    ],
  },

  {
    name: "login",
    path: "",
    component: userLayout,
    children: [
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: 'index'*/ "@/views/user/login.vue"),
      },
      {
        path: "/register",
        component: () =>
          import(/* webpackChunkName: 'index'*/ "@/views/user/register.vue"),
      },
      {
        path: "/forgetPassword",
        component: () =>
          import(
            /* webpackChunkName: 'index'*/ "@/views/user/forgetPassword.vue"
          ),
      },
      {
        path: "/mockLogin",
        component: () =>
          import(
            /* webpackChunkName: 'index'*/ "@/views/user/mockLogin.vue"
          ),
      },
    ],
  },
  {
    name: "mine",
    path: "",
    component: Layout,
    children: [
      {
        path: "/mine",
        component: () =>
          import(/* webpackChunkName: 'index'*/ "@/views/mine/index.vue"),
        children: [
          {
            path: "basicSettings",
            component: () =>
              import(/* webpackChunkName: 'index'*/ "@/views/mine/basicSettings.vue"),
          },
          {
            path: "securitySettings",
            component: () =>
              import(/* webpackChunkName: 'index'*/ "@/views/mine/securitySettings.vue"),
          },
        ]
      },
      // {
      //   path: "/register",
      //   component: () =>
      //     import(/* webpackChunkName: 'index'*/ "@/views/user/register.vue"),
      // },
      // {
      //   path: "/forgetPassword",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'index'*/ "@/views/user/forgetPassword.vue"
      //     ),
      // },
    ],
  },
  { path: "*", redirect: "/login" },
  {
    name: 'mpWebView', path: "/mp-web-view", component: () =>
      import("@/views/webView"),
  },
  communityRoute,
  dailyworkRoute,
  financeRoute,
  conferenceWorkbenchRoute,
  thinkTankRoute,
  fileManagementRoute
]
let router = new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: [...selfRouter]
});

router.beforeEach((to, from, next) => {
  const whiteList = ["/login", "/register", "/forgetPassword", "/aboutUs.html", "/mockLogin", "/mockLogin", "/mockLogin", "/mp-web-view"];
  if (whiteList.indexOf(to.path) !== -1) {
    // 在免登录白名单，直接进入
    next();
    return;
  }
  if (!getToken()) {
    // 没有token的情况下
    removeToken(); // 清理相关token
    next(`/login`); // 重定向到登录页
  }else {
    next();
  }
  // // 判断是否为 街镇办事服务页面，不是则删除该页面下到缓存
  // if (to.name !== "tsAgency" && to.name !== "tsAgencyMembers") {
  //   localStorage.removeItem("tsAgencyType");
  // }
  
});
export default router;
