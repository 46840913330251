<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 18:50:52
-->
<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
			<div class="logo" @click="goRouter('/dashboard')">
				<img src="@/assets/images/logo.png" alt="" />
			</div>
			<a-menu
				theme="dark"
				mode="inline"
				:default-selected-keys="defaultKeys"
				:open-keys.sync="openKeys"
			>
				<template v-for="menu in menus">
					<a-menu-item
						:key="menu.path"
						v-if="!menu.children"
						@click="menuHandle(menu.path, null, menu)"
					>
						<i class="ywh-icon anticon anticon-menu">
								<img :src="menu.icon"/>
							</i>
						<span>{{ menu.name }}</span>
					</a-menu-item>
					<a-sub-menu :key="menu.path" v-else>
						<span slot="title">
							<i class="ywh-icon anticon anticon-menu">
								<img :src="menu.icon"/>
							</i>
							<span>{{ menu.name }}</span>
						</span>
						<a-menu-item
							v-for="subMenu in menu.children"
							:key="subMenu.path"
							@click="menuHandle(subMenu.path, menu.path, menu)"
						>
							<!-- <a-icon :type="subMenu.icon" class="ywh-icon" /> -->
							{{ subMenu.name }}
						</a-menu-item>
					</a-sub-menu>
				</template>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header style="background: #fff" id="ywh-header">
				<div>
					<a-icon
						class="trigger"
						:type="collapsed ? 'menu-unfold' : 'menu-fold'"
						@click="() => (collapsed = !collapsed)"
					/>
					<span
						style="cursor: pointer"
						@click="goRouter('/dashboard')"
						>业主大会执行秘书系统</span
					>
				</div>
				<div style="display: flex">
					<a-dropdown
						v-if="uocList.length > 0"
						:overlayStyle="{ width: '120px' }"
						placement="bottomRight"
					>
						<div
							class="userinfo-content"
							style="display: flex; align-items: center"
						>
							切换业委会
							<span style="cursor: pointer; margin-left: 12px"
								>{{ uocSelect }}
							</span>
							<a-icon type="down" />
						</div>
						<a-menu slot="overlay">
							<a-menu-item
								v-for="item of uocList"
								:key="item._id"
								@click="switchUOC(item._id)"
							>
								{{ item.name }}
							</a-menu-item>
						</a-menu>
					</a-dropdown>
					<span v-else> 当前业委会：{{ uocSelect }} </span>

					<a-dropdown
						:overlayStyle="{ width: '120px' }"
						placement="bottomRight"
					>
						<div class="userinfo-content">
							<a-avatar :src="userInfo.avatar" />
							<span style="cursor: pointer; margin-left: 12px"
								>{{ userInfo.username }}
							</span>
						</div>
						<a-menu slot="overlay">
							<a-menu-item
								key="0"
								@click="goRouter('/mine/basicSettings')"
							>
								基本设置
							</a-menu-item>
							<a-menu-item
								key="1"
								@click="goRouter('/mine/securitySettings')"
							>
								安全设置
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item key="3" @click="logoutHandle">
								退出登录
							</a-menu-item>
						</a-menu>
					</a-dropdown>
				</div>
			</a-layout-header>
			<a-layout-content
				:style="{
					padding: '16px 16px 50px 16px',
					height: getWinHeight,
					background: '#F5F5F5',
					overflowY: 'auto',
				}"
			>
				<router-view style="height: 100%" />
			</a-layout-content>
			<div class="layout-links-center">
				<a href="/privacyPolicy.html" target="_blank">隐私政策</a>
				<a href="/siteMap.html" target="_blank">站点地图</a>
				<a href="/aboutUs.html" target="_blank">关于我们</a>
				<a
					href="https://beian.miit.gov.cn/#/Integrated/index"
					target="_blank"
					>沪ICP备2020035086号-1</a
				>
				<a href="#">
					<img style="width: 18px;height: 18px;" src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/huhuiyun_gongan.png">
					沪公网备案 31010102007070
				</a>
			</div>
		</a-layout>
		<div class="contact-us-content">
			<img
				class="lx-icon"
				src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_lx.svg"
			/>
			<div style="width: 14px">联系我们</div>
			<div class="cu-detail">
				<a-card
					:headStyle="{
						background: '#000729',
						color: 'white',
						borderBottom: '2px solid white',
					}"
					:bodyStyle="{ background: '#000729', color: 'white' }"
					title="工作时间：9:00～5:30"
					:bordered="false"
					style="width: 300px; color: white"
				>
					<p>座机：021-53067708</p>
					<p>微信：18018697278</p>
					<p>QQ：3234887997</p>
					<a-form
						:form="form"
						layout="vertical"
						@submit="handleNoteSubmit"
					>
						<a-form-item>
							<span slot="label" style="color: white">
								在线留言：
							</span>
							<a-textarea
								v-decorator="[
									'note',
									{
										rules: [
											{
												required: true,
												message: '请输入',
											},
										],
									},
								]"
							>
							</a-textarea>
						</a-form-item>
						<a-form-item>
							<a-button
								type="default"
								size="small"
								html-type="submit"
							>
								提交
							</a-button>
						</a-form-item>
					</a-form>
				</a-card>
			</div>
		</div>
	</a-layout>
</template>
<script>
import { getMenu } from '@/api/user/index'
import { getUOCWithPhone, switchUocLogin } from '@/api/user/login'
import { setMenu, removeToken } from '@/utils/auth'
import { setToken } from '@/api/auth'
import { createContactUs } from '@/api/contactUs'
const selfRouter = [
	{
		templateFiles: [],
		_id: '600c0eb9a8300bbe38ff2a38',
		name: '社区信息',
		parentId: '',
		icon: 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E9%A6%96%E9%A1%B5%E8%8F%9C%E5%8D%95%E5%9B%BE%E6%A0%87/%E5%B0%8F%E5%8C%BA%20.svg',
		path: '/community',
		seq: 1,
		createdBy: '5fabf67d0ed99c6112b837e5',
		createdAt: '2021-01-23T11:55:37.424Z',
		updateAt: '2021-01-23T11:55:37.424Z',
		__v: 0,
		children: [
			{
				templateFiles: [],
				_id: '600c1044a8300bbe38ff2a3d',
				name: '业委会信息',
				parentId: '600c0eb9a8300bbe38ff2a38',
				icon: 'menu',
				path: '/ywh',
				seq: 5,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-01-23T12:02:12.380Z',
				updateAt: '2021-01-23T12:02:12.380Z',
				__v: 0,
			},
			{
				templateFiles: [],
				_id: '600c108ca8300bbe38ff2a3e',
				name: '物业管理信息',
				parentId: '600c0eb9a8300bbe38ff2a38',
				icon: 'menu',
				path: '/property',
				seq: 6,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-01-23T12:03:24.460Z',
				updateAt: '2021-01-23T12:03:24.460Z',
				__v: 0,
			},
			{
				templateFiles: [],
				_id: '600c108ca8300bbe38ff2a4e',
				name: '小区公告',
				parentId: '600c0eb9a8300bbe38ff2a38',
				icon: 'menu',
				path: '/officialNews',
				seq: 7,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-01-23T12:03:24.460Z',
				updateAt: '2021-01-23T12:03:24.460Z',
				__v: 0,
			},
		],
	},
	{
		templateFiles: [],
		_id: '600c0f26a8300bbe38ff2a39',
		name: '资产管理信息',
		parentId: '',
		icon: 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E9%A6%96%E9%A1%B5%E8%8F%9C%E5%8D%95%E5%9B%BE%E6%A0%87/%E8%B5%84%E4%BA%A7.svg',
		path: '/community/floor',
		seq: 2,
		createdBy: '5fabf67d0ed99c6112b837e5',
		createdAt: '2021-01-23T11:57:26.849Z',
		updateAt: '2021-01-23T11:57:26.849Z',
		__v: 0,
	},
	{
		templateFiles: [],
		_id: '600c0f68a8300bbe38ff2a3a',
		name: '社区智库',
		parentId: '',
		icon: 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E9%A6%96%E9%A1%B5%E8%8F%9C%E5%8D%95%E5%9B%BE%E6%A0%87/%E6%99%BA%E5%BA%93.svg',
		path: '/thinkTank',
		seq: 3,
		createdBy: '5fabf67d0ed99c6112b837e5',
		createdAt: '2021-01-23T11:58:32.168Z',
		updateAt: '2021-01-23T11:58:32.168Z',
		__v: 0,
		children: [
			{
				templateFiles: [],
				_id: '60583fb87f448455099e60d6',
				name: '示范文本',
				path: '/modelText',
				parentId: '600c0f68a8300bbe38ff2a3a',
				icon: 'menu',
				seq: 4,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-03-22T06:56:56.781Z',
				updateAt: '2021-03-22T06:56:56.781Z',
				__v: 0,
			},
			{
				templateFiles: [],
				_id: '60583fef7f448455099e60d7',
				name: '政策法规',
				path: '/policiesRegulations',
				parentId: '600c0f68a8300bbe38ff2a3a',
				icon: 'menu',
				seq: 5,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-03-22T06:57:51.272Z',
				updateAt: '2021-03-22T06:57:51.272Z',
				__v: 0,
			},
			{
				templateFiles: [],
				_id: '605840087f448455099e60d8',
				name: '社区课堂',
				path: '/communityClassroom',
				parentId: '600c0f68a8300bbe38ff2a3a',
				icon: 'menu',
				seq: 6,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-03-22T06:58:16.787Z',
				updateAt: '2021-03-22T06:58:16.787Z',
				__v: 0,
			},
		],
	},
	{
		templateFiles: [],
		_id: '600c0f96a8300bbe38ff2a3b',
		name: '会议工作台',
		parentId: '',
		icon: 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E9%A6%96%E9%A1%B5%E8%8F%9C%E5%8D%95%E5%9B%BE%E6%A0%87/%E4%BC%9A%E8%AE%AE.svg',
		path: '/conferenceWorkbench/list',
		seq: 4,
		createdBy: '5fabf67d0ed99c6112b837e5',
		createdAt: '2021-01-23T11:59:18.648Z',
		updateAt: '2021-01-23T11:59:18.648Z',
		__v: 0,
	},
	{
		templateFiles: [],
		_id: '600c10caa8300bbe38ff2a3f',
		name: '档案管理信息',
		parentId: '',
		icon: 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E9%A6%96%E9%A1%B5%E8%8F%9C%E5%8D%95%E5%9B%BE%E6%A0%87/%E6%A1%A3%E6%A1%88.svg',
		path: '/fileManagement',
		seq: 7,
		createdBy: '5fabf67d0ed99c6112b837e5',
		createdAt: '2021-01-23T12:04:26.028Z',
		updateAt: '2021-01-23T12:04:26.028Z',
		__v: 0,
		children: [
			{
				templateFiles: [],
				_id: '600c10f5a8300bbe38ff2a40',
				name: '静态档案管理',
				parentId: '600c10caa8300bbe38ff2a3f',
				icon: 'menu',
				path: '/static',
				seq: 8,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-01-23T12:05:09.613Z',
				updateAt: '2021-01-23T12:05:09.613Z',
				__v: 0,
			},
			{
				templateFiles: [],
				_id: '600c1102a8300bbe38ff2a41',
				name: '动态档案管理',
				parentId: '600c10caa8300bbe38ff2a3f',
				icon: 'menu',
				path: '/dynamic',
				seq: 9,
				createdBy: '5fabf67d0ed99c6112b837e5',
				createdAt: '2021-01-23T12:05:22.950Z',
				updateAt: '2021-01-23T12:05:22.950Z',
				__v: 0,
			},
		],
	},
]

export default {
	data() {
		return {
			form: this.$form.createForm(this, { name: 'coordinated' }),
			collapsed: false,
			clientHeight: document.body.clientHeight,
			menus: [],
			defaultKeys: ['/dashbord'],
			openKeys: [],
			userInfo: {},
			uocList: [],
			uocSelect: null,
		}
	},
	computed: {
		getWinHeight() {
			return this.clientHeight - 62 - 23 + 'px'
		},
	},
	mounted() {
		var userAgent = navigator.userAgent
		const isChrome = userAgent.indexOf('Chrome') > -1
		if (!isChrome) {
			this.$notification.warning({
				message: '推荐切换到谷歌(Chrome)浏览器',
				description: () => {
					return (
						<span>
							请下载
							<a
								href="https://www.google.cn/chrome/"
								target="_blank"
							>
								谷歌浏览器
							</a>
							以获取最佳访问效果，避免因为浏览器兼容性问题影响您的使用。
						</span>
					)
				},
				duration: null,
			})
		}

		window.onresize = () => {
			this.clientHeight = document.body.clientHeight
		}
		setMenu(selfRouter)
		this.menus = selfRouter
		try {
			const localUserInfo = localStorage.getItem('userInfo')
			this.userInfo = JSON.parse(localUserInfo)
		} catch (error) {
			console.log(error)
		}
		if (this.userInfo) {
			getUOCWithPhone(this.userInfo.phone, {
				type: this.userInfo.type,
			}).then((res) => {
				this.uocList = res.uocList
				let finded = this.uocList.find(
					(item) => item._id === this.userInfo.uocId
				)
				this.uocSelect = finded.name
			})
		}

		window.addEventListener('setItemEvent', (e) => {
			if (e.key === 'userInfo') {
				this.userInfo = JSON.parse(e.newValue)
			}
		})
	},
	methods: {
		handleNoteSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (err) {
					console.log(err)
					return
				}
				let userInfo = this.userInfo || {}
				createContactUs({...values, userInfo})
					.then((res) => {
						this.$message.success('提交成功！')
					})
					.catch((err) => {
						this.$message.error('提交失败！')
					})
			})
		},
		switchUOC(uocId) {
			const { phone, type } = this.userInfo
			switchUocLogin({ uocId, phone, type }).then((res) => {
				localStorage.setItem('userInfo', JSON.stringify(res))
				setToken(res.token)
				location.reload()
			})
		},
		goRouter(url) {
			this.$router.push(url)
		},
		logoutHandle() {
			removeToken() // 清理相关token
			this.$router.replace('/login')
		},
		menuSelected({ item, key }) {
			this.$router.push({
				path: key,
			})
		},
		menuHandle(key, parentKey, menu) {
			let path = parentKey ? parentKey + key : key
			this.$router.push({
				path,
				query: { templateFiles: menu.templateFiles },
				// query: {
				//   //   templateFiles: menu.templateFiles,
				//   test: 123,
				// },
			})
		},
	},
	watch: {
		$route: {
			handler(to, from) {
				this.defaultKeys = []
				this.openKeys = []
				let { matched } = to
				matched.map((item, index) => {
					if (index === matched.length - 1) {
						let parentPath = this.openKeys.join('/')
						this.defaultKeys.push(item.path.split(parentPath)[1])
					} else {
						this.openKeys.push(item.path)
					}
				})
			},
			immediate: true,
		},
	},
}
</script>
<style lang="scss">
.contact-us-content {
	position: fixed;
	z-index: 999999;
	right: 0px;
	top: 30%;
	width: 48px;
	background: #2140d9;
	box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 15%);
	border-radius: 4px;
	color: white;
	font-size: 14px;
	font-weight: bold;
	color: #ffffff;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	cursor: pointer;
	padding: 14px 0px;
	.lx-icon {
		margin-bottom: 12px;
	}
	&:hover {
		.cu-detail {
			// display: block;
			visibility: visible;
			opacity: 1;
		}
	}
	.cu-detail {
		position: absolute;
		// display: none;
		right: 48px;
		top: 0px;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.userinfo-content {
	padding: 0px 12px;
	cursor: pointer;
	&:hover {
		background: rgba(0, 0, 0, 0.025);
	}
}
.layout-links-center {
	background: rgb(245, 245, 245);
	@include links-position(center);
	a {
		color: #999;
	}
	a:hover {
		color: #2140d9;
	}
}
#components-layout-demo-custom-trigger .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
	vertical-align: -1px;
}

#components-layout-demo-custom-trigger .trigger:hover {
	color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
	cursor: pointer;
	height: 64px;
	background: #000b40;
	padding: 12px 32px;
	overflow: hidden;
	img {
		width: 124px;
		height: 40px;
	}
}
.ant-layout-header {
}
#ywh-header {
	@include space-between;
	padding: 0px 32px 0px 0px;
}
</style>
