<template>
	<div>
		<a-modal
			class="ywh-modal"
			:title="title"
			:visible="visible"
			:confirm-loading="loading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
			:width="width"
		>
			<zform
				:formConfig="form_config"
				:formData="{}"
				ref="modal-form"
			></zform>
		</a-modal>
	</div>
</template>

<script>
import request from '@/api/request'
export default {
	name: 'modal',
	props: {
		width: String,
		title: String,
		columns: Array,
		formComfig: Object,
		api: String,
	},
	data() {
		return {
			visible: false,
			loading: false,
		}
	},
	methods: {
		showModal() {
			this.visible = true
		},
		updateForm(formValue) {
			if (formValue) {
				return
			}
			// 获取接口，更新数据
			request({
				url: this.api,
				method: 'get',
			}).then((res) => {
				this.$refs['modal-form'].updateForm(res, true)
			})
		},
		handleModalOk() {
			this.$refs['modal-form']
				.zformValidateFieldsAndScroll()
				.then((res) => {
					this.visible = true
					this.loading = true
					request({
						url: this.api,
						method: 'put',
						data: { ...res },
					})
						.then((res) => {
							this.$message.success('更新成功')
							this.visible = false
							this.$emit('successAction', res)
						})
						.finally((res) => {
							this.loading = false
						})
				})
		},
		handleModalCancel() {
			this.visible = false
		},
	},
	created() {},
	components: {},
	computed: {
		form_config() {
			if (this.formComfig) {
				return this.formComfig
			}
			let columns = this.columns || []
			return {
				col: 12,
				formLayout: 'vertical',
				textAlign: 'left',
				columns,
			}
		},
	},
}
</script>

<style></style>
