<template>
	<div :class="['zen-form', formSizeClass]">
		<a-divider orientation="left" v-if="formConfig.divider">{{
			formConfig.divider
		}}</a-divider>
		<a-form
			:form="form"
			:label-col="getLabelCol"
			:wrapper-col="getWrapperCol"
		>
			<a-row
				id="zform-row-flex"
				:type="formConfig.rowType"
				:gutter="24"
				:style="{
					marginRight: `${formConfig.space || 12}px`,
				}"
			>
				<template v-for="(item, columnIndex) in formConfig.columns">
					<a-col
						v-show="
							item.isHidden != undefined ? !item.isHidden : true
						"
						:key="columnIndex"
						:span="
							formConfig.rowType
								? null
								: formConfig.inDraggerDev
								? 24
								: item.col
								? item.col
								: formConfig.col || 24
						"
						v-bind="formConfig.wrapperCol"
						:style="{
							paddingRight: `${formConfig.space || 12}px`,
							...item.style,
						}"
						:class="'_zen-form-item-' + item.type"
					>
						<form-item
							v-if="item.type === 'text'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<div
								:class="item.className"
								v-html="item.text"
							></div>
						</form-item>
						<form-item
							v-if="item.type === 'input'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zinput
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zinput>
						</form-item>
						<!-- divider -->
						<form-item
							v-if="item.type === 'divider'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zdivider :item="item"></zdivider>
						</form-item>
						<!-- empty -->
						<form-item
							v-if="item.type === 'empty'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zempty></zempty>
						</form-item>
						<!-- button -->
						<form-item
							v-if="item.type === 'button'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zbutton
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zbutton>
						</form-item>
						<!-- buttons -->
						<form-item
							v-if="item.type === 'buttons'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zbuttons
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zbuttons>
						</form-item>
						<!-- button -->
						<form-item
							v-if="item.type === 'tag'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<ztag :item="item" :form="form"></ztag>
						</form-item>
						<!--input number-->
						<form-item
							v-if="item.type === 'input-number'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zinput-number
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zinput-number>
						</form-item>
						<!--textarea-->
						<form-item
							v-if="item.type === 'textarea'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<ztextArea
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></ztextArea>
						</form-item>
						<!-- checkbox -->
						<form-item
							v-if="item.type === 'checkbox'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zcheckbox
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zcheckbox>
						</form-item>
						<!-- checkboxGroup -->
						<form-item
							v-if="item.type === 'checkboxGroup'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zcheckboxGroup
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zcheckboxGroup>
						</form-item>
						<!-- radio -->
						<form-item
							v-if="item.type === 'radio'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<a-radio
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:disabled="item.isDisabled || false"
								:size="formConfig.size"
								>{{ item.label || item.title }}</a-radio
							>
						</form-item>
						<!-- radioGroup -->
						<form-item
							v-if="item.type === 'radioGroup'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zradio-group
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zradio-group>
						</form-item>
						<!-- switch -->
						<form-item
							v-if="item.type === 'switch'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zswitch
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zswitch>
						</form-item>
						<!-- datepicker -->
						<form-item
							v-if="item.type === 'datepicker'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zdatepicker
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zdatepicker>
						</form-item>
						<!-- timepicker -->
						<form-item
							v-if="item.type === 'timepicker'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<ztimepicker
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></ztimepicker>
						</form-item>
						<!--input search-->
						<form-item
							v-if="item.type === 'input-search'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zinput-search
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zinput-search>
						</form-item>
						<!--cascader-->
						<form-item
							v-if="item.type === 'cascader'"
							:form="form"
							:item="item"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zcascader
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
							></zcascader>
						</form-item>
						<!--select-->
						<form-item
							v-if="item.type === 'select'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zselect
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:columns="formConfig.columns"
								:updateForm="updateForm"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
								:columnIndex="columnIndex"
							></zselect>
						</form-item>
						<!--slot-->
						<form-item
							v-if="item.type === 'slot'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
						>
							<slot :name="item.slotName" :data="form"></slot>
						</form-item>

						<!--todo list  (oss PR)-->
						<form-item
							v-if="item.type === 'todo-list'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<todo-list
								:ref="'zform-' + item.props"
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
								:columnIndex="columnIndex"
							></todo-list>
						</form-item>

						<!--table list-->
						<form-item
							v-if="item.type === 'table-list'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<table-list
								:ref="'zform-' + item.props"
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
								:columnIndex="columnIndex"
							></table-list>
						</form-item>

						<!--group-->
						<form-item
							v-if="item.type === 'group'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
							class="zen-group-item"
						>
							<zgroup
								:ref="'zform-' + item.props"
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: false,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
								@blurHandle="blurHandle"
								:columnIndex="columnIndex"
							></zgroup>
						</form-item>
						<!--upload-->
						<form-item
							v-if="item.type === 'upload'"
							:form="form"
							:item="item"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zupload
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zupload>
							<!-- <a-upload
								v-decorator="[item.props, {
                                        valuePropName: 'fileList',
                                        getValueFromEvent: uploadNormFile,
                                    }]"
								:listType="item.listType || 'picture-card'"
								:name="item.name || 'files'"
								:action="item.action"
								list-type="picture"
								@preview="(file)=>{uploadPreviewHandle(file,item)}"
								:remove="(file)=>{return uploadRemoveHandle(file,item)}"
								:disabled="item.isDisabled"
								:showUploadList="item.showUploadList"
								:beforeUpload="(file)=>{return beforeUpload(file,item)}"
								:customRequest="(obj)=>{uploadCustomRequest(obj,item)}"
								:accept="item.accept || null"
							>
								<a-button v-if="item.listType === 'picture' || item.listType === 'text'">
									<a-icon type="upload" />upload
								</a-button>
								<div v-else>
									<a-icon :type="loading ? 'loading' : 'plus'" />
									<div class="ant-upload-text">Upload</div>
								</div>
							</a-upload>-->
						</form-item>

						<!--oss-upload-->
						<form-item
							v-if="item.type === 'oss-upload'"
							:form="form"
							:item="item"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<zoss-upload
								v-decorator="[
									item.props,
									{
										initialValue: newFormData[item.props],
										rules: [
											...(getItemRules(
												item.rules,
												item
											) || []),
											{
												message: getUploadErrorMsg(),
												validator: (
													rule,
													value,
													callback
												) =>
													handleValidatorOssUpload(
														rule,
														value,
														callback,
														item
													),
											},
										],
										getValueFromEvent: uploadNormFile,
									},
								]"
								:item="item"
								:size="formConfig.size"
								:form="form"
							></zoss-upload>
							<!-- <a-upload
								v-decorator="[item.props, {
                                        valuePropName: 'fileList',
                                        getValueFromEvent: uploadNormFile,
                                    }]"
								:listType="item.listType || 'picture-card'"
								:name="item.name || 'files'"
								list-type="picture"
								@preview="(file)=>{ossUploadPreviewHandle(file,item)}"
								:remove="(file)=>{return uploadRemoveHandle(file,item)}"
								:disabled="item.isDisabled"
								:showUploadList="item.showUploadList"
								:customRequest="(obj)=>{ossUploadCustomRequest(obj,item)}"
							>
								<a-button v-if="item.listType === 'picture' || item.listType === 'text'">
									<a-icon type="upload" />upload
								</a-button>
								<div v-else>
									<a-icon :type="loading ? 'loading' : 'plus'" />
									<div class="ant-upload-text">Upload</div>
								</div>
							</a-upload>-->
						</form-item>
						<!--tree-select-->
						<form-item
							v-if="item.type === 'tree-select'"
							:form="form"
							:item="item"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
							:allowClear="item.allowClear"
							:class="[
								'zen-form-mask_wrap',
								{ 'zen-select-mask': item.mask },
							]"
						>
							<a-tree-select
								v-decorator="[
									item.props,
									{
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:placeholder="item.placeholder || ''"
								:dropdownStyle="{
									maxHeight: item.maxHeight || '300px',
									overflow: 'auto',
								}"
								:treeDefaultExpandAll="
									item.treeDefaultExpandAll
								"
								:getPopupContainer="
									(target) => target.parentNode
								"
								:treeData="item.treeData"
								:treeCheckable="item.treeCheckable || false"
								:showCheckedStrategy="item.showCheckedStrategy || 'SHOW_ALL'"
								treeNodeFilterProp="title"
								:labelInValue="item.labelInValue || false"
								@change="
									(value) => {
										changeHandleByValue(value, item)
									}
								"
								@click="
									() => {
										treeSelectClickHandle(item)
									}
								"
								:allowClear="item.allowClear || false"
								:showSearch="item.showSearch || false"
								:multiple="item.multiple || false"
								:disabled="item.isDisabled"
								:size="formConfig.size"
							></a-tree-select>
							<div class="zen-form-mask_title" v-if="item.mask">
								<span v-text="getTreeVal({ item })"></span>
							</div>
							<!-- 会影响搜索结果，先删除了 -->
							<!-- :filterTreeNode="item.filterTreeNode == void 0 ? _filterTreeNode:item.filterTreeNode" -->
						</form-item>

						<!--calc-->
						<form-item
							v-if="item.type === 'calc'"
							:form="form"
							:item="item"
							:size="formConfig.size"
							:fixedLabelWidth="fixedLabelNum"
							:textAlign="formConfig.textAlign"
							:formLayout="formConfig.formLayout"
							:id="'zform-' + item.props"
						>
							<a-input
								v-decorator="[
									item.props,
									{
										rules: getItemRules(item.rules, item),
										validateFirst: true,
									},
								]"
								:size="formConfig.size"
								:placeholder="item.placeholder || ''"
								disabled
							></a-input>
						</form-item>
					</a-col>
				</template>
				<!--btns-->
				<a-col
					id="zform-btns"
					:span="formConfig.rowType ? null : formConfig.btnsCol || 24"
					v-if="
						!this.formConfig.openDrag &&
						formConfig.btns &&
						formConfig.btns.length > 0
					"
					:style="{
						paddingLeft: `${formConfig.space || 12}px`,
						paddingRight: `${formConfig.space || 12}px`,
						...formConfig.btns.style,
					}"
				>
					<div :style="{ textAlign: formConfig.btnsPlacement }">
						<a-form-item
							label
							:wrapper-col="{
								xs: { span: 24, offset: 0 },
								sm: {
									span: 24,
									offset: formConfig.btnsOffset || 0,
								},
							}"
						>
							<template v-for="(btn, index) in formConfig.btns">
								<a-button
									:key="index"
									:type="btn.styleType || null"
									@click="
										(e) => {
											submitHandle(btn)
										}
									"
									:style="[
										{
											width:
												btn.type === 'save' ||
												btn.type === 'reset'
													? '60px'
													: btn.width || 'auto',
											padding: 0,
										},
										btn.type === 'save' ||
										btn.type === 'reset'
											? { marginRight: '8px' }
											: btnMargin,
									]"
									:disabled="btn.isDisabled"
									:size="formConfig.size"
									:id="btn.id || btn.type"
									:loading="btn.loading"
									>{{ btn.name }}</a-button
								>
							</template>
						</a-form-item>
					</div>
				</a-col>
			</a-row>
		</a-form>
	</div>
</template>

<script>
import formItem from './formItem'
import customLabel from './customLabel'
import axios from 'axios'
import UpladFile from './utils/uploadToAliOss.js'
import zinput from './components/zinput.vue'
import zswitch from './components/zswitch.vue'
import zinputNumber from './components/zinputNumber.vue'
import zinputSearch from './components/zinputSearch.vue'
import zselect from './components/zselect.vue'
import zcascader from './components/zcascader.vue'
import zdatepicker from './components/zdatepicker.vue'
import todoList from './components/todoList.vue'
import tableList from './components/tableList.vue'
import zgroup from './components/zgroup.vue'
import ztextArea from './components/ztextArea.vue'
import zcheckbox from './components/zcheckbox.vue'
import zcheckboxGroup from './components/zcheckboxGroup.vue'
import zradioGroup from './components/zradioGroup.vue'
import ztimepicker from './components/ztimepicker.vue'
import zupload from './components/zupload.vue'
import zossUpload from './components/zossUpload.vue'
import zdivider from './components/zdivider.vue'
import zempty from './components/zempty.vue'
import zbutton from './components/zbutton.vue'
import zbuttons from './components/zbuttons.vue'
import ztag from './components/ztag.vue'
import ParsingRules from './utils/customerRules'
var cloneDeep = require('lodash.clonedeep')

let idMap = {}
export default {
	name: 'zform',
	components: {
		formItem,
		customLabel,
		zinput,
		zswitch,
		zinputNumber,
		zinputSearch,
		zselect,
		zcascader,
		zdatepicker,
		todoList,
		tableList,
		zgroup,
		ztextArea,
		zcheckbox,
		zcheckboxGroup,
		zradioGroup,
		ztimepicker,
		zupload,
		zossUpload,
		zdivider,
		zempty,
		zbutton,
		zbuttons,
		ztag,
	},
	inheritAttrs: false,
	props: {
		formConfig: {
			type: Object,
		},
		formData: {
			type: Object,
			default() {
				return {}
			},
		},
	},
	data() {
		return {
			formLayout: 'horizontal',
			currentKey: '',
			loading: '',
			newFormData: {},
		}
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
	},
	created() {
		this.type2UUID = {}
		// for (let item of this.formConfig.columns) {
		// 	if (item.defaultValue !== undefined) {
		// 		this.formData[item.props] = item.defaultValue
		// 	}
		// 	if (this.formData[item.props] === undefined) {
		// 		continue
		// 	}
		// 	item.defaultValue = this.formData[item.props]
		// }
	},
	mounted() {
		this._initFormValue(this.formConfig.columns)
		// setTimeout(() => {
		// 	this.$nextTick(() => {
		// 		this.form.setFieldsValue(this.formData)
		// 	})
		// }, 0)
		this.$nextTick(() => {
			this.$emit('render')
		})
	},
	computed: {
		getLabelCol() {
			let labelCol = this.formConfig.labelCol
			if (labelCol && Object.keys(labelCol).length > 0) {
				return labelCol
			}
			return void 0
		},
		getWrapperCol() {
			let wrapperCol = this.formConfig.wrapperCol
			if (wrapperCol && Object.keys(wrapperCol).length > 0) {
				return wrapperCol
			}
			return void 0
		},
		fixedLabelNum(item) {
			/*return (this.formConfig.fixedLabelWidth && typeof this.formConfig.fixedLabelWidth === 'string') ? this
                    .formConfig.fixedLabelWidth : null*/
			return this.formConfig.fixedLabelWidth || '120px'
		},
		// 根据‘left’、‘center’、‘right’不同的显示位置，来计算按钮之间的间距
		btnMargin() {
			let style = {}
			let { btnsPlacement } = this.formConfig
			switch (btnsPlacement) {
				case 'center':
					style.margin = '0 10px'
					break
				case 'left':
					style.marginRight = '20px'
					break
				case 'right':
					style.marginLeft = '20px'
					break

				default:
					style.margin = '0 10px'
					break
			}
			return style
		},
		formSizeClass() {
			let { size } = this.formConfig || {}
			if (size) {
				return 'zen-form--' + size
			}
		},
	},
	watch: {
		'$store.state.lang': function (val) {
			propsDict.changeLang(val)
			// this.$nextTick(() => {
			// 	location.reload()
			// })
		},
		'formConfig.columns': {
			immediate: true,
			handler(value) {
				if (value && value.length) {
					let temp = value.find((item) => item.treeData)
					if (temp && temp.treeData.length) {
						this.copyTreeData = JSON.parse(
							JSON.stringify(temp.treeData)
						)
					}
					temp = null
				}
			},
		},
		// formData: {
		// 	handler(newValue, oldValue) {
		// 		this.type2UUID = {}
		// 		this.$nextTick(() => {
		// 			this.form.setFieldsValue(newValue)
		// 			this.mapColumnsTypeOfFiled()
		// 			this.$nextTick(() => {
		// 				this.form.setFieldsValue(newValue)
		// 			})
		// 		})
		// 	},
		// 	deep: true
		// }
	},
	beforeDestroy() {
		this.$off('render')
	},
	methods: {
		// 3.13 by Jonny.shao 搜索显示全部子级跟父级
		_filterTreeNode(inputValue, node) {
			let {
				data: { props },
			} = node
			if (this.copyTreeData) {
				return this._findChildrenNode(
					this.copyTreeData,
					inputValue,
					props
				)
			}
			return true
		},
		_findChildrenNode(data, value, treeNode) {
			let stack = [],
				currentNode
			for (let j = 0; j < data.length; j++) {
				stack.push(data[j])
			}
			// 父子层级确认
			const isFather = (item, node) => {
				return node.id === item.parentId || item.id === node.parentId
			}
			while (stack.length) {
				let item = stack.shift()
				if (
					item.title.toUpperCase().indexOf(value.toUpperCase()) != -1
				) {
					currentNode = item
					if (isFather(item, treeNode)) {
						return true
					} else {
						break
					}
				}
				let children = item.children || []
				for (let i = 0; i < children.length; i++) {
					stack.push(children[i])
				}
			}
			const isChild = (children, node) => {
				return children.some((child) => {
					if (child.id === node.id) return true
					if (child.children) return isChild(child.children, node)
				})
			}
			if (currentNode) {
				// 当前搜索的有可能是最子级
				if (!currentNode.children) return currentNode.id === treeNode.id
				return isChild(currentNode.children, treeNode)
			}
			currentNode = null
			return null
		},
		getTreeVal({ item }) {
			const value = this.form.getFieldValue(item.props)
			let valueName = ''
			if (value) {
				let findKey = (column) => {
					if (Array.isArray(column)) {
						column.forEach((ele) => {
							if (ele.key === value) {
								valueName = ele.title
							} else if (ele.children) {
								findKey(ele.children)
							}
						})
					}
				}
				findKey(item.treeData)
			}
			return value ? `${item.maskTitle}: ${valueName}` : item.maskTitle
		},
		getItemRules(rules, item) {
			if (!rules || rules.length === 0 || item.isHidden) {
				return []
			}
			if (item && item.isDisabled === true) {
				return []
			}
			let cloneRules = cloneDeep(rules)
			let findedRequired = cloneRules.find(
				(cloneItem) => cloneItem.required === true
			)
			if (findedRequired) {
				findedRequired.message = '该字段不能为空'
				if (item && item.type === 'input') {
					findedRequired.whitespace = true
				}
				if (item && item.type === 'textarea') {
					findedRequired.whitespace = true
				}
			}
			if (findedRequired && item && item.type == 'group') {
				// 需要先查找原始的validator
				// 然后再替换clone的validator，否则会引起死循环
				let originalFinded = rules.find(
					(originalItem) => originalItem.validator !== undefined
				)
				let finded = cloneRules.find(
					(cloneItem) => cloneItem.validator !== undefined
				)
				if (originalFinded) {
					finded.validator = (rule, value, callback) => {
						this.handleCheckGroup(
							item.subColumns,
							rule,
							value,
							callback,
							originalFinded.validator
						)
					}
				} else {
					cloneRules.push({
						validator: (rule, value, callback) => {
							this.handleCheckGroup(
								item.subColumns,
								rule,
								value,
								callback
							)
						},
					})
				}
			}
			return cloneRules
		},
		handleCheckGroup(subColumns, rule, value, callback, original) {
			if (this.isEmpty(value)) {
				callback('该字段不能为空')
			}
			let res = true
			for (const key in value) {
				let subItem = subColumns.find((item) => item.props === key)
				if (!subItem.rules || subItem.rules.length === 0) {
					continue
				}
				let subItemRequire = subItem.rules.find(
					(item) => item.required === true
				)
				if (!subItemRequire) {
					continue
				}
				if (value.hasOwnProperty(key)) {
					const element = value[key]
					if (this.isEmpty(element)) {
						res = false
					}
				}
			}
			if (!res) {
				callback('该字段不能为空')
			}
			if (original) {
				original(rule, value, callback)
			} else {
				callback()
			}
		},
		isEmpty(obj) {
			//检验null和undefined
			if (!obj && obj !== 0) {
				return true
			}
			//检验数组
			if (Array.prototype.isPrototypeOf(obj) && obj.length === 0) {
				return true
			}
			//检验对象
			if (
				Object.prototype.isPrototypeOf(obj) &&
				Object.keys(obj).length === 0
			) {
				return true
			}
			return false
		},
		getUploadErrorMsg() {
			return 'Please wait for the file to finish uploading'
			// return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
			// 	? '请等待文件上传完毕'
			// 	: 'Please wait for the file to finish uploading'
		},
		handleValidatorOssUpload(rule, value, callback, item) {
			if (!value || value.length === 0) {
				callback()
			}
			if (item.uploading) {
				callback(new Error('illegal'))
			}
			callback()
		},
		getFieldValueTest(props) {
			return this.form.getFieldValue(props)
		},
		remove(k, props) {
			const { form } = this
			const keys = this.getFieldValueTest(props)
			if (keys.length === 1) {
				return
			}
			let fieldsValue = {}
			fieldsValue[props] = keys.filter((key) => key !== k)
			this.$nextTick(() => {
				form.setFieldsValue(fieldsValue)
			})
		},
		add(props) {
			const { form } = this
			const keys = this.getFieldValueTest(props)
			idMap[props] = idMap[props] ? idMap[props] : 0
			const nextKeys = keys.concat(++idMap[props])
			let fieldsValue = {}
			fieldsValue[props] = nextKeys
			this.$nextTick(() => {
				form.setFieldsValue(fieldsValue)
			})
		},
		subColumnCallback(value, column, subColumn, fn) {
			let { form } = this
			subColumn[fn]({
				value,
				form,
				column,
				subColumn,
			})
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column,
			})
		},
		selectFilterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			)
		},
		cascaderFilter(inputValue, path) {
			return path.some(
				(option) =>
					option.label
						.toLowerCase()
						.indexOf(inputValue.toLowerCase()) > -1
			)
		},
		clickHandle(e, column, subColumn) {
			let value = e.target.value
			if (subColumn && subColumn.clickHandle) {
				this.subColumnCallback(value, column, subColumn, 'clickHandle')
				return
			}
			if (column.clickHandle) {
				this.columnCallback(value, column, 'clickHandle')
			}
		},
		clickTask(index, item, column, step, path) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				const task = {
					by_select: 'xpath',
					by_val:
						path === false
							? `//*[@id="${column.props}"]`
							: `//*[@id="${column.props}"]/${path || ''}label[${
									index + 1
							  }]`,
					param: item.label || item.title,
					type: 'click',
					time: '0',
					step: step,
				}
				this.$utt.addTask(task)
			}
		},
		changeHandle(e, column, subColumn) {
			let value = e.target.value
			// 判断是否为计算属性
			if (column.calc) {
				let filter1 = column.calc.match(/[a-z]+[0-9]+/g) || []
				let filter2 = column.calc.match(/[a-z]+/g) || []
				let fileds = [...filter1, ...filter2]
				fileds = Array.from(new Set(fileds))
				let filedsValue = this.form.getFieldsValue(fileds)
				let fn = new Function(fileds.join(','), 'return ' + column.calc)
				let paramsValue = fileds.map((item) => {
					if (item === column.props) {
						return parseFloat(value)
					} else {
						return filedsValue[item]
							? parseFloat(filedsValue[item])
							: 0
					}
				})
				let total = fn.apply(null, paramsValue)
				this.$nextTick(() => {
					this.form.setFieldsValue({ [column.bindProps]: total })
				})
			}
			if (subColumn && subColumn.changeHandle) {
				this.subColumnCallback(value, column, subColumn, 'changeHandle')
				return
			}
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
		},
		blurHandle(e, column) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = `${column.props}`
				task.param = `${e.target.value}`
				task.type = 'sendkey'
				task.time = '2'
				task.step = `修改input值-${column.label}`
				this.$utt.addTask(task)
			}
		},

		changeHandleByValue(val, column, subColumn) {
			this._change({ value, column })
			let value = val || ''
			if (subColumn && subColumn.changeHandle) {
				this.subColumnCallback(value, column, subColumn, 'changeHandle')
				return
			}
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
		},
		filterHiddenColumns() {
			let props = []
			this.formConfig.columns.forEach((item) => {
				if (!item.isHidden) {
					props.push(item.props)
				}
			})
			return props
		},
		asyncValidateFieldsAndScroll(form) {
			return new Promise((resolve, reject) => {
				form.validateFieldsAndScroll(
					this.filterHiddenColumns(),
					{ force: false, first: false },
					(err, values) => {
						if (err) {
							return reject(err)
						}
						return resolve(values)
					}
				)
			})
		},
		zformValidateFieldsAndScroll() {
			return new Promise(async (resolve, reject) => {
				let errorRes = []
				let formRes = {}
				try {
					formRes = await this.asyncValidateFieldsAndScroll(this.form)
				} catch (error) {
					errorRes.push(error)
				}
				try {
					let { columns } = this.formConfig
					await columns.forEach(async (element) => {
						if (
							element.type === 'group' ||
							element.type === 'todo-list' ||
							element.type === 'table-list'
						) {
							try {
								await this.asyncValidateFieldsAndScroll(
									this.$refs['zform-' + element.props][0].form
								)
							} catch (error) {
								errorRes.push(error)
							}
						}
					})
				} catch (error) {
					errorRes.push(error)
				}

				if (errorRes && errorRes.length > 0) {
					return reject(errorRes)
				}

				return resolve(formRes)
			})
		},

		/**
		 * @description: 只获取表单的值 不进行校验
		 * @param {type}
		 * @return:
		 */
		asyncGetFieldsValue(form) {
			return new Promise((resolve, reject) => {
				try {
					let res = form.getFieldsValue()
					return resolve(res)
				} catch (error) {
					return resolve(error)
				}
			})
		},
		/**
		 * @description: 只获取表单的值 不进行校验
		 * @param {type}
		 * @return:
		 */
		zformGetFieldsValue() {
			return new Promise(async (resolve, reject) => {
				try {
					let res = await this.asyncGetFieldsValue(this.form)
					let { columns } = this.formConfig
					columns.forEach(async (element) => {
						if (
							element.type === 'group' ||
							element.type === 'todo-list' ||
							element.type === 'table-list'
						) {
							try {
								await this.asyncGetFieldsValue(
									this.$refs['zform-' + element.props][0].form
								)
							} catch (error) {
								return reject(error)
							}
						}
					})
					return resolve(res)
				} catch (error) {
					return reject(error)
				}
			})
		},
		async submitHandle(btn) {
			let { form } = this
			let { columns } = this.formConfig
			// 是否禁用默认行为
			if (btn.disableDefaultHandle || btn.isDisabled) {
				return
			}
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = btn.id || btn.type
				task.param = ''
				task.type = 'click'
				task.time = '2'
				task.step = ''
				this.$utt.addTask(task)
			}
			if (btn.type === 'reset') {
				this.resetFields(columns)
			}
			if (btn.type === 'save') {
				await this.zformValidateFieldsAndScroll()
			}
			if (btn.loading === false) {
				btn.loading = true
			}
			if (btn.clickHandle) {
				btn.clickHandle({ form, btn })
			}
		},
		mapColumnsTypeOfFiled() {
			let cloumns = [...this.formConfig.columns]
			cloumns = cloumns.map((item) => {
				if (item.inDict) {
					item = propsDict.getProps(item, 'form')
				}
				return item
			})
			this.formConfig.columns = cloumns
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},

		uploadRemoveHandle(file, item) {
			let props = item.props
			let values = this.form.getFieldsValue([item.props])
			let newValues = values[item.props].filter((item1) => {
				return file.uid !== item1.uid
			})
			let obj = {}
			obj[item.props] = newValues
			this.$nextTick(() => {
				this.form.setFieldsValue(obj)
			})
			return false
		},
		onSuccess(props, data) {
			let values = this.form.getFieldsValue([props])
			let len = values[props].length
			values[props][len - 1].status = 'success'
			values[props][len - 1].filename = data.filename
			this.$message.success(this.$locale.fileUploaded)
		},
		onError(props, errorMsg) {
			let msg = errorMsg || this.$locale.uploadFailed
			let values = this.form.getFieldsValue([props])
			let len = values[props].length
			values[props][len - 1].status = 'error'
			this.$message.error(msg)
		},
		uploadCustomRequest(obj, item) {
			let _this = this
			var form = new FormData()
			form.append('file', obj.file) // 文件对象
			var xhr = new XMLHttpRequest()
			xhr.open('post', obj.action, true)
			xhr.onload = function (res) {
				//console.log(res)
			}
			xhr.send(form)
			xhr.onreadystatechange = function () {
				if (xhr.readyState == 4) {
					let props = item.props
					var res = JSON.parse(xhr.responseText)
					if (res.success) {
						_this.onSuccess(props, res.data)
					} else {
						_this.onError(props, res.msg)
					}
				}
			}
			xhr.ontimeout = function () {
				_this.onError(props)
			}
		},
		// ossUploadCustomRequest(obj, item) {
		// 	obj.onProgress()
		// 	UpladFile(obj.file, item.uploadDir, item.upload.uploadBasePath)
		// 		.then(res => {
		// 			res = JSON.parse(res)
		// 			let values = this.form.getFieldsValue([item.props])
		// 			let uploadedFile = values[item.props].find(item => {
		// 				return item.uid === obj.file.uid
		// 			})
		// 			uploadedFile.url = res.url
		// 			obj.onSuccess(res)
		// 			this.$message.success(this.$locale.fileUploaded)
		// 			return res
		// 		})
		// 		.catch(err => {
		// 			obj.onError(err)
		// 			return err
		// 		})
		// },
		treeSelectClickHandle(item) {
			let column = item
			if (item.asyncData) {
				item.asyncData({ column })
			}
		},
		setIdTofileds(item) {
			return 'zform-' + item.props
		},
		_initFormValue(columns) {
			this.mapColumnsTypeOfFiled()
			this.resetFields(columns)
		},
		updateFormConfig() {
			this.mapColumnsTypeOfFiled()
		},
		checkChildProps() {
			// 查看是否有关联关系
			for (const iterator of this.formConfig.columns) {
				if (
					!iterator.childProps ||
					Object.keys(iterator.childProps).length === 0
				) {
					continue
				}
				for (const key in iterator.childProps) {
					let finded = this.formConfig.columns.find(
						(item) => item.props === key
					)
					if (!finded) {
						continue
					}
					let value = this.form.getFieldValue(iterator.props)
					finded.options = []
					if (value) {
						//finded.isDisabled = false
						finded.fetchParams[iterator.childProps[key]] = value.key
					} else {
						//finded.isDisabled = true
						finded.fetchParams[iterator.childProps[key]] = 0
					}
				}
			}
		},
		isBoolean(obj) {
			let toString = Object.prototype.toString
			return (
				obj === true ||
				obj === false ||
				toString.call(obj) === '[object Boolean]'
			)
		},
		/**
		 * 更新表单值
		 *
		 * @param {object} formData 需要更新的值
		 * @param {string|array} groupProps 需要更新的group或者todo-list
		 */
		updateForm(formData, groupProps) {
			let parsingRulesFn = new ParsingRules(
				this.formConfig,
				formData,
				this
			)
			let newColumns = parsingRulesFn.validationRules()
			this.formConfig.columns = newColumns

			this.formConfig.columns.forEach((item) => {
				if (item.type === 'group') {
					let newFormData = {}
					let groupFormData = formData[item.props] || {}
					if (
						!groupFormData ||
						Object.keys(groupFormData).length <= 0
					) {
						return
					}
					item.subColumns.forEach((subColumn) => {
						if (
							groupFormData[subColumn.props] !== undefined &&
							groupFormData[subColumn.props] !== null
						) {
							newFormData[subColumn.props] =
								groupFormData[subColumn.props]
						}
					})
					if (!newFormData || Object.keys(newFormData).length <= 0) {
						return
					}
					formData[item.props] =
						JSON.parse(JSON.stringify(newFormData)) || {}
				}
			})

			this.newFormData = formData
			// groupProps 可以为数组，也可以为字符串
			this.$nextTick(() => {
				this.form.setFieldsValue(formData)
				if (this.isBoolean(groupProps) && groupProps === true) {
					this.formConfig.columns.forEach((item) => {
						if (
							(item.type === 'group' ||
								item.type === 'todo-list' ||
								item.type === 'table-list') &&
							formData[item.props] !== undefined
						) {
							this.$refs[`zform-${item.props}`][0].updateForm(
								formData[item.props]
							)
						}
					})
					return
				}

				if (!groupProps) {
					this.checkChildProps()
					return
				}
				if (Array.isArray(groupProps)) {
					groupProps.map((item) => {
						this.$refs[`zform-${item}`][0].updateForm(
							formData[item]
						)
					})
				}
				if (typeof groupProps === 'string') {
					this.$refs[`zform-${groupProps}`][0].updateForm(
						formData[groupProps]
					)
				}
				this.checkChildProps()
			})
		},
		/**
		 * 获取默认的表单值
		 * 如果`column` 中存在defaultValue，直接重置到defaultValue，如果不存在则直接显示不同组件的默认值
		 * todo-list组件的默认值以对象的形式写在父column上
		 *
		 * @param {array} columns
		 */
		getDefaultFormValue(column) {
			let { type } = column
			if (
				type === 'radioGroup' &&
				column.hasOwnProperty('defaultValue')
			) {
				return column.defaultValue
			}
			if (
				column.defaultValue ||
				column.defaultValue === 0 ||
				column.defaultValue === ''
			) {
				return column.defaultValue
			}
			if (type === 'input-number' || type === 'input') {
				return ''
			} else if (type === 'switch') {
				return false
			} else if (
				type === 'checkboxGroup' ||
				type === 'cascader' ||
				type === 'oss-upload'
			) {
				return []
			} else if (type === 'select' && column.mode === 'multiple') {
				return []
			} else {
				return void 0
			}
		},
		/**
		 * 重置表单方法
		 *
		 * @param {array} columns
		 */
		resetFields(columns) {
			columns = columns || this.formConfig.columns
			if (!columns || !Array.isArray(columns)) {
				return
			}
			let formData = {}
			let setDefaultValues = (arr, formData) => {
				arr.map((item) => {
					if (item.type === 'group') {
						formData[item.props] = {}
						setDefaultValues(item.subColumns, formData[item.props])
					} else if (
						item.type === 'todo-list' ||
						item.type === 'table-list'
					) {
						formData[item.props] = []
						if (item.defaultValue && item.defaultValue.length > 0) {
							formData[item.props] = item.defaultValue
						}
					} else {
						if (item.type === 'select') {
							// 添加reset属性，控制后期异步请求接口的回显
							item.reset = true
						}
						formData[item.props] = this.getDefaultFormValue(item)
					}
				})
			}
			setDefaultValues(columns, formData)
			this.updateForm(formData, true)
		},
		/**
		 * 通过props过滤columns中的选项
		 *
		 * @param {array} props -需要过滤掉的props集合
		 */
		filteredColumnsByProps(props) {
			if (!props || !Array.isArray(props)) {
				return
			}
			let { columns } = this.formConfig
			let newColumns = []
			columns.map((column) => {
				let findTarget = props.filter((props) => {
					return props === column.props
				})
				if (findTarget.length === 0) {
					newColumns.push(column)
				}
			})
			this.formConfig.columns = newColumns
		},
		/**
		 * 通过props过滤subColumns中的选项
		 *
		 * @param {strint} groupProp - group组件或者todoList组件中的props
		 * @param {array} props -需要过滤掉的props集合
		 */
		filteredSubColumnsByProps(groupProp, props) {
			let { columns } = this.formConfig

			let groupColumn = columns.filter((column) => {
				return column.props === groupProp
			})
			let newSubColumns = []
			groupColumn[0].subColumns.map((column) => {
				let findTarget = props.filter((props) => {
					return props === column.props
				})
				if (findTarget.length === 0) {
					newSubColumns.push(column)
				}
			})

			groupColumn[0].subColumns = newSubColumns
		},
		closeDisabledColumns(props, deep){
			let columns = cloneDeep(this.formConfig.columns)
			let recursiveHandle = (columns) => {
				columns.map((item) => {
					if (props !== 'all') {
						if (item.props === props) {
							item.isDisabled = false
						}
						return item
					}
					if (
						deep &&
						(item.type === 'todo-list' ||
							item.type === 'group' ||
							item.type === 'table-list')
					) {
						item.isDisabled = false
						recursiveHandle(item.subColumns)
					} else {
						item.isDisabled = false
					}
				})
			}
			recursiveHandle(columns)
			this.formConfig.columns = columns
		},
		disabledColumns(props, deep) {
			let columns = cloneDeep(this.formConfig.columns)
			let recursiveHandle = (columns) => {
				columns.map((item) => {
					if (props !== 'all') {
						if (item.props === props) {
							item.isDisabled = true
						}
						return item
					}
					if (
						deep &&
						(item.type === 'todo-list' ||
							item.type === 'group' ||
							item.type === 'table-list')
					) {
						item.isDisabled = true
						recursiveHandle(item.subColumns)
					} else {
						item.isDisabled = true
					}
				})
			}
			recursiveHandle(columns)
			this.formConfig.columns = columns
		},
		async _change({ value, column }) {
			let { formConfig, name } = this
			let fromValue = await this.zformGetFieldsValue()
			let parsingRulesFn = new ParsingRules(
				cloneDeep(formConfig),
				cloneDeep(fromValue),
				this
			)
			let newColumns = parsingRulesFn.validationRules()

			this.$set(this.formConfig, 'columns', cloneDeep(newColumns))
			parsingRulesFn = null
			this.$forceUpdate()

			let form = this
			if (this.$attrs.change) {
				this.$attrs.change({ value, column, formConfig, name, form })
			}
			this.$emit('change', { value, column, formConfig, name, form })
			// 触发moduleType为flow组件中的formConfigChange方法
			let findComponents = function (component) {
				if (component.$options.moduleType === 'flow') {
					if (component.formConfigChange) {
						component.formConfigChange({
							value,
							column,
							formConfig,
							name,
							form,
						})
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		_action({ action, column }) {
			let { formConfig, name } = this
			let form = this
			if (this.$attrs.action) {
				this.$attrs.action({ action, column, formConfig, name, form })
			}
		},
	},
}
</script>

<style lang=less>
@import './style/index.less';
</style>
