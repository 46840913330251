import EditCell from "../editCell/index";
import generalEvents from '../utils/generalEvents';
import { isArray } from '../utils/index';
import { getFields } from '../../utils/componentHelpers';
var cloneDeep = require('lodash.clonedeep')

import { join } from "lodash";

const prefixCls = 'zdetail'
const zform2detail = {
    input: 'text',
    cascader: 'text',
    checkbox: 'text',
    checkboxGroup: 'text',
    datepicker: 'time',
    timepicker: 'time',
    'input-number': 'number',
    'oss-upload': 'link',
    radio: 'text',
    radioGroup: 'text',
    select: 'text',
    ztag: 'tag',
    textarea: 'text',
    'input-search': 'text',
}


const RenderColumns = {
    props: {
        column: {
            type: Object
        },
        express: Array,
        _value: {},
        dataSource: [Array, Object]
    },
    data() {
        return {
            items: [],
            popoverRenderedContentFn: null
        }
    },
    inject: ['emitHandel'],
    provide() {
        return {
            generalEvents: generalEvents(this)
        }
    },
    mounted() {
        const { column: { popoverContent } } = this.$props
        const { renderPopover, items } = this

        if (popoverContent) {
            renderPopover(popoverContent)
        }
    },
    methods: {
        parsingExpress(value, props) {
            let { express } = this.$props
            if (!isArray(express)) {
                return {}
            }
            let filterExpress = express.filter(item => {
                return item.rule && item.props === props
            })
            let res = {}
            filterExpress.forEach(item => {
                let expressionFn = new Function('props', `return ${item.rule}`);
                let expressionRes = expressionFn(value)
                if (!expressionRes) {
                    return
                }
                // delete item.props
                // delete item.rule
                res = Object.assign(res, item)
            })
            return res
        },
        formatedValue({ isSub, column }) {
            let { column: propsColumn, dataSource } = this.$props
            const { parsingExpress } = this
            let { props } = column
            if (!props) {
                return ''
            }

            let { props: rootProps, mapping } = propsColumn
            if (isSub && rootProps) {
                dataSource = cloneDeep(dataSource[rootProps])
            }
            let _value =
                props.split('.').reduce((dataSource, val) => {
                    return isArray(dataSource) ? getFields(dataSource, val) : dataSource[val]
                }, dataSource)

            Object.assign(column, parsingExpress(_value, props))


            if (mapping && Object.keys(mapping).length > 0) {
                mapping.forEach(item => {
                    if (_value == item.value) {
                        _value = item.text || item.label || ''
                    }
                })
            }
            return _value
        },
        renderColumn(column, isSub) {
            const { formatedValue, dataSource } = this
            let { type, splitFlagChar = ",", renderContent } = column

            let _value = formatedValue({ isSub, column })
            if (isArray(_value) && splitFlagChar && type !== 'time' && type !== 'number') {
                _value = _value.join(splitFlagChar)
            }

            if (renderContent) {
                return renderContent({ value: _value, dataSource })
            }

            let formateType = zform2detail[type] ? zform2detail[type] : type


            const componentProps = {
                props: { ...column, _value, column }
            }
            const ComponentModule = require(`./${formateType}`) || require(`./text`)
            const Component = ComponentModule.default
            return <Component {...componentProps}></Component>
        },
        renderColumnChildren() {

        },
        formatedColumns(columns) {

        },
        renderPopover(popoverContent) {
            this.popoverRenderedContentFn = null
            let { dataSource } = this.$props
            let res = popoverContent(dataSource)
            let fnType = Object.prototype.toString.call(res)

            if (fnType === '[object Promise]') {
                return res.then(promiseRes => {
                    this.createPopoverRenderedContentFn(promiseRes)
                })
            }
            this.createPopoverRenderedContentFn(res)
        },
        createPopoverRenderedContentFn(userProps) {
            const props = {
                props: userProps
            }
            this.popoverRenderedContentFn = (vNodes) => {
                return (
                    <a-popover {...props}>
                        <span>{vNodes}</span>
                    </a-popover>
                )
            }
        },
        renderLabel(label) {
            const className = {
                [`${prefixCls}-item-label`]: true,
            }
            return <div class={className}>
                {label}
            </div>
        },
        renderEditCell(items) {
            const { column: { props, editOptions, editMode, editIcon, expression, modalOptions } } = this.$props
            const { formatedValue } = this
            let preEditOptions = isArray(editOptions) ? editOptions : [{ ...editOptions }]
            let _value = {}
            preEditOptions.forEach(item => {
                _value[item.props] = formatedValue({ isSub: true, column: item })
            })

            const editCellProps = {
                props: {
                    editOptions: preEditOptions,
                    defaultValue: _value,
                    editMode: editMode,
                    editIcon: editIcon,
                    columnProps: props,
                    expression,
                    modalOptions
                }
            }
            return <EditCell {...editCellProps}>{items}</EditCell>
        },
        renderItem() {
            const { column, column: { type, subColumns, label, editOptions, lineClamp, width } } = this.$props
            const { renderColumn, renderLabel, popoverRenderedContentFn, renderEditCell } = this
            let items = []
            if (type === 'group' && subColumns && subColumns.length > 0) {
                items = subColumns.map(subColumn => {
                    return renderColumn(subColumn, true)
                })
            } else {
                items.push(renderColumn(column))
            }

            return (
                <div style="display: flex;" class={prefixCls}>
                    {/* {label ? renderLabel(label) : ''} */}
                    <div
                        class={[
                            `${prefixCls}-item-content`,
                            lineClamp > 0 ? `${prefixCls}-item-content-wrap` : ''
                        ]}
                        style={{ '-webkit-line-clamp': lineClamp || 2, width }}>
                        {editOptions
                            ? renderEditCell(items)
                            : popoverRenderedContentFn ? popoverRenderedContentFn(items) : items
                        }
                    </div>
                </div>
            )
        }
    },
    render() {
        const { renderItem } = this
        return renderItem()
    }
}

export default RenderColumns