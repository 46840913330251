<template>
	<div class="u-fotter">
		<img src="../../assets/images/user_sbw.png" alt />
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	methods: {},
}
</script>
<style lang="scss">
.u-fotter {
	height: 150px;
	background: #f1f4fa;
	img {
		height: 100%;
		width: 100%;
	}
}
</style>