<template>
	<div>
		<a-time-picker
			:size="item.size"
			:disabled="item.isDisabled"
			:placeholder="item.placeholder"
			:format="item.format || 'HH:mm:ss'"
			:allowEmpty="item.allowEmpty"
			:clearText="item.clearText"
			:defaultOpenValue="
                moment(value, item.format || 'HH:mm:ss')
            "
			:disabledHours="
                () => {
                    return item.disabledHours || [];
                }
            "
			:disabledMinutes="
                () => {
                    return item.disabledMinutes || [];
                }
            "
			:disabledSeconds="
                () => {
                    return item.disabledSeconds || [];
                }
            "
			:hideDisabledOptions="item.hideDisabledOptions"
			:hourStep="item.hourStep"
			:minuteStep="item.minuteStep"
			:secondStep="item.secondStep"
			@change="(time, timeString) => changeHandle(time, timeString, item)"
			v-model="timeVal"
		>
			<a-button
				v-if="item.addon"
				slot="addon"
				size="small"
				type="primary"
				@click="addonClickHandle(getFieldValueTest(item.props), item)"
			>{{ item.addon }}</a-button>
			<a-icon v-if="item.suffixIcon" slot="suffixIcon" :type="item.suffixIcon" />
		</a-time-picker>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	name: '',
	data() {
		return {
			timeVal: this.value
				? moment(this.value, this.item.format || 'HH:mm:ss')
				: null
		}
	},
	methods: {
		moment,
		subColumnCallback(value, column, subColumn, fn) {
			let { form } = this
			subColumn[fn]({
				value,
				form,
				column,
				subColumn
			})
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		getFieldValueTest(props) {
			return this.form.getFieldValue(props)
		},
		changeHandle(time, timeString, column) {
			this.$emit('change', timeString)

			if (column.datePickChangeHandle) {
				column.datePickChangeHandle(time, timeString, item)
			}
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', timeString, column, this.rowIndex)
			}
		},
		addonClickHandle(value, column, subColumn) {
			if (subColumn && subColumn.clickHandle) {
				this.subColumnCallback(value, column, subColumn, 'clickHandle')
				return
			}
			if (column.addonClickHandle) {
				this.columnCallback(value, column, 'addonClickHandle')
			}
		}
	},
	watch: {
		value: {
			handler: function(val) {
				this.timeVal = val
					? moment(val, this.item.format || 'HH:mm:ss')
					: null
			},
			immediate: true
		}
	},
	props: {
		value: {
			type: [String, Number, Boolean, Array, Object, Date]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
