<template>
	<div :style="getStyle">
		<div v-if="!item.tags">
			<a-tag :color="item.color">{{item.tagText}}</a-tag>
		</div>
		<div v-if="item.tags">
			<template v-for="(tag,index) in item.tags">
				<a-tag :key="tag" :color="item.colors[index]">{{tag}}</a-tag>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			inputVisible: false,
			inputValue: ''
		}
	},
	methods: {},
	props: {
		item: {
			type: Object
		},
		form: {
			type: Object
		},
		size: {
			type: String
		}
	},
	computed: {
		getStyle() {
			let textAlign = this.item.align || 'left'
			let offset =
				textAlign === 'right'
					? { paddingRight: this.item.offset || 0 }
					: { paddingLeft: this.item.offset || 0 }
			return { textAlign, ...offset }
		}
	}
}
</script>
<style lang="less"></style>
