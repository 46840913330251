/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-09-22 17:52:46
 */
var cloneDeep = require('lodash.clonedeep')

class parsingRules {
    constructor(fromConf, fromValue, root) {
        const { updateForm } = root
        this.fromConf = fromConf
        this.expression = fromConf.expression
        this.fromValue = fromValue
        this.utilityFunctionFn = new utilityFunction(fromConf.columns, updateForm)
        this.utilityFunctionFnMethods = Object.getOwnPropertyNames(Object.getPrototypeOf(this.utilityFunctionFn))
    }

    validationRules() {
        this.expression && this.expression.forEach(item => {
            let expressionFn = new Function('props', `return ${item.rule}`);
            console.log('this.fromValue', this.fromValue);
            let expressionRes = expressionFn(this.fromValue)
            if (!expressionRes) {
                return
            }

            for (const iterator of this.utilityFunctionFnMethods) {
                if (iterator === 'constructor' || !item[iterator] || iterator.length === 0) {
                    continue
                }
                let newColumns = this.utilityFunctionFn[iterator](item[iterator])

                this.utilityFunctionFn.columns = newColumns
            }
        })
        return this.utilityFunctionFn.columns
    }
}

class utilityFunction {
    constructor(columns, updateForm) {
        this._columns = columns
        this.updateForm = updateForm
    }

    set columns(value) {
        this._columns = value
    }

    get columns() {
        return this._columns;
    }

    changePropsHidden(props, isHidden) {
        let _columns = cloneDeep(this._columns)

        props.forEach(propsItem => {
            let splited = propsItem.split('.')
            if (splited.length === 1) {
                let finded = _columns.find(colItem => colItem.props === splited[0])
                finded ? finded.isHidden = isHidden : finded.isHidden = !isHidden
                return
            }
            let finded = _columns.find(colItem => colItem.props === splited[0])
            if (!finded) {
                return
            }
            let subColFinded = finded.subColumns.find(subColItem => subColItem.props === splited[1])
            subColFinded ? subColFinded.isHidden = isHidden : subColFinded.isHidden = !isHidden
        })

        this._columns = _columns
        return this._columns
    }

    hideProps(props) {
        return this.changePropsHidden(props, true)
    }

    showProps(props) {
        return this.changePropsHidden(props, false)
    }

    // updateFormData(data) {
    //     this.updateForm(data, null, )
    //     return this._columns
    // }

    updateFormConfig(formConfig) {
        return this._columns.map(item => {
            let config = formConfig[item.props]
            if (!config) {
                return item
            }
            delete config['props']
            delete config['uid']
            item = formConfig[item.props] ? Object.assign(item, formConfig[item.props]) : item
            return item
        })
    }
};
export default parsingRules;