import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "@/assets/styles/index.scss";
Vue.use(Antd);
import zformComponent from "@/components/zform/index.js";
Vue.use(zformComponent);
import ztableComponent from "@/components/ztable2/index.jsx";
Vue.use(ztableComponent);
import zdetailComponent from "@/components/zdetail2/index.jsx";
Vue.use(zdetailComponent);
import searchForm from "@/components/searchForm/index.js";
Vue.use(searchForm);
import modal from "@/components/modal/index.js";
Vue.use(modal);

// 全局方法挂载
import { download } from "@/utils";
Vue.prototype.$download = download;

const signSetItem = localStorage.setItem
localStorage.setItem = function (key, val) {
  let setEvent = new Event('setItemEvent')
  setEvent.key = key
  setEvent.newValue = val
  window.dispatchEvent(setEvent)
  signSetItem.apply(this, arguments)
}


new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
