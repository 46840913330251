<template>
	<div>
		<a-switch
			v-if="
                !item.checkedChildrenType ||
                    item.checkedChildrenType == 'string'
            "
			:disabled="item.isDisabled || false"
			:checkedChildren="item.checkedChildren || null"
			:unCheckedChildren="item.unCheckedChildren || null"
			:size="item.size||null"
			@click="
                e => {
                    clickSwitchHandle(e, item);
                }
            "
			@change="
                e => {
                    changeSwitchHandle(e, item);
                }
            "
			v-model="switchVal"
		/>
		<a-switch
			v-if="
                item.checkedChildrenType && item.checkedChildrenType == 'icon'
            "
			:disabled="item.isDisabled || false"
			:size="item.size||null"
			@click="
                e => {
                    clickSwitchHandle(e, item);
                }
            "
			@change="
                e => {
                    changeSwitchHandle(e, item);
                }
            "
			v-model="switchVal"
		>
			<a-icon :type="item.checkedChildren" slot="checkedChildren" />
			<a-icon :type="item.unCheckedChildren" slot="unCheckedChildren" />
		</a-switch>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			switchVal: this.value || false
		}
	},
	methods: {
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		changeSwitchHandle(e, column) {
			let value = (column.checked = e)
			this.$emit('change', value)
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}

			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		clickSwitchHandle(e, column) {
			let value = (column.checked = e)
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = `${column.props}`
				task.param = `${value}`
				task.type = 'click'
				task.time = '2'
				task.step = `修改input值-${column.label}`
				this.$utt.addTask(task)
			}
		}
	},
	watch: {
		value: function(val) {
			this.switchVal = val || false
		}
	},
	props: {
		value: {
			type: Boolean
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
