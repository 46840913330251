<template>
	<div style="opacity:0">空态占位符</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	methods: {},
	props: { parentType: { type: String } }
}
</script>
<style lang="less"></style>
