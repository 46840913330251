import moment from 'moment'
import { isArray } from '../utils/index'

const ZTimeComponent = {
    name: 'ZTagComponent',
    props: {
        _value: {
            type: Boolean
        },
        column: {
            type: Object
        }
    },
    inject: ['generalEvents'],
    methods: {

    },
    computed: {
        getChecked() {
            const { _value = undefined } = this.$props
            return { defaultChecked: _value || false }
        }
    },
    render() {
        const { getChecked, column, column: { props }, generalEvents } = this
        const switchProps = {
            props: { ...column, ...getChecked },
            on: {
                change: (checked) => generalEvents({ fnName: 'change', val: checked, props }),
            }
        }
        return (
            <div>
                <a-switch {...switchProps}>
                </a-switch>
            </div>
        )
    }
}

export default ZTimeComponent