/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-01-23 20:05:51
 * 社区智库
 */
import Layout from "@/layout/index.vue";
export default {
  path: "/thinkTank",
  name: "thinkTank",
  component: Layout,
  children: [
    {
      path: "modelText",
      name: "modelText",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 示范文本*/ "@/views/thinkTank/modelText/index.vue"
        ),
    },
    {
      path: "modelText/preview/:fileId",
      name: "modelText-preview",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 示范文本 预览*/ "@/views/thinkTank/modelText/preview.vue"
        ),
    },
    {
      path: "policiesRegulations",
      name: "policiesRegulations",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 政策法规*/ "@/views/thinkTank/policiesRegulations/index.vue"
        ),
    },
    {
      path: "policiesRegulations/preview/:fileId",
      name: "policiesRegulations-preview",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 示范文本 预览*/ "@/views/thinkTank/policiesRegulations/preview.vue"
        ),
    },
    {
      path: "communityClassroom",
      name: "communityClassroom",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 社区课堂*/ "@/views/thinkTank/communityClassroom/index.vue"
        ),
    },
    {
      path: "communityClassroom/preview/:fileId",
      name: "communityClassroom-preview",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 示范文本 预览*/ "@/views/thinkTank/communityClassroom/preview.vue"
        ),
    },
  ],
};
