/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 12:12:00
 */
import request from '@/api/request'
// 用户注册
export function getSignature(data) {
    return request({
        url: '/api/oss-upload',
        method: 'get',
        data
    })
}