<template>
	<div>
		<a-input
			:placeholder="item.placeholder || ''"
			:size="item.size || size"
			:disabled="item.isDisabled"
			:addonBefore="item.addonBefore"
			:defaultValue="item.defaultValue"
			:addonAfter="item.addonAfter"
			@click="
                e => {
                    clickHandle(e, item);
                }
            "
			@change="
                e => {
                    debounceHandle(e, item);
                }
            "
			@blur="
                e => {
                    blurHandle(e, item);
                }
            "
			v-model="inputVal"
		>
            <a-icon :type="item.prefixIcon" slot="prefix" v-if="item.prefixIcon"></a-icon>
			<template v-for="(icon, index3) in item.icons">
				<a-icon
					:key="index3"
					:slot="icon.slot"
					:type="icon.type"
					@click="
                        e => {
                            clickHandle(e, item, icon);
                        }
                    "
				/>
			</template>
		</a-input>
	</div>
</template>
<script>
import debounce from 'lodash/debounce'
const debounceFn = debounce(val => {
	val()
}, 500)
export default {
	name: '',
	data() {
		return {
			inputVal: this.value
		}
	},
	methods: {
		subColumnCallback(value, column, subColumn, fn) {
			let { form } = this
			subColumn[fn]({
				value,
				form,
				column,
				subColumn
			})
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		clickHandle(e, column, subColumn) {
			let value = e.target.value
			if (subColumn && subColumn.clickHandle) {
				this.subColumnCallback(value, column, subColumn, 'clickHandle')
				return
			}
			if (column.clickHandle) {
				this.columnCallback(value, column, 'clickHandle')
			}
		},
		debounceHandle(e, column, subColumn) {
			this.changeHandle(e, column, subColumn)
			// if (column.closeDebounce) {
			// 	this.changeHandle(e, column, subColumn)
			// } else {
			// 	debounceFn(() => {
			// 		this.changeHandle(e, column, subColumn)
			// 	})
			// }
		},
		changeHandle(e, column, subColumn) {
			let value = e.target.value

			this.$emit('change', value)
			// 判断是否为计算属性
			if (column.calcExpr) {
				let filter1 = column.calcExpr.match(/[a-z]+[0-9]+/g) || []
				let filter2 = column.calcExpr.match(/[a-z]+/g) || []
				let fileds = [...filter1, ...filter2]
				fileds = Array.from(new Set(fileds))
				let filedsValue = this.form.getFieldsValue(fileds)
				let fn = new Function(
					fileds.join(','),
					'return ' + column.calcExpr
				)
				let paramsValue = fileds.map(item => {
					if (item === column.props) {
						return parseFloat(value)
					} else {
						return filedsValue[item]
							? parseFloat(filedsValue[item])
							: 0
					}
				})
				let total = fn.apply(null, paramsValue)
				this.$nextTick(() => {
					this.form.setFieldsValue({ [column.calcTarget]: total })
				})
			}

			if (subColumn && subColumn.changeHandle) {
				this.subColumnCallback(value, column, subColumn, 'changeHandle')
				return
			}
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}

			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		blurHandle(e, column) {
			let value = this.formateValue(e.target.value, column)
			this.$emit('change', value)
			this.inputVal = value
			this.$emit('blurHandle', e, column)
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}
		},
		formateValue(value, column) {
			if (column.maximumNumber === 0 || column.maximumNumber) {
				value = parseFloat(value) || 0
				value =
					value > column.maximumNumber ? column.maximumNumber : value
			}

			if (column.minimalNumber === 0 || column.minimalNumber) {
				value = parseFloat(value) || 0

				value =
					value > column.minimalNumber ? value : column.minimalNumber
			}
			value = value + ''
			return value
		}
	},
	// mounted() {
	// 	this.inputVal = this.item.defaultValue
	// },
	watch: {
		value: function(val) {
			this.inputVal = val
		}
	},
	props: {
		value: {
			type: [String, Number]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
