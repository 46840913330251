import Layout from "@/layout/index.vue";
export default {
  path: "/finance",
  name: "finance",
  component: Layout,
  children: [
    {
      path: "repair",
      name: "repair",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/finance/repair/index.vue"
        ),
    },
    {
      path: "property",
      name: "property",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/finance/property/index.vue"
        ),
    },
    {
      path: "office",
      name: "office",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/finance/office/index.vue"
        ),
    },
    {
      path: "audit",
      name: "audit",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/finance/audit/index.vue"
        ),
    },
  ],
};
