/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-01-23 20:05:51
 */
import Layout from "@/layout/index.vue";
export default {
  path: "/community",
  name: "community",
  component: Layout,
  children: [
    {
      path: "ywh",
      name: "ywh",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/ywh/info.vue"
        ),
    },
    {
      path: "floor",
      name: "floor",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/floor/index.vue"
        ),
    },
    {
      path: "tsAgency",
      name: "tsAgency",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/tsAgency/index.vue"
        ),
    },
    {
      path: "tsAgency/members",
      name: "tsAgencyMembers",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/tsAgency/members.vue"
        ),
    },
    {
      path: "property",
      name: "property",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/property/index.vue"
        ),
    },
    {
      path: "officialNews",
      name: "officialNews",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/officialNews/list.vue"
      ),
    },
    {
      path: "ONewsEdit",
      name: "ONewsEdit",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard'*/ "@/views/community/officialNews/edit.vue"
        ),
    },
  ],
};
