import moment from 'moment'
import { isArray } from '../utils/index'

const ZTimeComponent = {
    name: 'ZLinkComponent',
    props: {
        _value: {
            type: String
        },
        column: {
            type: Object
        }
    },
    inject: ['noneValue', 'generalEvents'],
    methods: {

    },
    computed: {
        getText() {
            const { _value = undefined, column: { props } } = this.$props
            const { noneValue, generalEvents } = this
            if (!_value && _value !== 0) {
                return noneValue
            }
            const aProps = {
                on: {
                    click: () => generalEvents({ fnName: 'click', val: _value, props }),
                },
            }

            return <a  {...aProps} domPropsInnerHTML={_value}></a>
        }
    },
    render() {
        const { getText } = this
        return (
            <div >
                {getText}
            </div>
        )
    }
}

export default ZTimeComponent