/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-01-23 20:05:51
 * 社区智库
 */
import Layout from "@/layout/index.vue";
export default {
  path: "/fileManagement",
  name: "fileManagement",
  component: Layout,
  children: [
    {
      path: "static",
      name: "static",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 静态档案管理*/ "@/views/fileManagement/static/index.vue"
        ),
    },
    {
      path: "dynamic",
      name: "dynamic",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 动态档案管理*/ "@/views/fileManagement/dynamic/index.vue"
        ),
    },
    {
      path: "dynamic/preview/:projectId",
      name: "dynamic-preview",
      meta: { keepAlive: true },
      component: () =>
        import(
          /* 动态档案管理 文件页*/ "@/views/fileManagement/dynamic/preview.vue"
        ),
    },
  ],
};
