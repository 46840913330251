<template>
	<div>
		<a-upload
			v-decorator="[item.props, {
                valuePropName: 'fileList',
                getValueFromEvent: uploadNormFile,
            }]"
			:listType="item.listType || 'picture-card'"
			:name="item.name || 'files'"
			:action="item.action"
			list-type="picture"
			@preview="(file)=>{uploadPreviewHandle(file,item)}"
			:remove="(file)=>{return uploadRemoveHandle(file,item)}"
			:disabled="item.isDisabled"
			:showUploadList="item.showUploadList"
			:beforeUpload="(file)=>{return beforeUpload(file,item)}"
			:customRequest="(obj)=>{uploadCustomRequest(obj,item)}"
			:accept="item.accept || null"
		>
			<a-button v-if="item.listType === 'picture' || item.listType === 'text'">
				<a-icon type="upload" />upload
			</a-button>
			<div v-else>
				<a-icon :type="loading ? 'loading' : 'plus'" />
				<div class="ant-upload-text">Upload</div>
			</div>
		</a-upload>
	</div>
</template>

<script>
import UpladFile from '../utils/uploadToAliOss.js'
export default {
	name: '',
	data() {
		return {
			inputVal: this.value || '',
			loading: ''
		}
	},
	methods: {
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		uploadPreviewHandle(file, item) {
			if (!item.previewPath && item.listType !== 'text') {
				console.warn('在使用非text类型的控件时，必须添加previewPath')
				return
			}
			this.ossUploadPreviewHandle(file, item)
		},
		uploadRemoveHandle(file, item) {
			let props = item.props
			let values = this.form.getFieldsValue([item.props])
			let newValues = values[item.props].filter(item1 => {
				return file.uid !== item1.uid
			})
			let obj = {}
			obj[item.props] = newValues
			this.$nextTick(() => {
				this.form.setFieldsValue(obj)
			})
			return false
		},
		beforeUpload(file, item) {
			if (item.beforeUpload) {
				if (!item.beforeUpload(file)) {
					console.error('上传')
				}
				return item.beforeUpload(file)
			}
			return true
		},
		uploadCustomRequest(obj, item) {
			let _this = this
			var form = new FormData()
			form.append('file', obj.file) // 文件对象
			var xhr = new XMLHttpRequest()
			xhr.open('post', obj.action, true)
			xhr.onload = function(res) {
				//console.log(res)
			}
			xhr.send(form)
			xhr.onreadystatechange = function() {
				if (xhr.readyState == 4) {
					let props = item.props
					var res = JSON.parse(xhr.responseText)
					if (res.success) {
						_this.onSuccess(props, res.data)
					} else {
						_this.onError(props, res.msg)
					}
				}
			}
			xhr.ontimeout = function() {
				_this.onError(props)
			}
		}
	},
	watch: {
		value: function(val) {
			this.inputVal = val
		}
	},
	props: {
		value: {
			type: [String, Number]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
