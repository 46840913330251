<template>
	<a-locale-provider :locale="locale">
		<div id="app">
			<router-view />
			<!--这里为应用容器-->
			<div id="vue"></div>
		</div>
	</a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
	name: 'App',
	data() {
		return {
			locale: zhCN,
		}
	},
	components: {},
}
</script>

<style lang="scss">
#app {
	min-width: 1300px;
	overflow-x: auto;
	// 修复tab栏下边距
	.ant-tabs-bar {
		margin: 0;
		border-bottom: 0;
	}
	// 自定义tab栏样式
	.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
		border: none;
		border-top: 1px solid #f5f5f5;
		background: #f5f5f5;
		height: 44px;
		line-height: 44px;
		font-size: 14px;
		width: 160px;
		padding: 0;
		text-align: center;
		border-radius: 0;
	}
	.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
		border-bottom: none;
		border-top: 1px solid $primary;
		background: #fff;
		border-radius: 0;
	}
	// 修改small类型表格头间距
	.ant-table-small > .ant-table-content > .ant-table-body {
		margin: 0;
	}

	// .ant-table-title {
	//   display: none;
	// }

	// .ant-select-selection--single {
	//   height: 32px;
	// }
	// .ant-pagination-options-quick-jumper input {
	//   height: 32px;
	// }
	// .ant-select-selection__rendered {
	//   line-height: 32px;
	// }
	// .ant-pagination-options-quick-jumper {
	//   height: 32px;
	//   line-height: 32px;
	// }
}
// modal
.ywh-modal.ant-modal-content {
	box-shadow: 0px 10px 30px 0px rgba(36, 42, 51, 0.1);
}
.ywh-modal .ant-modal-header {
	border-bottom: 0px;
}
.ywh-modal .ant-modal-title {
	font-weight: 400;
}
.ywh-modal .ant-modal-footer {
	border-top: 0px;
}
.ywh-modal .ant-modal-body {
	padding: 0px 24px;
}
.ywh-modal .ant-btn {
	height: 36px;
	padding: 0 26px;
}
.ywh-modal .vertical .custom-label-loyat__left {
	padding: 8px 0;
	color: #666666;
}
.ywh-modal .ant-form-item {
	margin-bottom: 10px;
}
.ywh-modal .ant-input {
	height: 36px;
}
.custom-label-loyat.vertical {
}

.ant-upload-list-picture-card-container {
	height: auto !important;
	margin: 0 !important;
}
</style>
