/*
 * @Author: your name
 * @Date: 2020-06-05 14:48:08
 * @LastEditTime: 2020-09-18 12:06:58
 * @LastEditors: Yin Xiang Zheng
 * @Description: In User Settings Edit
 * @FilePath: /sand-ui/components/zen-ui/form/emitHandel.js
 */


export default ($root) => {
    return (attr = {}) => {
        // $root.sFormProps.sOnValuesChange({ ...attr })
        // const { fnName, props, newVal } = attr
        // if (fnName === 'editValueChange') {
        //     $root.setFieldsValue({ [props]: newVal })
        // }

        $root.$emit('action', { ...attr })
    }
}