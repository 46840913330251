<template>
	<div>
		<a-checkbox
			:disabled="item.isDisabled || false"
			:size="size"
			:indeterminate="item.indeterminate"
			:checked="item.defaultValue"
			@click="
                clickTask(
                    null,
                    item,
                    item,
                    `checkbox选择值-${item.label}`,
                    false
                )
            "
			@change="
                e => {
                    changeCheckedHandle(e, item);
                }
            "
			v-model="checkboxVal"
		>{{ item.label || item.title }}</a-checkbox>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			checkboxVal: this.value || false
		}
	},
	methods: {
		clickTask(index, item, column, step, path) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				const task = {
					by_select: 'xpath',
					by_val:
						path === false
							? `//*[@id="${column.props}"]`
							: `//*[@id="${column.props}"]/${path ||
									''}label[${index + 1}]`,
					param: item.label || item.title,
					type: 'click',
					time: '0',
					step: step
				}
				this.$utt.addTask(task)
			}
		},
		changeCheckedHandle(e, column) {
			let value = (column.defaultValue = e.target.checked)
			this.$emit('change', value)

			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}

			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}

			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		}
	},
	watch: {
		value: function(val) {
			this.checkboxVal = val || false
		}
	},
	props: {
		value: {
			type: [String, Number, Boolean]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
