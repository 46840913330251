<template>
  <div :class="datepikerClass">
    <a-date-picker
      v-if="!item.datepickerType || item.datepickerType === 'date-picker'"
      :size="size"
      :disabled="item.isDisabled"
      :placeholder="item.placeholder || ''"
      :format="item.format || 'YYYY-MM-DD'"
      v-model="dateVal"
      :disabledDate="
        (currentDate) =>
          item.disabledDate ? item.disabledDate(currentDate, item) : ''
      "
      :disabledTime="
        (currentDate) =>
          item.disabledTime ? item.disabledTime(currentDate, item) : ''
      "
      :showTime="
        item.showTimeDefaultValue
          ? {
              defaultValue: moment(
                item.showTimeDefaultValue.time,
                item.showTimeDefaultValue.format
              ),
            }
          : false
      "
      :allowClear="item.allowClear"
      :showToday="item.showToday ? true : false"
      @change="(date, dateString) => pickerChangeHandle(date, dateString, item)"
    >
      <a-icon
        v-if="item.suffixIcon"
        slot="suffixIcon"
        :type="item.suffixIcon"
      />

      <template v-if="item.renderExtraFooter" slot="renderExtraFooter">
        <div v-html="item.renderExtraFooter"></div>
      </template>
    </a-date-picker>
    <a-month-picker
      v-if="item.datepickerType === 'month-picker'"
      :size="size"
      :disabled="item.isDisabled"
      :placeholder="item.placeholder || ''"
      :format="item.format || 'YYYY-MM'"
      v-model="dateVal"
      :disabledDate="
        (currentDate) =>
          item.disabledDate ? item.disabledDate(currentDate, item) : ''
      "
      :allowClear="item.allowClear"
      @change="(date, dateString) => pickerChangeHandle(date, dateString, item)"
    >
      <a-icon
        v-if="item.suffixIcon"
        slot="suffixIcon"
        :type="item.suffixIcon"
      />
      <template v-if="item.renderExtraFooter" slot="renderExtraFooter">
        <div v-html="item.renderExtraFooter"></div>
      </template>
    </a-month-picker>
    <a-range-picker
      v-if="item.datepickerType === 'range-picker'"
      :disabled="item.isDisabled"
      :size="size"
      :placeholder="item.placeholder || [$locale.startTime, $locale.endTime]"
      :ranges="
        item.ranges
          ? item.ranges
          : {
              Today: [moment(), moment()],
              'This Month': [moment(), moment().endOf('month')],
            }
      "
      :format="item.format || 'YYYY-MM-DD'"
      v-model="dateVal"
      :disabledDate="
        (currentDate) =>
          item.disabledDate ? item.disabledDate(currentDate, item) : ''
      "
      :disabledTime="
        (dates, partial) =>
          item.disabledTime ? item.disabledTime(dates, partial, item) : ''
      "
      :showTime="item.showTime || false"
      :allowClear="item.allowClear"
      @change="(date, dateString) => pickerChangeHandle(date, dateString, item)"
    >
      <a-icon
        v-if="item.suffixIcon"
        slot="suffixIcon"
        :type="item.suffixIcon"
      />
      <template v-if="item.renderExtraFooter" slot="renderExtraFooter">
        <div v-html="item.renderExtraFooter"></div>
      </template>
    </a-range-picker>
    <a-week-picker
      v-if="item.datepickerType === 'week-picker'"
      :size="size"
      :disabled="item.isDisabled"
      :placeholder="item.placeholder || ''"
      :format="item.format || 'YYYY-wo'"
      v-model="dateVal"
      :disabledDate="
        (currentDate) =>
          item.disabledDate ? item.disabledDate(currentDate, item) : ''
      "
      :allowClear="item.allowClear"
      @change="(date, dateString) => pickerChangeHandle(date, dateString, item)"
    >
      <a-icon
        v-if="item.suffixIcon"
        slot="suffixIcon"
        :type="item.suffixIcon"
      />
      <template v-if="item.renderExtraFooter" slot="renderExtraFooter">
        <div v-html="item.renderExtraFooter"></div>
      </template>
    </a-week-picker>
    <div class="zen-form-mask_title" v-if="item.mask">
      <span v-text="maskText"></span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  data() {
    let dateVal = null;
    dateVal = this.setDateVal();
    return {
      dateVal,
    };
  },
  computed: {
    datepikerClass() {
      const { item } = this;
      return [
        "zen-datePiker",
        { "piker-block": item.col },
        { "zen-datePiker-mask": item.mask },
      ];
    },
    maskText() {
      const { item, value } = this;
      return !value ? item.maskTitle : `${item.maskTitle}: ${value}`;
    },
  },
  methods: {
    moment,
    setDateVal() {
      let dateVal = null;
      let { datepickerType, format } = this.item;

      if (datepickerType === "range-picker") {
        dateVal = [];
        if (this.value && this.value[0]) {
          dateVal.push(moment(this.value[0], format || "YYYY-MM-DD"));
        }
        if (this.value && this.value[1]) {
          dateVal.push(moment(this.value[1], format || "YYYY-MM-DD"));
        }
      } else if (datepickerType === "date-picker") {
        // if (typeof this.value !== 'string') {
        // 	console.error('参数类型应该为:‘string’')
        // 	return
        // }
        if (this.value) {
          dateVal = moment(this.value, format || "YYYY-MM-DD");
        }
      } else if (datepickerType === "month-picker") {
        // if (typeof this.value !== 'string') {
        // 	console.error('参数类型应该为:‘string’')
        // 	return
        // }
        if (this.value) {
          dateVal = moment(this.value, format || "YYYY-MM");
        }
      } else if (datepickerType === "week-picker") {
        // if (typeof this.value !== 'string') {
        // 	console.error('参数类型应该为:‘string’')
        // 	return
        // }
        if (this.value) {
          dateVal = moment(this.value, format || "YYYY-w");
        }
      } else {
        if (this.value) {
          dateVal = moment(this.value, "YYYY-MM-DD");
          // // 2-24 by Jonny.Shao  datepicker 日期比大小 编辑回显时需要触发这个方法
          // this.pickerChangeHandle(dateVal, this.value, this.item)
        }
      }
      return dateVal;
    },
    setTimeTask(xpath, data, step) {
      if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
        //添加uitest日志
        let task = {};
        task.by_select = `id+css`;
        task.by_val = xpath;
        task.param = data;
        task.type = "date";
        task.time = "2";
        task.step = step;
        this.$utt.addTask(task);
      }
    },
    pickerChangeHandle(date, dateString, item) {
      if (dateString.constructor == Array) {
        dateString.forEach((fore, index) => {
          this.setTimeTask(
            `#${item.props} .ant-calendar-range-picker-input:nth-child(${
              index === 0 ? 1 : 3
            })`,
            fore,
            `修改时间-${item.props}`
          );
        });
      } else {
        this.setTimeTask(
          `#${item.props} input`,
          dateString,
          `修改时间-${item.props}`
        );
      }

      this.$emit("change", dateString);

      item.datePickChangeHandle
        ? item.datePickChangeHandle(date, dateString, item)
        : "";

      // 触发父级changeHandle
      if (this.parentType === "todo-list" || this.parentType === "group-list") {
        this.$emit("changeHandle", dateString, item, this.rowIndex);
      }

      // 触发zform组件上的change方法
      let findComponents = function(component) {
        if (component.$options.name === "zform") {
          if (component._change) {
            component._change({ dateString, column: item });
          }
          return;
        }
        if (component.$parent) {
          findComponents(component.$parent);
        }
      };
      findComponents(this);
    },
  },
  watch: {
    value: {
      handler: function(val) {
        console.log("val121212", val);
        this.dateVal = this.setDateVal();
      },
      immediate: true,
    },
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object, Date],
    },
    item: {
      type: Object,
    },
    size: {
      type: String,
    },
    form: {
      type: Object,
    },
    parentType: {
      type: String,
    },
    rowIndex: {
      type: Number,
    },
  },
};
</script>
<style lang="less" scoped>
.piker-block > span {
  width: 100%;
}
.zen-datePiker {
  position: relative;
}
.zen-datePiker-mask .ant-calendar-picker {
  width: 100%;
}
</style>
