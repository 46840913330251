/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-10-09 15:53:15
 */
import RenderColumns from "./components/index";
import emitHandel from './utils/emitHandel';
import find from 'lodash/find';
import { isArray } from './utils/index';

import './style/index.less';

const zdetailComponent = {
    name: 'zdetail2',
    props: {
        column: {
            type: Object,
            default: () => {
                return {}
            }
        },
        express: Array,
        // columns: {
        //     type: Array,
        // },
        dataSource: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            cloneDataSource: JSON.parse(JSON.stringify(this.dataSource)),
            childFormRef: null
        }
    },
    watch: {
        dataSource: {
            deep: true,
            handler(val) {
                this.cloneDataSource = JSON.parse(JSON.stringify(val))
            }
        }
    },
    // inject: {
    //     setChildrenRef: {},
    //     getScope: {
    //         default: () => { }
    //     }
    // },
    provide() {
        return {
            noneValue: '-',
            emitHandel: emitHandel(this),
            validateFields: (zform) => {
                this.childFormRef = zform
            }
        }
    },
    methods: {
        validateFields() {
            const { childFormRef } = this
            if (!childFormRef) {
                return
            }
            return childFormRef.zformValidateFieldsAndScroll()
        },
        decoratorOption(vnode) {
            if (vnode.data && vnode.data.directives) {
                const directive = find(vnode.data.directives, ['name', 'custom']);
                return directive ? directive.value : null;
            } else {
                return null;
            }
        },
        decoratorChildren(vnodes) {
            for (let i = 0, len = vnodes.length; i < len; i++) {
                const vnode = vnodes[i];
                if (vnode.children) {
                    vnode.children = this.decoratorChildren(vnode.children);
                } else if (vnode.componentOptions && vnode.componentOptions.children) {
                    vnode.componentOptions.children = this.decoratorChildren(
                        vnode.componentOptions.children,
                    );
                }
                const option = this.decoratorOption(vnode);
                if (option) {
                    if (option === 'time') {
                        vnode.children = [(<div>asdasd</div>)]
                    }
                    if (option === 'time1') {
                        vnode.children = [(<div>11111</div>)]
                    }
                }
            }
            return vnodes;
        },
        formatedValue(dataSource, column) {
            const { props, mapping } = column
            let val = dataSource[props]

            if (!mapping || Object.keys(mapping).length === 0) {
                return val
            }
            let result = ''
            mapping.forEach(item => {
                if (val == item.value) {
                    result = item.text || item.label || ''
                }
            })
            return result
        },
        renderContent() {
            const { column, cloneDataSource, formatedValue, express, dataSource } = this
            const value = formatedValue(cloneDataSource, column)
            const props = {
                props: { column, _value: value, express, dataSource }
            }
            return (<RenderColumns {...props}></RenderColumns>)
            // return column.map((column) => {
            //     const value = formatedValue(cloneDataSource, column)
            //     const props = {
            //         props: { column, _value: value }
            //     }
            //     return (<RenderColumns {...props}></RenderColumns>)
            // })
        }
    },
    render() {
        const {
            renderContent,
        } = this;
        return (
            <div>
                {renderContent()}
            </div>
        )
    }
}

export default zdetailComponent