<template>
	<div>
		<a-cascader
			:placeholder="item.placeholder"
			:options="item.options"
			:fieldNames="
                item.fieldNames && {
                    label: item.fieldNames.label,
                    value: item.fieldNames.value,
                    children: item.fieldNames.children
                }
            "
			:changeOnSelect="item.changeOnSelect"
			:expandTrigger="item.expandTrigger"
			:showSearch="item.showSearch && { cascaderFilter }"
			:notFoundContent="item.notFoundContent"
			:size="item.size"
			:disabled="item.isDisabled"
			@change="
                value => {
                    changeHandleByValue(value, item);
                }
            "
			v-model="cascaderVal"
		>
			<a-icon :type="item.suffixIcon" slot="suffixIcon" class="test" v-if="item.suffixIcon" />
		</a-cascader>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			cascaderVal: this.value || []
		}
	},
	methods: {
		changeHandleByValue(val, column, subColumn) {
			let value = val || ''
			this.$emit('change', value)
			if (subColumn && subColumn.changeHandle) {
				this.subColumnCallback(value, column, subColumn, 'changeHandle')
				return
			}
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}
		},
		cascaderFilter(inputValue, path) {
			return path.some(
				option =>
					option.label
						.toLowerCase()
						.indexOf(inputValue.toLowerCase()) > -1
			)
		}
	},
	watch: {
		value: function(val) {
			this.cascaderVal = val || []
		}
	},
	props: {
		value: {
			type: Array
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
