<template>
	<div class="zen-group-box">
		<a-form :form="form">
			<a-row :gutter="item.space || 5">
				<a-col
					v-for="(column, colindex) in item.subColumns"
					:key="colindex"
					v-show="
						column.isHidden != undefined ? !column.isHidden : true
					"
					:span="column.col || 12"
				>
					<a-form-item :style="formItemStyle(column)">
						<zinput
							v-if="column.type === 'input'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zinput>
						<zinput-number
							v-else-if="column.type === 'input-number'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zinput-number>
						<ztextArea
							v-else-if="column.type === 'textarea'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
							@blurHandle="blurHandle"
						></ztextArea>
						<zselect
							v-else-if="column.type === 'select'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:columns="item.subColumns"
							:updateForm="updateForm"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zselect>
						<!-- zdivider -->
						<zdivider
							v-else-if="column.type === 'divider'"
							:item="column"
							parentType="todo-list"
						></zdivider>

						<!-- button -->
						<zbutton
							v-else-if="column.type === 'button'"
							:item="column"
							:form="form"
							:id="column.props"
						></zbutton>
						<!-- buttons -->
						<zbuttons
							v-else-if="column.type === 'buttons'"
							:item="column"
							:form="form"
							:id="column.props"
						></zbuttons>
						<!-- tag -->
						<ztag
							v-else-if="column.type === 'tag'"
							:item="column"
							:form="form"
						></ztag>
						<!-- empty -->
						<zempty
							v-else-if="column.type === 'empty'"
							parentType="todo-list"
						></zempty>
						<zcheckbox
							v-else-if="column.type === 'checkbox'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zcheckbox>
						<zcheckboxGroup
							v-else-if="column.type === 'checkboxGroup'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zcheckboxGroup>
						<zswitch
							v-else-if="column.type === 'switch'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></zswitch>
						<zdatepicker
							v-else-if="column.type === 'datepicker'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
							@blurHandle="blurHandle"
						></zdatepicker>
						<ztimepicker
							v-else-if="column.type === 'timepicker'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
						></ztimepicker>
						<zinput-search
							v-else-if="column.type === 'input-search'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
							@blurHandle="blurHandle"
						></zinput-search>
						<zcascader
							v-if="column.type === 'cascader'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="todo-list"
							@changeHandle="changeHandle"
							@blurHandle="blurHandle"
						></zcascader>
						<zradioGroup
							v-if="column.type === 'radioGroup'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: getItemRules(column.rules, column),
									validateFirst: true,
								},
							]"
							:item="column"
							:size="size"
							:form="form"
							parentType="group-list"
							@changeHandle="changeHandle"
						></zradioGroup>

						<zoss-upload
							v-if="column.type === 'oss-upload'"
							v-decorator="[
								column.props,
								{
									initialValue: groupListVal[column.props],
									rules: [
										...(getItemRules(
											column.rules,
											column
										) || []),
										{
											message: getUploadErrorMsg(),
											validator: (
												rule,
												value,
												callback
											) =>
												handleValidatorOssUpload(
													rule,
													value,
													callback,
													column
												),
										},
									],
									getValueFromEvent: uploadNormFile,
								},
							]"
							:item="column"
							:form="form"
							parentType="group-list"
							@changeHandle="changeHandle"
						></zoss-upload>
					</a-form-item>

					<span
						:style="[
							Object.assign(
								{
									position: 'absolute',
									top: '10px',
									left: '-4px',
								},
								item.splitCharStyle || {}
							),
						]"
						v-if="colindex > 0 && colindex < item.subColumns.length"
						>{{ item.splitChar }}</span
					>
				</a-col>
			</a-row>
		</a-form>
	</div>
</template>

<script>
import formItem from '../formItem'
import zinput from './zinput'
import zselect from './zselect'
import zinputNumber from './zinputNumber'
import ztextArea from './ztextArea'
import zcheckbox from './zcheckbox'
import zcheckboxGroup from './zcheckboxGroup'
import zswitch from './zswitch'
import zdatepicker from './zdatepicker'
import ztimepicker from './ztimepicker'
import zinputSearch from './zinputSearch'
import zcascader from './zcascader'
import zradioGroup from './zradioGroup'
import zossUpload from './zossUpload'
import zdivider from './zdivider'
import zempty from './zempty'
import zbutton from './zbutton'
import zbuttons from './zbuttons'
import ztag from './ztag'
export default {
	data() {
		return {
			form: this.$form.createForm(this),
			groupListVal: this.value
				? JSON.parse(JSON.stringify(this.value))
				: {},
		}
	},
	components: {
		formItem,
		zinput,
		zselect,
		zinputNumber,
		zinputNumber,
		ztextArea,
		zcheckbox,
		zcheckboxGroup,
		zradioGroup,
		zswitch,
		zdatepicker,
		ztimepicker,
		zinputSearch,
		zcascader,
		zossUpload,
		zdivider,
		zempty,
		zbutton,
		zbuttons,
		ztag,
	},
	methods: {
		formItemStyle(item) {
			let conditionalJudgment = {}
			Object.assign(
				conditionalJudgment,
				item.type == 'textarea' ? { marginTop: '7px' } : {}
			)

			return Object.assign(
				{
					maxWidth: item.maxWidth,
				},
				conditionalJudgment
			)
		},
		getItemRules(rules, item) {
			if (!rules || rules.length === 0 || item.isHidden) {
				return []
			}
			if (item && item.isDisabled === true) {
				return []
			}
			let findedRequired = rules.find((item) => item.required === true)
			if (findedRequired) {
				findedRequired.message = '该字段不能为空'
			}
			return rules
		},
		getUploadErrorMsg() {
			return 'Please wait for the file to finish uploading'
			// return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
			// 	? '请等待文件上传完毕'
			// 	: 'Please wait for the file to finish uploading'
		},
		handleValidatorOssUpload(rule, value, callback, item) {
			if (!value || value.length === 0) {
				callback()
			}
			if (item.uploading) {
				callback(new Error('illegal'))
			}
			callback()
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		blurHandle(e, column) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				let task = {}
				task.by_select = `id`
				task.by_val = `${column.props}`
				task.param = `${e.target.value}`
				task.type = 'sendkey'
				task.time = '2'
				task.step = `修改input值-${column.label}`
				this.$utt.addTask(task)
			}
		},
		emitFormChange(groupListVal) {
			let { item } = this
			let findComponents = function (component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value: groupListVal, column: item })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		changeHandle(value, column) {
			if (!this.groupListVal) {
				return
			}
			this.groupListVal[column.props] = value
			this.$emit('change', this.groupListVal)
			this.emitFormChange(this.groupListVal)
		},
		updateForm(formData) {
			let newFormData = {}
			this.item.subColumns.forEach((subColumn) => {
				if (
					formData[subColumn.props] !== undefined &&
					formData[subColumn.props] !== null
				) {
					newFormData[subColumn.props] = formData[subColumn.props]
				}
			})
			this.groupListVal = JSON.parse(JSON.stringify(newFormData)) || {}
			this.form.setFieldsValue(this.groupListVal)
		},
	},
	watch: {
		value: function (val) {
			this.groupListVal = JSON.parse(JSON.stringify(val)) || {}
			this.form.setFieldsValue(this.groupListVal)
		},
		item: {
			handler(newValue, oldValue) {
				if (this.item.isDisabled !== undefined) {
					this.item.subColumns.forEach((subItem) => {
						subItem.isDisabled = this.item.isDisabled
					})
				}
			},
			deep: true,
		},
	},
	props: {
		value: {
			type: Object,
		},
		item: {
			type: Object,
		},
		size: {
			type: String,
		},
	},
	beforeCreate() {
		this.form = this.$form.createForm(this)
	},
	created() {
		if (this.item.isDisabled !== undefined) {
			this.item.subColumns.forEach((subItem) => {
				subItem.isDisabled = this.item.isDisabled
			})
		}
	},
}
</script>
<style lang="less" >
.todolist-item {
	position: relative;
	padding-right: 20px;
	.todolist-action {
		position: absolute;
		right: 0px;
		top: 0px;
	}
}

.zen-group-box {
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0px;
	}
}

// .zen-group-box {
// 	.ant-form-item-control {
// 		margin-bottom: 10px;
// 	}
// }
// 	.ant-form-item-children {
// 		line-height: 45px!important;
// 	}
// }
</style>
