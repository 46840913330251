<template>
	<div class="u-header">
		<div>
			<div class="logo">
				<img src="@/assets/images/logo.png" title="沪荟云" />
			</div>
			<div class="guide">
				<a href="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/template/%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E%E6%B2%AA%E8%8D%9F%E4%BA%91%E7%A4%BE%E5%8C%BA.pdf" target="_blank">
					使用指南
					<a-icon type="download" style="vertical-align: 0" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	methods: {},
}
</script>
<style lang="scss">
.u-header {
	height: 80px;
	background: $blue;
	display: flex;
	align-items: center;
	> div {
		width: 1100px;
		@include space-between;
		margin: 0 auto;
		.guide {
			color: #fff;
			line-height: 40px;
			a {
				color: #fff;
			}
			a:hover {
			}
		}
		.logo {
			width: 124px;
			height: 40px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>