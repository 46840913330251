var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
                !_vm.item.checkedChildrenType ||
                    _vm.item.checkedChildrenType == 'string'
            )?_c('a-switch',{attrs:{"disabled":_vm.item.isDisabled || false,"checkedChildren":_vm.item.checkedChildren || null,"unCheckedChildren":_vm.item.unCheckedChildren || null,"size":_vm.item.size||null},on:{"click":function (e) {
                    _vm.clickSwitchHandle(e, _vm.item);
                },"change":function (e) {
                    _vm.changeSwitchHandle(e, _vm.item);
                }},model:{value:(_vm.switchVal),callback:function ($$v) {_vm.switchVal=$$v},expression:"switchVal"}}):_vm._e(),(
                _vm.item.checkedChildrenType && _vm.item.checkedChildrenType == 'icon'
            )?_c('a-switch',{attrs:{"disabled":_vm.item.isDisabled || false,"size":_vm.item.size||null},on:{"click":function (e) {
                    _vm.clickSwitchHandle(e, _vm.item);
                },"change":function (e) {
                    _vm.changeSwitchHandle(e, _vm.item);
                }},model:{value:(_vm.switchVal),callback:function ($$v) {_vm.switchVal=$$v},expression:"switchVal"}},[_c('a-icon',{attrs:{"slot":"checkedChildren","type":_vm.item.checkedChildren},slot:"checkedChildren"}),_c('a-icon',{attrs:{"slot":"unCheckedChildren","type":_vm.item.unCheckedChildren},slot:"unCheckedChildren"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }