var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"todolist-box"},[_c('a-form',{attrs:{"form":_vm.form}},[_vm._l((_vm.todoListVal),function(todo,rowindex){return _c('div',{key:rowindex,attrs:{"required":false}},[_c('div',{staticClass:"todolist-item"},[_c('div',{staticClass:"todolist-content"},[_c('a-row',{attrs:{"gutter":_vm.item.space || 5}},[_vm._l((_vm.item.subColumns),function(column,colindex){return [(!column.isHidden)?_c('a-col',{key:colindex,attrs:{"span":column.col || 12}},[_c('a-form-item',{style:(_vm.formItemStyle(column))},[(
											colindex > 0 &&
											_vm.item.symlinks &&
											_vm.item.symlinks[colindex - 1]
										)?_c('span',{staticStyle:{"position":"absolute","top":"0px","left":"-10px"}},[_vm._v(_vm._s(_vm.item.symlinks[colindex - 1]))]):_vm._e(),(column.type === 'input')?_c('zinput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'input-number'
										)?_c('zinput-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'textarea'
										)?_c('ztextArea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle,"blurHandle":_vm.blurHandle}}):_vm._e(),(column.type === 'select')?_c('zselect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"columns":_vm.item.subColumns,"updateForm":_vm.updateForm,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'divider'
										)?_c('zdivider',{attrs:{"item":column,"parentType":"todo-list"}}):(column.type === 'empty')?_c('zempty',{attrs:{"parentType":"todo-list"}}):(column.type === 'button')?_c('zbutton',{attrs:{"item":column,"size":_vm.size,"form":_vm.form,"id":column.props}}):(
											column.type === 'buttons'
										)?_c('zbuttons',{attrs:{"item":column,"size":_vm.size,"form":_vm.form,"id":column.props}}):(column.type === 'tag')?_c('ztag',{attrs:{"item":column,"size":_vm.size,"form":_vm.form}}):(
											column.type === 'checkbox'
										)?_c('zcheckbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'checkboxGroup'
										)?_c('zcheckboxGroup',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(column.type === 'switch')?_c('zswitch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'datepicker'
										)?_c('zdatepicker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle,"blurHandle":_vm.blurHandle}}):(
											column.type === 'timepicker'
										)?_c('ztimepicker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):(
											column.type === 'input-search'
										)?_c('zinput-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle,"blurHandle":_vm.blurHandle}}):_vm._e(),(column.type === 'cascader')?_c('zcascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"size":_vm.size,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle,"blurHandle":_vm.blurHandle}}):_vm._e(),(column.type === 'radioGroup')?_c('zradio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: _vm.getItemRules(
													column.rules,
													column
												),
												validateFirst: true,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\tvalidateFirst: true,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"form":_vm.form,"parentType":"todo-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):_vm._e(),(column.type === 'oss-upload')?_c('zoss-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											_vm.setDecorator(column, rowindex),
											{
												initialValue:
													_vm.todoListVal[rowindex][
														column.props
													],
												rules: (_vm.getItemRules(
														column.rules,
														column
													) || []).concat( [{
														message: _vm.getUploadErrorMsg(),
														validator: function (
															rule,
															value,
															callback
														) { return _vm.handleValidatorOssUpload(
																rule,
																value,
																callback,
																column
															); },
													}] ),
												getValueFromEvent: _vm.uploadNormFile,
											} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\tsetDecorator(column, rowindex),\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\tinitialValue:\n\t\t\t\t\t\t\t\t\t\t\t\t\ttodoListVal[rowindex][\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.props\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t\t...(getItemRules(\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn.rules,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t\t) || []),\n\t\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: getUploadErrorMsg(),\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tvalidator: (\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trule,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvalue,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcallback\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t) =>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\thandleValidatorOssUpload(\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trule,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvalue,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcallback,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolumn\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t),\n\t\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\tgetValueFromEvent: uploadNormFile,\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"item":column,"form":_vm.form,"parentType":"group-list","rowIndex":rowindex},on:{"changeHandle":_vm.changeHandle}}):_vm._e()],1)],1):_vm._e()]}),_c('a-col',{attrs:{"span":_vm.item.actionColSpan || 2}},[_c('a-form-item',[_vm._l((_vm.item.btns),function(btn,index1){return _c('a-icon',{key:index1,staticStyle:{"font-size":"16px","cursor":"pointer","margin-right":"10px"},attrs:{"type":btn.icon},on:{"click":function($event){btn.clickHandle &&
											btn.clickHandle(
												rowindex,
												_vm.todoListVal
											)}}})}),(
										_vm.item.allowDeleteFirst
											? true
											: rowindex > 0
									)?_c('a-icon',{staticClass:"dynamic-delete-button",staticStyle:{"font-size":"16px","cursor":"pointer"},attrs:{"type":"minus-circle-o"},on:{"click":function($event){return _vm.deleteHandle(rowindex)}}}):_vm._e()],2)],1)],2)],1)])])}),_c('a-button',{staticStyle:{"width":"60%"},attrs:{"disabled":_vm.item.isDisabled,"type":"dashed"},on:{"click":_vm.addHandle}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.addText || 'Add field')}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }