<template>
	<!--<div v-if="!fixedLabelWidth">
        <a-form-item :label="item.label || ''"
                :label-col="{
                    xs: { span: 24, offset: 0 },
                    sm: { span: item.labelCol || 8, offset:0 },
                }"
                :validate-status="item.validate && item.validate.validateStatus"
                :help="item.validate && item.validate.errorMsg"
                :wrapper-col="{
                    xs: { span: 24, offset: 0 },
                    sm: { span: item.wrapperCol || 16, offset: 0 },
                }"
                :required="(item.validate || item.rules) ? true : item.hasSubValidate ? false : null" v-show="!item.isHidden">
            <slot></slot>
        </a-form-item>
	</div>-->
	<div :class="['custom-label-loyat', formLayout]">
		<div
			:class="['custom-label-loyat__left', size]"
			v-if="!!itemLabel"
			:style="{ minWidth: getLabelWidth, textAlign: textAlign }"
		>
			<span style="color:red" v-if="requiredIcon">*</span>
			<span style="line-height: 1.5;" v-html="itemLabel"></span>
			<a-popover v-if="item.tooltipsInlabel">
				<template slot="content">
					<p>{{item.tooltipsInlabel.content||""}}</p>
				</template>
				<a-icon
					:type="item.tooltipsInlabel.iconType ||'question-circle'"
					:style="{color:item.tooltipsInlabel.color||'#0c85ee',marginLeft:'5px',verticalAlign:'middle'}"
				/>
			</a-popover>
		</div>
		<div class="custom-label-loyat__right">
			<a-form-item
				label
				:label-col="getLabelCol"
				:wrapper-col="getWrapperCol"
				:validate-status="item.validate && item.validate.validateStatus"
				:help="item.validate && item.validate.errorMsg"
				:required="
                    item.validate || item.rules
                        ? true
                        : item.hasSubValidate
                        ? false
                        : null
                "
				v-show="!item.isHidden"
				:style="formItemStyle(item)"
			>
				<div style="display: flex;">
					<span
						style="color:red;vertical-align: middle;padding-right: 8px;"
						v-if="
                        !itemLabel &&
                            ((item.rules && !!item.rules.length) ||
                                item.validate)
                    "
					>*</span>
					<div style="flex: 1">
						<a-popover
							v-if="item.popoverContent"
							:defaultVisible="false"
							:placement="item.placement || 'bottomLeft'"
							:mouseEnterDelay="item.mouseEnterDelay || 0"
							:mouseLeaveDelay="item.mouseLeaveDelay || 0"
							:overlayStyle="{
                        width: item.overlayWidth
                            ? typeof item.overlayWidth === 'number'
                                ? item.overlayWidth + 'px'
                                : item.overlayWidth
                            : '300px',
                        'word-wrap': 'break-word'
                    }"
							:autoAdjustOverflow="true"
						>
							<div v-if="item.popoverTitle" slot="title" v-html="item.popoverTitle"></div>
							<div
								v-if="
                            item.popoverContent && item.popoverContent !== true
                        "
								slot="content"
								v-html="item.popoverContent"
							></div>
							<div @mouseover="mouseoverHandle(item, form)" @mouseleave="mouseleaveHandle(item, form)">
								<slot></slot>
							</div>
						</a-popover>
						<slot v-else></slot>
					</div>
				</div>
			</a-form-item>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	props: {
		item: {
			type: Object
		},
		form: {
			type: Object
		},
		fixedLabelWidth: {
			type: String,
			default: '60px'
		},
		textAlign: {
			type: String,
			default: 'right'
		},
		formLayout: {
			type: String,
			default: 'horizontal'
		},
		size: {
			type: String,
			default: 'default'
		}
	},
	methods: {
		itemCallback(attr = {}, fn) {
			if (Object.keys(attr).length === 0) {
				return
			}
			attr.item[fn](attr.item, attr.form)
		},
		mouseoverHandle(item, form) {
			if (item.mouseoverHandle) {
				this.itemCallback({ item, form }, 'mouseoverHandle')
			}
		},
		mouseleaveHandle(item, form) {
			if (item.mouseleaveHandle) {
				this.itemCallback({ item, form }, 'mouseleaveHandle')
			}
		},
		formItemStyle(item) {
			let conditionalJudgment = {}
			Object.assign(
				conditionalJudgment,
				item.type == 'group' ? { marginBottom: 0 } : {}
			)

			Object.assign(
				conditionalJudgment,
				item.type == 'todo-list' || item.type == 'table-list'
					? { marginBottom: '20px' }
					: {}
			)

			Object.assign(
				conditionalJudgment,
				item.type == 'textarea' || item.type == 'table-list'
					? { marginTop: '7px' }
					: {}
			)

			return Object.assign(
				{
					maxWidth: item.maxWidth
				},
				conditionalJudgment
			)
		}
	},
	computed: {
		getLabelWidth() {
			return this.item.labelWidth
				? this.item.fixedLabelWidth
				: this.fixedLabelWidth
		},
		getLabelCol() {
			let labelCol = this.item.labelCol
			if (labelCol && Object.keys(labelCol).length > 0) {
				return labelCol
			}
			return {
				xs: { span: 24, offset: 0 },
				sm: { span: 0, offset: 0 }
			}
		},
		getWrapperCol() {
			let wrapperCol = this.item.wrapperCol
			if (wrapperCol && Object.keys(wrapperCol).length > 0) {
				return wrapperCol
			}
			return {
				xs: { span: 24, offset: 0 },
				sm: { span: 24, offset: 0 }
			}
		},
		requiredIcon() {
			let hasRequred = false
			if (this.item.rules) {
				this.item.rules.forEach(element => {
					if (element.required) {
						hasRequred = true
					}
				})
			}
			return (this.item.rules && hasRequred) || this.item.validate
		},
		itemLabel() {
			if (this.item.zh && this.item.en) {
				return (localStorage.getItem('lang') || 'zh_CN') == 'zh_CN'
					? this.item.zh
					: this.item.en
			}
			return this.item.label
		}
	},
	mounted() {}
}
</script>

<style lang="less">
@import './style/index.less';
</style>
