import moment from 'moment'
import { isArray } from '../utils/index'
const prefixCls = 'znumber-item'

const ZTimeComponent = {
    name: 'ZNumberComponent',
    props: {
        _value: {
            type: [Number, Array]
        },
        column: {
            type: Object
        }
    },
    inject: ['noneValue'],
    methods: {
        toDecimal(arg, decimal) {
            let f = parseFloat(arg);
            if (isNaN(f)) {
                return this.noneValue;
            }
            return f.toFixed(decimal)
        }
    },
    computed: {
        getText() {
            const { _value = undefined, column: { decimal, splitFlagChar = "," } } = this.$props
            const { noneValue, toDecimal } = this
            if (!_value && _value !== 0) {
                return noneValue
            }
            let val = _value
            if (isArray(_value)) {
                let valArr = []
                _value.forEach(element => {
                    if (typeof decimal === 'number') {
                        valArr.push(toDecimal(element, decimal))
                    } else {
                        valArr.push(element)
                    }
                });
                val = valArr.join(splitFlagChar)
            }
            if (typeof decimal === 'number') {
                val = toDecimal(_value, decimal)
            }
            return val
        }
    },
    render() {
        const { getText } = this
        const { column: { addonBefore, addonAfter, color } } = this.$props
        return (
            <div class={prefixCls}>
                {
                    addonBefore ? <span class={`${prefixCls}-addon-before`} domPropsInnerHTML={addonBefore}></span> : ''
                }
                <span style={{ color }}>{getText}</span>
                {
                    addonAfter ? <span class={`${prefixCls}-addon-after`} domPropsInnerHTML={addonAfter}></span> : ''
                }
            </div>
        )
    }
}

export default ZTimeComponent