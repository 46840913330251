import moment from 'moment'
import { isArray } from '../utils/index'

const ZTimeComponent = {
    name: 'ZTagComponent',
    props: {
        _value: {
            type: [String, Array, Number]
        },
        column: {
            type: Object
        }
    },
    inject: ['noneValue'],
    methods: {

    },
    computed: {
        getText() {
            const { _value = undefined, format, delimiter } = this.$props
            const { noneValue } = this
            if (!_value && _value !== 0) {
                return noneValue
            }
            return _value
        }
    },
    render() {
        const { getText, column } = this
        const props = {
            props: { ...column }
        }
        return (
            <div>
                <a-tag {...props}>
                    {getText}
                </a-tag>
            </div>
        )
    }
}

export default ZTimeComponent