<template>
	<div>
		<!-- uploadedListLayout: horizontal -->
		<a-upload
			:class="['_zen-oss-upload', item.uploadedListLayout]"
			:listType="item.listType || 'picture-card'"
			:name="item.name || 'files'"
			:fileList="fileList"
			:defaultFileList="value"
			:before-upload="
				(file, fileList) => ossUploadBeforeUpload(file, fileList, item)
			"
			@change="(obj) => ossUploadChangeHandle(obj, item)"
			@preview="
				(file) => {
					ossUploadPreviewHandle(file, item)
				}
			"
			:remove="
				(file) => {
					return uploadRemoveHandle(file, item)
				}
			"
			:disabled="item.isDisabled"
			:showUploadList="item.showUploadList"
			:customRequest="
				(obj) => {
					ossUploadCustomRequest(obj, item)
				}
			"
		>
			<template
				v-if="!item.maxUpload || fileList.length < item.maxUpload"
			>
				<a-button
					ref="uploadFile"
					:disabled="item.isDisabled"
					:loading="uploading"
					v-if="
						item.listType === 'picture' || item.listType === 'text'
					"
				>
					<a-icon type="upload" />
					{{ item.uploadText }}
				</a-button>
				<div v-else ref="uploadFile">
					<a-icon
						style="width: 19px; height: 18px"
						:type="uploading ? 'loading' : 'upload'"
					/>
					<div class="ant-upload-text">{{ item.uploadText }}</div>
				</div>
			</template>
		</a-upload>
	</div>
</template>

<script>
import UpladFile from '../utils/uploadToAliOss.js'
import axios from '@/api/request'
export default {
	name: '',
	data() {
		return {
			inputVal: this.value || '',
			uploading: false,
			fileList: this.value || [],
		}
	},
	methods: {
		triggerUpload() {
			this.$refs['uploadFile'].click()
		},
		setUploadingStatus(fileList) {
			let list = fileList || this.fileList
			if (!list || list.length === 0) {
				this.item.uploading = false
				this.uploading = false
				return
			}
			for (const iterator of list) {
				if (
					iterator.status !== undefined &&
					iterator.status !== 'done'
				) {
					this.item.uploading = true
					this.uploading = true
					return
				}
			}
			this.item.uploading = false
			this.uploading = false
		},
		ossUploadChangeHandle(obj, item) {
			this.setUploadingStatus()

			// 过滤上传属性
			let result = obj.fileList.map((file) => {
				let { status, name, uid, url } = file
				return { status, name, uid, url }
			})
			this.$emit('change', result)
			this.$emit('changeHandle', result, item)

			item.ossUploadChangeHandle
				? item.ossUploadChangeHandle(result, item)
				: ''
			this.$nextTick(() => {
				this.setUploadingStatus()
			})
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		async uploadRemoveHandle(file, item) {
			if (item.isDisabled) {
				return false
			}

			let props = item.props
			let values = this.form.getFieldsValue([item.props])
			let newValues = values[item.props].filter((item1) => {
				return file.uid !== item1.uid
			})

			this.setUploadingStatus(newValues)
			this.$emit('change', newValues)

			item.ossUploadChangeHandle
				? item.ossUploadChangeHandle(newValues, item)
				: ''

			item.ossUploadDeleteHandle
				? item.ossUploadDeleteHandle(file, item)
				: ''

			this.$nextTick(() => {
				this.setUploadingStatus()
			})
			return false
		},
		ossUploadPreviewHandle(file, item) {
			let url = file.url || item.previewPath + file.filename
			// 为了兼容憨批阿里OSS写法 https://github.com/ali-sdk/ali-oss/blob/master/lib/client.js#L229
			window.open(
				encodeURIComponent(url)
					.replace(/%2F/g, '/')
					.replace(/\+/g, '%2B')
					.replace('%3A', ':')
			)
		},
		ossUploadBeforeUpload(File, FileList, item) {
			const maxFileNameLength = 150
			let res = true
			FileList.forEach((selFile) => {
				if (selFile.name.length > maxFileNameLength) {
					res = false
				}
			})
			if (res) {
				return true
			}
			setTimeout(() => {
				const fileList = []
				this.fileList.forEach((selFile) => {
					if (selFile.name.length < maxFileNameLength) {
						fileList.push(selFile)
					}
				})
				if (fileList.length !== this.fileList.length) {
					this.$message.error(
						'The file name cannot exceed 150 characters!'
					)
				}
				this.$emit('change', fileList)
				this.$emit('changeHandle', fileList, item)
			}, 0)
			return false
		},
		ossUploadCustomRequest(obj, item) {
			this.uploading = true
			obj.onProgress()
			UpladFile(
				obj.file,
				item.uploadDir,
				item.uploadBasePath,
				item.uploadParams || {}
			)
				.then((res) => {
					if (this.form) {
						let values = this.form.getFieldsValue([item.props])
						let uploadedFile = values[item.props].find((item) => {
							return item.uid === obj.file.uid
						})
						uploadedFile.url = res.url
						console.log('item', item);
						item.successCallback
							? item.successCallback(uploadedFile, values, item)
							: ''
					}else {
						this.$emit('changeHandle', res)
					}
					obj.onSuccess(res)
					this.setUploadingStatus()
					return res
				})
				.catch((err) => {
					console.log(err);
					obj.onError(err)
					this.setUploadingStatus()
					return err
				})
		},
	},
	watch: {
		value: function (val) {
			this.fileList = val
		},
		uploading: function (val) {
			this.item.uploading = val
		},
	},
	props: {
		value: {
			type: [String, Number, Array],
		},
		item: {
			type: Object,
		},
		size: {
			type: String,
		},
		form: {
			type: Object,
		},
		parentType: {
			type: String,
		},
		rowIndex: {
			type: Number,
		},
	},
}
</script>

<style lang="less" scope>
._zen-oss-upload.horizontal {
	padding: 7px 0px;
	display: flex;
	.ant-upload-list {
		flex: 1;
	}
	.ant-upload-list-item {
		margin-left: 8px;
	}
	.ant-upload-list.ant-upload-list-text {
		width: 100%;
	}
}
._zen-oss-upload {
	.ant-upload-select-picture-card {
		height: 80px;
		width: 80px;
	}
	.ant-upload-text {
		font-size: 10px;
	}
	.ant-upload-list-item {
		height: 80px;
		width: 80px;
	}
}
</style>
