/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-08-31 11:54:15
 */
function getType(fn) {
    const match = fn && fn.toString().match(/^\s*function (\w+)/);
    return match ? match[1] : '';
}

const filterProps = (props, propsData = {}) => {
    const res = {};
    Object.keys(props).forEach(k => {
        if (k in propsData || props[k] !== undefined) {
            res[k] = props[k];
        }
    });
    return res;
};

export function getComponentName(opts) {
    return opts && (opts.Ctor.options.name || opts.tag);
}

export function isEmptyElement(c) {
    return !(c.tag || (c.text && c.text.trim() !== ''));
}

export function isStringElement(c) {
    return !c.tag;
}

export function filterEmpty(children = []) {
    return children.filter(c => !isEmptyElement(c));
}

export function getOptionProps(instance) {
    if (instance.componentOptions) {
        const componentOptions = instance.componentOptions;
        const { propsData = {}, Ctor = {} } = componentOptions;
        const props = (Ctor.options || {}).props || {};
        const res = {};
        for (const [k, v] of Object.entries(props)) {
            const def = v.default;
            if (def !== undefined) {
                res[k] =
                    typeof def === 'function' && getType(v.type) !== 'Function' ? def.call(instance) : def;
            }
        }
        return { ...res, ...propsData };
    }
    const { $options = {}, $props = {} } = instance;
    return filterProps($props, $options.propsData);
};

export function isArray(arg) {
    if (!Array.isArray) {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}