<template>
    <div>
        <a-input-search
            :class="{'hide-input-suffix' : item.prefixIcon}"
            :size="size"
            :placeholder="item.placeholder || ''"
            :disabled="item.isDisabled"
            :addonBefore="item.addonBefore"
            :addonAfter="item.addonAfter"
            :enterButton="item.searchBtnText"
            @search="
                value => {
                    searchHandle(value, item);
                }
            "
            @change="
                e => {
                    changeHandle(e, item);
                }
            "
            @blur="
                e => {
                    blurHandle(e, item);
                }
            "
            v-model="inputVal"
        >
            <a-icon :type="item.prefixIcon" slot="prefix" v-if="item.prefixIcon"></a-icon>
        </a-input-search>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            inputVal: this.value || ""
        };
    },
    methods: {
        columnCallback(value, column, fn) {
            let { form } = this;
            column[fn]({
                value,
                form,
                column
            });
        },
        changeHandle(value, column) {
            this.$emit("change", value);
            if (column.clickHandle) {
                this.columnCallback(value, column, "clickHandle");
            }
            // 触发父级changeHandle
            if (
                this.parentType === "todo-list" ||
                this.parentType === "group-list"
            ) {
                this.$emit("changeHandle", value, column, this.rowIndex);
            }
        },
        blurHandle(e, item) {
            this.$emit("blurHandle", e, item);
        },
        searchHandle(value, column) {
            if (column.searchHandle) {
                this.columnCallback(value, column, "searchHandle");
            }
        }
    },
    watch: {
        value: function(val) {
            this.inputVal = val;
        }
    },
    props: {
        value: {
            type: String
        },
        item: {
            type: Object
        },
        size: {
            type: String
        },
        form: {
            type: Object
        }
    }
};
</script>
<style lang="less">
.hide-input-suffix.ant-input-affix-wrapper .ant-input {
    padding-right: 12px;
}
.hide-input-suffix .ant-input-suffix {
    display: none;
}
</style>
