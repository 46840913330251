// 通用下载方法
export function download(url) {
  window.open(url);
}


export function SectionToChinese(section) {
  var chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var chnUnitChar = ["", "十", "百", "千", "万", "亿", "万亿", "亿亿"];
  var strIns = '', chnStr = '';
  var unitPos = 0;
  var zero = true;
  while (section > 0) {
    var v = section % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    section = Math.floor(section / 10);
  }
  return chnStr;
}

export function cotingMattersMap(section) {
  const optionsType = {
    1: '同意',
    2: '反对',
    3: '弃权',
    4: '废票',
    5: '计入多数',
  }
  return optionsType[section]
}



export function cotingMattersOptions(type) {
  const optionsType = {
    1: [{ label: '同意', value: 1 },
    { label: '反对', value: 2 },
    { label: '弃权', value: 3 },
    { label: '废票', value: 4 },],
    2: [
      { label: '同意', value: 1 },
      { label: '反对', value: 2 },
      { label: '废票', value: 4 },
    ]
  }
}

export function hideStr(str, frontLen, endLen) {
  var newStr;
  var len = str.length - frontLen - endLen;
  if (str.length === 2) {
      newStr = str.substring(0, 1) + '*';
  } else if (str.length > 2) {
      var char = '';
      for (let i = 0; i < len; i++) {
          char += '*';
      }
      newStr = str.substring(0, frontLen) + char + str.substring(str.length - endLen);
  } else {
      newStr = str;
  }
  return newStr;
}