/*
 * @Author: your name
 * @Date: 2020-05-18 10:13:14
 * @LastEditTime: 2020-09-24 11:28:02
 * @LastEditors: Yin Xiang Zheng
 * @Description: In User Settings Edit
 * @FilePath: /sand-ui/components/_util/generalEvents.js
 */
import { isArray } from './index'

const getVal = (value) => {
    if (value !== null && value !== undefined && value.preventDefault) {
        return value.target.value
    }
    return value
}

const generalEvents = ($root) => {
    return (attr) => {
        $root.emitHandel({ ...attr, dataSource: $root.dataSource })
        const { express } = $root
        if (!isArray(express) || express.length <= 0) {
            return
        }
        const { fnName, props } = attr
        const findExpress = express.find(item => item.props === props)
        if (!findExpress) {
            return
        }
        if (fnName !== findExpress.tigger) {
            return
        }
        const originInstance = $root.getScope()
        let showModal = findExpress['showModal']
        originInstance[showModal] = true
    }
}

export default generalEvents