import request from "@/api/request";
// 发送验证码
export function sendSmsCaptcha(data) {
  return request({
    url: "/api/captcha",
    method: "post",
    data: data,
  });
}

export function getMenu() {
  return request({
    url: "/api/menu",
    method: "get",
  });
}

export function updateUserInfo(_id, data) {
  return request({
    url: `/api/user/userinfo/${_id}`,
    method: "put",
    data,
  });
}
