import zform from "../../zform/index";
import { isArray } from '../utils/index'

const EditCell = {
    name: 'EditCell',
    props: {
        editOptions: {
            type: Array,
            default() {
                return []
            }
        },
        defaultValue: {},
        editIcon: {
            type: String,
            default: "edit"
        },
        editMode: {
            type: String,
            default: "inline"
        },
        columnProps: String,
        expression: {
            type: Array,
            default() {
                return []
            }
        },
        modalOptions: Object,
    },
    components: {
        zform
    },
    data() {
        return {
            editable: false,
        };
    },
    inject: ['generalEvents', 'validateFields'],
    methods: {
        check() {
            const { generalEvents, editOptions, defaultValue } = this
            this.$refs.zform.zformValidateFieldsAndScroll().then(values => {
                for (const key in values) {
                    const element = values[key];
                    generalEvents({
                        fnName: 'editValueChange',
                        props: key,
                        newVal: element
                    })
                }
                this.editable = false;
            }).catch(err => {
                console.error('err:', err);
            })
        },
        handleOk(e, values) {
            const { generalEvents } = this

            for (const key in values) {
                const element = values[key];
                generalEvents({
                    fnName: 'editValueChange',
                    props: key,
                    newVal: element
                })
            }
            this.editable = false;
        },
        cancel() {
            this.editable = false;
        },
        updateForm() {
            const { editOptions, defaultValue } = this.$props
            const { editMode, columnProps } = this
            if (editMode !== "simple") {
                this.editable = true;
            }
            this.$nextTick(() => {
                this.$refs.zform.updateForm(defaultValue)
            })
        },
        rendorForm(mode) {
            const { check, cancel, handleOk, editable, $slots, editMode } = this
            const { editOptions, expression, modalOptions } = this.$props
            const formConfig = {
                formLayout: 'horizontal', //vertical
                col: 24,
                expression
            }
            formConfig.columns = isArray(editOptions) ? editOptions : [{ ...editOptions }]
            let zmodalProps = {
                props: {
                    visible: editable,
                    formConfig: formConfig,
                    size: "small",
                    closable: false,
                    ...modalOptions
                },
                ref: "zform",
                on: {
                    handleCancel: cancel,
                    handleOk: handleOk
                }
            }
            let result = null
            if (editMode === "modal") {
                result = <template>
                    <div class="editable-cell-text-wrapper" >
                        <a href="javascript:void(0)">{$slots.default}</a>
                    </div>
                    <zmodal {...zmodalProps} ></zmodal>
                </template>
            } else {
                result = <div class="editable-cell-input-wrapper">
                    <zform formConfig={formConfig} formData={{}} ref="zform" style="width: 100%"></zform>
                    <span style="display: flex;align-items: center;">
                        <a style={{ marginRight: '6px' }} onClick={check}>Save</a>
                        <a onClick={cancel}>Cancel</a>
                    </span>
                </div>
            }
            return result
        },
        rendorColumn() {
            const { updateForm, $slots, editIcon, editMode, editOptions, generalEvents } = this
            const { expression } = this.$props
            let result = null

            if (editMode === "modal") {
                result = <div class="editable-cell-text-wrapper" >
                    <a href="javascript:void(0)" onClick={updateForm}>{$slots.default}</a>
                </div>
            } else if (editMode === "simple") {
                const zformProps = {
                    props: {
                        formConfig: {
                            formLayout: 'horizontal', //vertical
                            col: 24,
                            columns: isArray(editOptions) ? editOptions : [{ ...editOptions }],
                            expression
                        },
                        formData: {},
                    },
                    on: {
                        change: ({ value, column: { props: columnProps } }) => {
                            generalEvents({
                                fnName: 'editValueChange',
                                props: columnProps,
                                newVal: value,
                            })
                        }
                    },
                    ref: 'zform'
                }
                result = <div class="editable-cell-input-wrapper" style="padding-right: 0">
                    <zform {...zformProps} style="width: 100%"></zform>
                </div>
            } else {
                result = <div class="editable-cell-text-wrapper" >
                    {$slots.default}
                    < a-icon type={editIcon} class="editable-cell-icon" onClick={updateForm} />
                </div>
            }
            this.$nextTick(() => {
                this.validateFields(this.$refs.zform)
            })
            return result
        }
    },
    render() {
        const { edit, editable, $slots, editIcon, editMode, rendorForm, rendorColumn } = this
        if (editMode === "simple") {
            // 更新数据
            this.updateForm()
        }
        return (
            <div class="editable-cell" ref="xxxxx">
                {
                    editable ? rendorForm() : rendorColumn()
                }
            </div>
        )
    }
};

export default EditCell