/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 16:16:50
 */
import request from "@/api/request";

// 获取流程列表
export function createContactUs(data) {
    return request({
        url: "/api/contactUs",
        method: "post",
        data: data,
    });
}