<template>
	<div style="line-height: 56px">
		<a-radio-group
			v-if="item.subCol"
			class="zen-form--checkboxgroup"
			:disabled="item.isDisabled"
			@change="
				e => {
					changeRadioGroupHandle(e, item)
				}
			"
			v-model="radioGroupVal"
		>
			<a-row>
				<template v-for="(checkboxItem, index) in item.options">
					<a-col :span="item.subCol" :key="index">
						<a-radio
							:value="checkboxItem.value"
							:disabled="checkboxItem.isDisabled"
							@click="
								clickTask(
									index,
									checkboxItem,
									item,
									`radio选择值-${item.label}`,
									'div/div/'
								)
							"
							>{{ checkboxItem.label }}</a-radio
						>
					</a-col>
				</template>
			</a-row>
		</a-radio-group>

		<a-radio-group
			v-else-if="item.style && item.style == 'button'"
			:size="item.buttonSize"
			:buttonStyle="item.buttonStyle"
			:disabled="item.isDisabled"
			@change="
				e => {
					changeRadioGroupHandle(e, item)
				}
			"
			v-model="radioGroupVal"
		>
			<a-radio-button
				:value="checkboxItem.value"
				:disabled="checkboxItem.isDisabled"
				v-for="(checkboxItem, index) in item.options"
				:key="index"
				@click="
					clickTask(
						index,
						checkboxItem,
						item,
						`radio选择值-${item.label}`
					)
				"
				>{{ checkboxItem.label }}</a-radio-button
			>
		</a-radio-group>
		<a-radio-group
			v-else
			:disabled="item.isDisabled"
			@change="
				e => {
					changeRadioGroupHandle(e, item)
				}
			"
			:size="size"
			v-model="radioGroupVal"
		>
			<a-row>
				<a-col :span="24">
					<template v-for="(checkboxItem, index) in item.options">
						<a-radio
							:key="index"
							:value="checkboxItem.value"
							:disabled="checkboxItem.isDisabled"
							@click="
								clickTask(
									index,
									checkboxItem,
									item,
									`radio选择值-${item.label}`,
									'div/div/'
								)
							"
							>{{ checkboxItem.label }}</a-radio
						>
					</template>
				</a-col>
			</a-row>
		</a-radio-group>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			radioGroupVal: this.value || ''
		}
	},
	methods: {
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		changeRadioGroupHandle(e, column) {
			//let value = (column.defaultValue = e.target.value)
			let value = e.target.value
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}

			//
			setTimeout(() => {
				this.$emit('change', value)

				// 触发zform组件上的change方法
				let findComponents = function(component) {
					if (component.$options.name === 'zform') {
						if (component._change) {
							component._change({ value, column })
						}
						return
					}
					if (component.$parent) {
						findComponents(component.$parent)
					}
				}
				findComponents(this)

				// 触发父级changeHandle
				if (
					this.parentType === 'todo-list' ||
					this.parentType === 'group-list'
				) {
					this.$emit('changeHandle', value, column, this.rowIndex)
				}
			}, 50)
		},
		clickTask(index, item, column, step, path) {
			if (this.$utt && this.$utt.isRunning && !this.isDisabledUTT()) {
				//添加uitest日志
				const task = {
					by_select: 'xpath',
					by_val:
						path === false
							? `//*[@id="${column.props}"]`
							: `//*[@id="${column.props}"]/${path ||
									''}label[${index + 1}]`,
					param: item.label || item.title,
					type: 'click',
					time: '0',
					step: step
				}
				this.$utt.addTask(task)
			}
		}
	},
	watch: {
		value: {
			handler: function(val) {
				this.radioGroupVal = val
			},
			immediate: true
		}
	},
	props: {
		value: {
			type: [String, Number, Boolean]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
