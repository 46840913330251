<template>
	<div :style="getStyle">
		<a-button
			v-bind="item"
			:type="item.btnStatus"
			:disabled="item.isDisabled"
			:size="item.size || size"
			@click="clickHandle"
		>{{item.btnText}}</a-button>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {}
	},
	methods: {
		clickHandle() {
			let column = this.item
			if (column.loading === false) {
				column.loading = true
			}
			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._action) {
						component._action({
							action: null,
							column
						})
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		}
	},
	props: {
		item: {
			type: Object
		},
		form: {
			type: Object
		},
		size: {
			type: String
		}
	},
	computed: {
		getStyle() {
			let textAlign = this.item.align || 'left'
			let offset =
				textAlign === 'right'
					? { paddingRight: this.item.offset || 0 }
					: { paddingLeft: this.item.offset || 0 }
			return { textAlign, ...offset }
		}
	}
}
</script>
<style lang="less"></style>
