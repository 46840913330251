/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-01 10:58:53
 */
const TokenKey = "jwt";
const MenuKey = "ywh-web-menu";

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  localStorage.removeItem('userInfo');
  return localStorage.removeItem(TokenKey);
}

export function getMenu() {
  return JSON.parse(localStorage.getItem(MenuKey));
}

export function setMenu(token) {
  return localStorage.setItem(MenuKey, JSON.stringify(token));
}

export function removeMenu() {
  return localStorage.removeItem(MenuKey);
}
