export function getToken() {
  return localStorage.getItem("jwt");
}

export function setToken(token) {
  return localStorage.setItem("jwt", token);
}

export function removeToken() {
  localStorage.removeItem("userInfo");
  return localStorage.removeItem("jwt");
}
