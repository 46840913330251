<template>
	<div>
		<a-input-number
			:size="size"
			:placeholder="item.placeholder || ''"
			:disabled="item.isDisabled"
			:step="item.step || 1"
			:min="item.min"
			:max="item.max"
			:class="{'input-number--hidden--step' : item.hiddenStep}"
			@change="
                value => {
                    debounceHandle(value, item);
                }
            "
			@blur="
                e => {
                    blurHandle(e, item);
                }
            "
			:formatter="value => formatterVal(value)"
			:parser="value => parserVal(value)"
			v-model="inputVal"
			:style="{width: item.width ? item.width : (item.col ? '100%' : 'unset')}"
		></a-input-number>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
const debounceFn = debounce(val => {
	val()
}, 500)

function toDecimal(number, pos) {
	var f = parseFloat(number)
	pos = pos || 2
	if (isNaN(f)) {
		return number
	}
	var f = Math.round(f * Math.pow(10, pos)) / Math.pow(10, pos)
	var s = f.toString()

	var rs = s.indexOf('.')
	if (rs < 0) {
		rs = s.length
		s += '.'
	}
	while (s.length <= rs + pos) {
		s += '0'
	}
	return s
}
export default {
	name: '',
	data() {
		return {
			inputVal: this.prepareVal(this.value, true)
		}
	},
	methods: {
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		debounceHandle(e, column, subColumn) {
			this.changeHandle(e, column, subColumn)
			// if (column.closeDebounce) {
			// 	this.changeHandle(e, column, subColumn)
			// } else {
			// 	debounceFn(() => {
			// 		this.changeHandle(e, column, subColumn)
			// 	})
			// }
		},
		changeHandle(value, column) {
			value = this.prepareVal(value)
			this.$emit('change', value)
			if (column.clickHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}

			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)
		},
		blurHandle(e, column) {
			let value = e.target.value
			value = this.prepareVal(value)
			this.$emit('blurHandle', e, column)
			// 触发父级changeHandle
			if (
				this.parentType === 'todo-list' ||
				this.parentType === 'group-list'
			) {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}
		},
		prepareVal(val, isParseNumber) {
			if (val === null || val === undefined || val === '') {
				return val
			}
			if (Number(val) === 0) {
				return val
			}
			if (isParseNumber) {
				return Number(val) || void 0
			}
			return val
		},
		parserVal(val) {
			if (this.item.thousandSeparator === true) {
				val = val.replace(/\$\s?|(,*)/g, '')
			}

			return val
		},
		formatterVal(val) {
			if (this.item.decimally) {
				val = toDecimal(val, this.item.decimally)
			}
			if (this.item.thousandSeparator === true) {
				val = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			}

			return val
		}
	},
	watch: {
		value: function(val) {
			this.inputVal = this.prepareVal(val)
		}
	},
	props: {
		value: {
			type: [Number, String]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less">
.input-number--hidden--step .ant-input-number-handler-wrap {
	display: none;
}
</style>
