import moment from 'moment'
import { isArray } from '../utils/index'
const prefixCls = 'ztext-item'
const ZTimeComponent = {
    name: 'ZTextComponent',
    props: {
        _value: {
            type: [String, Number, Array]
        },
        column: {
            type: Object
        },
        delimiter: { // 当_value为数组时，可以自定义分隔符样式
            type: String,
            default: '<br>'
        },
    },
    inject: ['noneValue'],
    methods: {

    },
    computed: {
        getText() {
            const { _value = undefined, format, delimiter } = this.$props

            const { noneValue } = this
            if (!_value && _value !== 0) {
                return noneValue
            }

            // if (isArray(_value)) {
            //     return _value.join(delimiter)
            // }

            return _value.toString()
        }
    },
    render() {
        const { getText } = this
        const { column: { addonBefore, addonAfter, color } } = this.$props
        return (
            <div class={prefixCls}>
                {
                    addonBefore ? <span class={`${prefixCls}-addon-before`} domPropsInnerHTML={addonBefore}></span> : ''
                }
                <span style={{ color }} domPropsInnerHTML={getText}>
                </span>
                {
                    addonAfter ? <span class={`${prefixCls}-addon-after`} domPropsInnerHTML={addonAfter}></span> : ''
                }
            </div>
        )
    }
}

export default ZTimeComponent