<template>
	<div>
		<a-textarea
			v-if="item.type === 'textarea'"
			:disabled="item.isDisabled"
			:placeholder="item.placeholder || ''"
			:rows="item.rows ? item.rows + '' : null"
			:id="item.props"
			@click="
                e => {
                    clickHandle(e, item);
                }
            "
			@change="
                e => {
                    debounceHandle(e, item);
                }
            "
			@blur="
                e => {
                    blurHandle(e, item);
                }
            "
			v-model="textAreaVal"
		/>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
const debounceFn = debounce(val => {
	val()
}, 500)
export default {
	name: '',
	data() {
		return {
			textAreaVal: this.value
		}
	},
	methods: {
		subColumnCallback(value, column, subColumn, fn) {
			let { form } = this
			subColumn[fn]({
				value,
				form,
				column,
				subColumn
			})
		},
		columnCallback(value, column, fn) {
			let { form } = this
			column[fn]({
				value,
				form,
				column
			})
		},
		clickHandle(e, column, subColumn) {
			let value = e.target.value
			if (subColumn && subColumn.clickHandle) {
				this.subColumnCallback(value, column, subColumn, 'clickHandle')
				return
			}
			if (column.clickHandle) {
				this.columnCallback(value, column, 'clickHandle')
			}
		},
		debounceHandle(e, column, subColumn) {
			this.changeHandle(e, column, subColumn)
			// if (column.closeDebounce) {
			// 	this.changeHandle(e, column, subColumn)
			// } else {
			// 	debounceFn(() => {
			// 		this.changeHandle(e, column, subColumn)
			// 	})
			// }
		},
		changeHandle(e, column, subColumn) {
			let value = e.target.value
			this.$emit('change', value)
			// 触发zform组件上的change方法
			let findComponents = function(component) {
				if (component.$options.name === 'zform') {
					if (component._change) {
						component._change({ value, column })
					}
					return
				}
				if (component.$parent) {
					findComponents(component.$parent)
				}
			}
			findComponents(this)

			if (subColumn && subColumn.changeHandle) {
				this.subColumnCallback(value, column, subColumn, 'changeHandle')
				return
			}
			if (column.changeHandle) {
				this.columnCallback(value, column, 'changeHandle')
			}
		},
		blurHandle(e, column) {
			let value = e.target.value
			this.$emit('blurHandle', e, column)
			// 集成在todo-list组件中
			if (this.parentType === 'todo-list') {
				this.$emit('changeHandle', value, column, this.rowIndex)
			}
		}
	},
	watch: {
		value: function(val) {
			this.textAreaVal = val
		}
	},
	props: {
		value: {
			type: [String, Number]
		},
		item: {
			type: Object
		},
		size: {
			type: String
		},
		form: {
			type: Object
		},
		parentType: {
			type: String
		},
		rowIndex: {
			type: Number
		}
	}
}
</script>
<style lang="less"></style>
